import { PayloadAction } from '@reduxjs/toolkit';
import { GenericState } from '../../types/apiModule';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { Activitat } from '../../types/activitat/Activitat';
import { deleteActivitat } from '../../api/activitats/deleteActivitat';
import { listActivitatsRefresh } from './listActivitatsModule';

export interface deleteActivitatState extends GenericState<unknown> {
  active: boolean;
  data: Activitat[];
  activitat: Activitat | null;
}
const initialState: deleteActivitatState = {
  errors: null,
  loading: false,
  data: [],
  active: false,
  activitat: null,
};
const deleteActivitatModule = createApiModule({
  name: 'deleteActivitat',
  initialState,
  apiMethod: deleteActivitat,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: "L'activitat s'ha esborrat correctament.",
    }),
    listActivitatsRefresh(),
  ],
  onError: () => ({
    message: "No es pot esborrar l'activitat.",
  }),
  onValidationError: (error) => ({
    message: error ? error.message : "Error esborrant l'activitat",
  }),
  reducers: {
    show(state, action: PayloadAction<Activitat>) {
      state.active = true;
      state.activitat = action.payload;
    },
    hide(state) {
      state.active = false;
    },
  },
});

export const {
  start: del,
  success: delOk,
  error: delKo,
  hide,
  show,
} = deleteActivitatModule.slice.actions;

export default deleteActivitatModule;
