export type AssessoriaEmprenedoriaDerivacio =
  | 'associacionisme'
  | 'academica'
  | 'laboral'
  | 'mobilitat_internacional'
  | 'habitatge'
  | 'pdl_basa'
  | 'programes_basa'
  | 'programes_gj'
  | 'saier'
  | 'soc'
  | 'ino_ba';

export enum AssessoriaEmprenedoriaDerivacioEnum {
  'associacionisme' = 'Associacionisme',
  'academica' = 'Acadèmica',
  'laboral' = 'Laboral',
  'mobilitat_internacional' = 'Mobilitat internacional',
  'habitatge' = 'Habitatge',
  'pdl_basa' = 'PDL Basa',
  'programes_basa' = 'Programes BASA',
  'programes_gj' = 'Programes CJ',
  'saier' = 'SAIER',
  'soc' = 'SOC',
  'ino_ba' = 'InoBA',
}
