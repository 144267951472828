import { updateDataRules } from '../../../api/configuracio/control/updateDataRules';
import createApiModule from '../../createApiModule';

const updateDataRulesModule = createApiModule({
  name: 'updateDataRules',
  apiMethod: updateDataRules,
  onSuccess: () => ({
    message: "Les regles de dates s'han modificat correctament.",
    path: '/configuracio/control',
  }),
  onError: () => ({
    message: 'Error modificant les regles de dates',
  }),
  onValidationError: () => ({
    message: 'Error modificant les regles de dates',
  }),
});

export const {
  start: update,
  success: updateOk,
  error: updateKo,
} = updateDataRulesModule.slice.actions;

export default updateDataRulesModule;
