import { lazy } from 'react';
import { CalendariProvider } from './CalendariContext';
const List = lazy(() => import('./Components/Cites/List/CitesList'));
const NewEdit = lazy(() => import('./Components/Cites/NewEdit/CitaNewEdit'));

const CalendariRoutes = [
  {
    path: 'calendari',
    element: (
      <CalendariProvider>
        <List />
      </CalendariProvider>
    ),
  },
  {
    path: 'calendari/cita/nova',
    element: (
      <CalendariProvider>
        <NewEdit />
      </CalendariProvider>
    ),
  },
];

export default CalendariRoutes;
