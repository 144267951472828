import React from 'react';
import { connect } from 'react-redux';
import {
  hide,
  del as deleteProfessional,
} from '../../../store/professionals/deleteProfessionalModule';

import { AppDispatch, RootState } from '../../../store/store';
import { UUID } from '../../../types/standard';
import Button from '../../common/Buttons/Button/Button';
import Loading from '../../common/Loading/Loading';
import Modal from '../../common/Modal/Modal';
import styles from '../changeActions.module.scss';
import { ReactComponent as IconTrash } from '../../../assets/moreMenu/trash.svg';
import ButtonsGroup from '../../common/Buttons/ButtonsGroup/ButtonsGroup';
import renderWhen from '../../../helpers/renderWhen';

import { Professional } from '../../../types/professionals/professionals';
import {
  selectDeleteProfessional,
  selectDeleteProfessionalActive,
  selectDeleteProfessionalLoading,
} from '../../../store/professionals/selectors';

interface DeleteProfessionalProps {
  professional: Professional | null;
  active: boolean;
  loading: boolean;
  closeModal: () => void;
  deleteProfessional: (id: UUID | undefined) => void;
}

const DeleteProfessional: React.FC<DeleteProfessionalProps> = ({
  professional,
  active,
  loading,
  closeModal,
  deleteProfessional,
}) => {
  // 'active' controls if the modal is open or close
  if (!active) return null;
  return (
    <>
      {professional && (
        <Modal closeModal={closeModal}>
          <div className={styles.container}>
            <h1 className={styles.title}>
              <IconTrash className={`${styles.icon} ${styles.isIconTrash}`} />
              Eliminar professional
            </h1>
            <p className={styles.description}>
              Estàs segur/a que vols eliminar la/el professional?
            </p>
            <div className={styles.appointmentWrapper}>
              <div className={styles.description}>
                <p>
                  <span className={styles.appointmentSection}>Nom:</span>{' '}
                  {professional.nom} {professional.cognom}{' '}
                  {professional.segon_cognom}
                </p>
              </div>
            </div>
            <ButtonsGroup variant="center">
              <Button type="button" variant="negative" onClick={closeModal}>
                Cancel·lar
              </Button>
              {professional.id && (
                <Button
                  variant="positive"
                  onClick={() => {
                    deleteProfessional(professional.id);
                    closeModal();
                  }}
                  data-testid="submit_button"
                >
                  Eliminar
                </Button>
              )}
            </ButtonsGroup>
            {loading && <Loading />}
          </div>
        </Modal>
      )}
    </>
  );
};

const ConnectedDeleteProfessional = connect(
  (state: RootState) => ({
    professional: selectDeleteProfessional(state),
    active: selectDeleteProfessionalActive(state),
    loading: selectDeleteProfessionalLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    deleteProfessional: (payload: UUID | undefined) =>
      dispatch(deleteProfessional(payload)),
    closeModal: () => dispatch(hide()),
  }),
)(DeleteProfessional);

export default renderWhen(
  ConnectedDeleteProfessional,
  selectDeleteProfessionalActive,
);
