import React, { useState } from 'react';
import { Tematica, tematicaToSelectOption } from '../types/shared/tematica.d';
import {
  selectTematicaInputSelectPlaceholder,
  selectTematiques,
  selectTematiquesLoading,
} from '../store/ambits/selectors';
import { useSelector } from 'react-redux';
import {
  GroupedOption,
  ReactSelectOption,
} from '../components/common/Form/MultiSelectField/MultiSelectField';
import { sortArrayByKey } from '../utils/sortArrayByKey';
import Select from 'react-select/base';
import { GroupBase } from 'react-select';
import { AmbitRequest } from '../types/consultes/consulta.d';
import { UUID } from '../types/standard';

const useTematica = () => {
  const emptyAmbit: AmbitRequest = {
    ambit: '',
    tematica_id: [],
    subtematica_id: [],
    serveis_tramits: [],
  };

  const [groupedSubTematiques, setGroupedSubTematiques] = useState<
    GroupedOption[]
  >([]);

  const tematiquesRef =
    React.createRef<
      Select<ReactSelectOption, true, GroupBase<ReactSelectOption>>
    >();
  const subtematiquesRef =
    React.createRef<
      Select<ReactSelectOption, true, GroupBase<ReactSelectOption>>
    >();

  const [selectedAmbit, setSelectedAmbit] = useState<string>('');
  const [selectedTematiques, setSelectedTematiques] = useState<
    ReactSelectOption[]
  >([]);
  const [selectedSubTematiques, setSelectedSubTematiques] = useState<
    ReactSelectOption[]
  >([]);
  const [filteredTematiques, setFilteredTematiques] = useState<Tematica[]>([]);
  const [checkedServeisTramits, setCheckedServeisTramits] = useState<string[]>(
    [],
  );

  function clearRefs() {
    tematiquesRef?.current?.clearValue();
    subtematiquesRef?.current?.clearValue();
    setCheckedServeisTramits([]);
  }
  function handleAmbitChange(selected: string, clear: boolean) {
    if (clear) {
      clearRefs();
    }

    setSelectedAmbit(selected);
    setFilteredTematiques(
      tematiques.filter((tematica: Tematica) => tematica.ambit === selected),
    );
  }

  function handleTematicaChange(selected: string[]) {
    selected = Array.from(new Set(selected));
    setSelectedTematiques(
      findTematicaObject(tematiques, selected).map(tematicaToSelectOption),
    );
    const showSubtematiques: GroupedOption[] = [];
    selected.forEach((id) => {
      const tematica = sortArrayByKey(tematiques, 'name').find(
        (tematica) => tematica.id === id,
      );
      if (tematica && tematica.subtematica) {
        showSubtematiques.push({
          label: tematica.name,
          options: sortArrayByKey(tematica.subtematica, 'name').map(
            tematicaToSelectOption,
          ),
        });
      }
    });

    setGroupedSubTematiques(showSubtematiques);
  }

  function handleSubTematicaChange(selected: string[]) {
    selected = Array.from(new Set(selected));
    setSelectedSubTematiques(
      findSubTematicasObject(tematiques, selected).map(tematicaToSelectOption),
    );
  }

  function handleServeiTramitChange(checked: string[]) {
    setCheckedServeisTramits(checked);
  }

  function subTematiquesPlaceholder() {
    return isLoading
      ? 'Carregant temàtiques...'
      : groupedSubTematiques
        ? groupedSubTematiques.length > 0
          ? 'Selecciona una subtemàtica'
          : 'No hi ha subtemàtiques disponibles'
        : '';
  }

  const tematiques = useSelector(selectTematiques);
  const isLoading = useSelector(selectTematiquesLoading);
  const tematiquesPlaceholder = useSelector(
    selectTematicaInputSelectPlaceholder,
  );

  const findTematicaObject = (tematiques: Tematica[], selected: UUID[]) => {
    return tematiques.filter((tematica: Tematica) => {
      return selected.indexOf(tematica.id) > -1;
    });
  };

  const findSubTematicasObject = (
    tematiques: Tematica[],
    selected: UUID[],
  ): Tematica[] => {
    const foundSubTematicas: Tematica[] = [];

    const searchSubTematicas = (tematica: Tematica) => {
      if (tematica.subtematica) {
        for (const subtematica of tematica.subtematica) {
          if (selected.includes(subtematica.id)) {
            foundSubTematicas.push(subtematica);
          }
          searchSubTematicas(subtematica);
        }
      }
    };

    for (const tematica of tematiques) {
      searchSubTematicas(tematica);
    }

    return foundSubTematicas;
  };

  return {
    tematiquesPlaceholder,
    subTematiquesPlaceholder: subTematiquesPlaceholder(),
    handleAmbitChange,
    selectedAmbit,
    handleTematicaChange,
    handleSubTematicaChange,
    filteredTematiques,
    groupedSubTematiques,
    tematiquesRef,
    subtematiquesRef,
    emptyAmbit,
    selectedTematiques,
    selectedSubTematiques,
    checkedServeisTramits,
    setCheckedServeisTramits,
    handleServeiTramitChange,
  };
};

export default useTematica;
