export type AssessoriaZonaGeografica =
  | 'africa'
  | 'america_nord'
  | 'america_sud'
  | 'asia'
  | 'europa'
  | 'oceania';

export enum AssessoriaZonaGeograficaEnum {
  'africa' = 'Àfrica',
  'america_nord' = 'Amèrica del Nord',
  'america_sud' = 'Amèrica del Sud i Centre',
  'asia' = 'Àsia',
  'europa' = 'Europa',
  'oceania' = 'Oceania',
}
