import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { Consulta } from '../../types/consultes/consulta.d';

export const newConsulta = async (
  data: Consulta,
): Promise<Result<Consulta, APIValidationError>> => {
  return validatedApiCall<Consulta>(`/api/consultes`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  });
};
