import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { PostActivitatProps } from './postActivitat';

export const patchActivitat = async (
  data: PostActivitatProps,
): Promise<Result<PostActivitatProps, APIValidationError>> => {
  return validatedApiCall<PostActivitatProps>(`/api/activitats`, {
    method: 'PATCH',
    auth: true,
    body: JSON.stringify(data),
  });
};
