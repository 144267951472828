import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FormLayout from '../../../../components/common/Layouts/FormLayout/FormLayout';
import InputField from '../../../../components/common/Form/InputField/InputField';
import SectionHeading from '../../../../components/common/Form/SectionHeading/SectionHeading';
import { AssessoriaTipusEnum } from '../../../../types/assessories/assessoria_tipus.d';
import { UUID } from '../../../../types/standard';
import { formatDate } from '../../../../helpers/formatDate';
import TextArea from '../../../../components/common/Form/TextArea/TextArea';
import ButtonsGroup from '../../../../components/common/Buttons/ButtonsGroup/ButtonsGroup';
import Button from '../../../../components/common/Buttons/Button/Button';
import { SectionRealitzada } from './SectionRealitzada';
import Loading from '../../../../components/common/Loading/Loading';
import { ViewModel as useViewModel } from './ViewModel';
import { GenderEnum } from '../../../../types/users/gender.d';
import { RangEdatEnum } from '../../../../types/consultes/rang_edat.d';
import { CursEnum } from '../../../../types/assessories/curs.d';
import { AssessoriaDistricteEnum } from '../../../../types/assessories/assessoria_districte.d';
import { SituacioAdministrativaEnum } from '../../../../types/consultes/situacio_administrativa.d';
import { ComConegutEnum } from '../../../../types/consultes/com_conegut.d';
import { AssessoriaModalitatEnum } from '../../../../types/assessories/assessoria_modalitat.d';
import { AssessoriaPerfilEnum } from '../../../../types/assessories/assessoria_perfil.d';

const AssessoriaRealitzada: React.FC<any | null> = () => {
  const params = useParams();
  const id = params.id as UUID;
  const navigate = useNavigate();
  const { assessoria, get, reset, isLoading } = useViewModel();

  useEffect(() => {
    if (
      (!assessoria && id) ||
      assessoria?.id !== id ||
      !assessoria?.realitzada
    ) {
      reset();
      get(id);
    }
  }, [id]);

  if (!assessoria) {
    return null;
  }

  const {
    jove,
    perfil,
    edat,
    genere,
    idioma,
    autonomia,
    formacio,
    ocupacio,
    situacio_administrativa,
    residencia,
    conegut,
    tipus,
    cita,
    modalitat,
    tematica,
    descripcio,
    informacio_entregada,
    observacions,
    professional,
  } = assessoria;

  const goBack = () => navigate(-1);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <FormLayout
          variant="profile"
          formTitle={`Assessoria ${AssessoriaTipusEnum[tipus]} reservada per ${professional?.nom} ${professional?.cognom}`}
        >
          <SectionHeading title={'Dades persona usuària'} />
          <InputField
            label="Nom"
            input={{
              type: 'text',
              defaultValue: jove?.nom,
              readOnly: true,
            }}
          />
          <InputField
            label="Cognoms"
            input={{
              type: 'text',
              defaultValue: jove?.cognoms,
              readOnly: true,
            }}
          />
          <InputField
            label="Telèfon"
            input={{
              type: 'text',
              readOnly: true,
              defaultValue: jove?.telefon,
            }}
          />
          <InputField
            label="Correu electrònic"
            input={{
              type: 'email',
              readOnly: true,
              defaultValue: jove?.email,
            }}
          />
          <InputField
            label="Edat"
            input={{
              defaultValue: RangEdatEnum[edat],
              readOnly: true,
            }}
          />
          <InputField
            label="Perfil"
            input={{
              defaultValue: perfil ? AssessoriaPerfilEnum[perfil] : '',
              readOnly: true,
            }}
          />
          <InputField
            label="Gènere"
            input={{
              defaultValue: GenderEnum[genere],
              readOnly: true,
            }}
          />
          <InputField
            label="Nivell d'idioma"
            input={{
              type: 'text',
              readOnly: true,
              defaultValue: idioma,
            }}
          />
          <InputField
            label="Autonomia"
            input={{
              type: 'text',
              readOnly: true,
              defaultValue: autonomia,
            }}
          />
          <InputField
            label="Formació"
            input={{
              defaultValue: CursEnum[formacio],
              readOnly: true,
            }}
          />
          <InputField
            label="Ocupació"
            input={{
              type: 'text',
              readOnly: true,
              defaultValue: ocupacio,
            }}
          />
          <InputField
            label="Situació administrativa"
            input={{
              defaultValue: SituacioAdministrativaEnum[situacio_administrativa],
              readOnly: true,
            }}
          />
          <InputField
            label="Lloc de residència"
            input={{
              defaultValue: AssessoriaDistricteEnum[residencia],
              readOnly: true,
            }}
          />
          <TextArea
            label="Com ens has conegut?"
            textarea={{
              defaultValue:
                conegut?.map((el) => ComConegutEnum[el]).join(', ') || [],
              readOnly: true,
              name: 'conegut',
            }}
          />
          <SectionHeading title={'Dades reserva'} />
          {
            <>
              <InputField
                label="On es realitza?"
                input={{
                  disabled: true,
                  readOnly: true,
                  defaultValue: cita?.oficina?.name,
                }}
              />
              <InputField
                label="Assessor"
                input={{
                  disabled: true,
                  defaultValue: `${cita?.professional?.nom} ${cita?.professional?.cognom}`,
                }}
              />
              <InputField
                label="Dia"
                input={{
                  disabled: true,
                  defaultValue: formatDate(cita?.date, true),
                }}
              />
            </>
          }
          <InputField
            label="Modalitat"
            input={{
              defaultValue: AssessoriaModalitatEnum[modalitat],
            }}
          />
          <InputField
            label="Temàtica de la consulta"
            input={{
              defaultValue: tematica.name,
            }}
          />
          <TextArea
            label="Descripció de la petició"
            textarea={{
              defaultValue: descripcio,
              name: 'descripcio',
              readOnly: true,
            }}
          />
          <TextArea
            label="Informació entregada"
            textarea={{
              defaultValue: informacio_entregada,
              name: 'informacio',
              readOnly: true,
            }}
          />
          <TextArea
            label="Observacions"
            textarea={{
              defaultValue: observacions,
              readOnly: true,
              name: 'observacions',
            }}
          />
          {assessoria.realitzada && (
            <>
              <SectionHeading title={'Dades realitzada'} />
              <SectionRealitzada
                realitzada={assessoria?.realitzada}
                tipus={assessoria.tipus}
              />
            </>
          )}
          <ButtonsGroup variant={'column'}>
            <>
              <Button type="button" variant="negative" onClick={goBack}>
                Tornar
              </Button>
            </>
          </ButtonsGroup>
        </FormLayout>
      )}
    </>
  );
};

export default AssessoriaRealitzada;
