import { UUID } from '../standard';
import { ReactSelectOption } from '../../components/common/Form/MultiSelectField/MultiSelectField';

export type SectorProfessional = {
  name: string;
  id: UUID;
};

export const sectorProfessionalToSelectOption = (
  sector: SectorProfessional,
): ReactSelectOption => {
  return {
    value: sector.id,
    label: sector.name,
  };
};
