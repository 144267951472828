import React, { useEffect } from 'react';
import Loading from '../../../../components/common/Loading/Loading';
import { RealitzadaViewModel as useViewModel } from './RealitzadaViewModel';
import { useParams } from 'react-router-dom';
import { UUID } from '../../../../types/standard';
import { AssessoriaRealitzada } from '../../../../types/assessories/assessoria_realitzada';
import { iEspecifica } from './iEspecifica';
import RealitzadaDataForm from './Forms/RealitzadaDataForm';

const RealitzadaAssessoria = () => {
  const params = useParams();
  const id = params.id as UUID;
  const isNew = !id;

  const {
    userAuth,
    activeEspecifica,
    assessoria,
    save,
    get,
    isLoading,
    isSaving,
    serverErrors,
    createEspecifica,
  } = useViewModel();

  useEffect(() => {
    if ((!assessoria && id) || assessoria?.id !== id) {
      get(id);
    }
  }, [id]);

  useEffect(() => {
    createEspecifica();
  }, [isLoading]);

  function onSaveOrUpdate(data: AssessoriaRealitzada) {
    return isNew ? save(data, activeEspecifica) : save(data, activeEspecifica);
  }

  return (
    <>
      {isLoading || !activeEspecifica ? (
        <Loading />
      ) : (
        <RealitzadaDataForm
          userAuth={userAuth}
          isSaving={isSaving}
          serverErrors={serverErrors}
          isLoading={isLoading}
          isNew={isNew}
          isRealitzada={false}
          onSave={onSaveOrUpdate}
          especifica={activeEspecifica as iEspecifica}
        />
      )}
    </>
  );
};

export default React.memo(RealitzadaAssessoria);
