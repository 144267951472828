export type AssessoriaTemporalitat =
  | 'estades_estiu'
  | 'any_trimestre_academic'
  | '1_3_mesos'
  | '3_mesos_1_any'
  | 'mes_un_any'
  | 'indefint'
  | 'ns_nc';

export enum AssessoriaTemporalitatEnum {
  'estades_estiu' = "Estades d'estiu",
  'any_trimestre_academic' = 'Any/trimestre acadèmic',
  '1_3_mesos' = '1-3 mesos',
  '3_mesos_1_any' = '3 mesos - 1 any',
  'mes_un_any' = "Més d'un any",
  'indefint' = 'Indefint',
  'ns_nc' = 'NS/NC',
}
