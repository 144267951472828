import { lazy } from 'react';
import { ActivitatsProvider } from './ActivitatsContext';
const NewEdit = lazy(() => import('./Components/NewEdit/ActivitatNewEdit'));
const ActivitatsList = lazy(() => import('./Components/List/ActivitatsList'));

const ActivitatsRoutes = [
  {
    path: 'activitats/nova',
    element: (
      <ActivitatsProvider>
        <NewEdit />
      </ActivitatsProvider>
    ),
  },
  {
    path: 'activitats/:id/editar',
    element: (
      <ActivitatsProvider>
        <NewEdit />
      </ActivitatsProvider>
    ),
  },
  {
    path: 'activitats',
    element: (
      <ActivitatsProvider>
        <ActivitatsList />
      </ActivitatsProvider>
    ),
  },
];

export default ActivitatsRoutes;
