import { ReactSelectOption } from '../components/common/Form/MultiSelectField/MultiSelectField';

export const enumToSelectOptionArray = (
  enumObj: Record<string, string>,
): readonly ReactSelectOption[] => {
  return Object.keys(enumObj).map((key) => ({
    value: key,
    label: enumObj[key],
    color: '',
  }));
};
