import { Result, validatedApiCall } from '../api';
import { Professional } from '../../types/professionals/professionals';
import { APIValidationError } from '../../types/api/api';
import { UUID } from '../../types/standard';

export interface ProfessionalDTO {
  data: Professional;
}

export const getProfessional = async (
  id: UUID,
): Promise<Result<ProfessionalDTO, APIValidationError>> => {
  return validatedApiCall<ProfessionalDTO>(`/api/professionals/${id}`, {
    method: 'GET',
    auth: true,
  });
};
