import { lazy } from 'react';
import { CentresProvider } from './CentresContext';
const Centres = lazy(() => import('./Centres'));

const CentresRoutes = [
  {
    path: 'centres_educatius',
    element: (
      <CentresProvider>
        <Centres />
      </CentresProvider>
    ),
  },
];

export default CentresRoutes;
