import { GenericState } from '../../types/apiModule';
import createApiModule from '../createApiModule';
import {
  findCites,
  DisponibilitatsDTO,
} from '../../api/disponibilitats/findCites';
import { AssessoriaDisponibilitatDate } from '../../types/assessories/assessoria_disponibilitat';

export interface FindCitesModule extends GenericState<unknown> {
  data: DisponibilitatsDTO;
}

const initialState: FindCitesModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as AssessoriaDisponibilitatDate[],
  },
};

const findCitesModule = createApiModule({
  name: 'listDisponibilitats',
  apiMethod: findCites,
  initialState,
  parse: (disponibilitats) => disponibilitats,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess: () => {},
  onError: () => ({
    message: 'Error llistant les disponibilitats',
  }),
  onValidationError: () => ({
    message: 'Error llistant les disponibilitats',
  }),
});

export const {
  start: listDisponibilitats,
  success: listDisponibilitatsOk,
  error: listDisponibilitatsKo,
  refresh: listDisponibilitatsRefresh,
  reset: listDisponibilitatsReset,
} = findCitesModule.slice.actions;

export default findCitesModule;
