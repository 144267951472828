import { Result, validatedApiCall } from '../../api';
import { APIValidationError } from '../../../types/api/api';
import { AssessoriaRealitzada } from '../../../types/assessories/assessoria_realitzada';

export const newAssessoriaMobilitat = async (
  data: AssessoriaRealitzada,
): Promise<Result<AssessoriaRealitzada, APIValidationError>> => {
  return validatedApiCall<AssessoriaRealitzada>(
    `/api/assessories/mobilitat_internacional`,
    {
      method: 'POST',
      auth: true,
      body: JSON.stringify(data),
    },
  );
};
