import { RootState } from '../store';

/******************** ProfessionalS **************************************/
export const selectProfessionalsSlice = (state: RootState) =>
  state.professionals;
export const selectProfessionals = (state: RootState) =>
  selectProfessionalsSlice(state).data.data;
export const selectProfessionalsLoading = (state: RootState) =>
  selectProfessionalsSlice(state).loading;
export const selectProfessionalsPageInfo = (state: RootState) =>
  selectProfessionalsSlice(state).data.meta;

/******************** REGISTER Professional **************************************/
export const selectNewProfessional = (state: RootState) =>
  state.registerProfessional;
export const selectNewProfessionalLoading = (state: RootState) =>
  selectNewProfessional(state).loading;
export const selectNewProfessionalErrors = (state: RootState) =>
  selectNewProfessional(state).errors;

/******************** UPDATE Professional **************************************/
export const selectUpdateProfessional = (state: RootState) =>
  state.updateProfessional;
export const selectUpdateProfessionalLoading = (state: RootState) =>
  selectUpdateProfessional(state).loading;
export const selectUpdateProfessionalErrors = (state: RootState) =>
  selectUpdateProfessional(state).errors;

/******************** Professional **************************************/
export const selectProfessionalSlice = (state: RootState) => state.professional;
export const selectProfessional = (state: RootState) =>
  selectProfessionalSlice(state).data?.data;
export const selectProfessionalLoading = (state: RootState) =>
  selectProfessionalSlice(state).loading;
export const selectProfessionalErrors = (state: RootState) =>
  selectProfessionalSlice(state).errors;
/******************** DELETE Professional *****************************/
export const selectDeleteProfessional = (state: RootState) =>
  state.deleteProfessional.professional;

export const selectDeleteProfessionalActive = (state: RootState) =>
  state.deleteProfessional.active;

export const selectDeleteProfessionalLoading = (state: RootState) =>
  state.deleteProfessional.loading;
