import { Result, validatedApiCall } from '../../api';
import { APIValidationError } from '../../../types/api/api';
import { AssessoriaRealitzada } from '../../../types/assessories/assessoria_realitzada';

export const newAssessoriaHabitatge = async (
  data: AssessoriaRealitzada,
): Promise<Result<AssessoriaRealitzada, APIValidationError>> => {
  return validatedApiCall<AssessoriaRealitzada>(`/api/assessories/habitatges`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  });
};
