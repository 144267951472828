import { getAmbits as ambits, AmbitsDTO } from '../../api/ambits/getAmbits';
import { GenericState } from '../../types/apiModule';
import createApiModule from '../createApiModule';
import { Ambit } from '../../types/consultes/ambit';

export interface AmbitsModule extends GenericState<unknown> {
  data: AmbitsDTO;
}

const initialState: AmbitsModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Ambit[],
  },
};

const getAmbitsModule = createApiModule({
  name: 'getAmbits',
  apiMethod: ambits,
  initialState,
  parse: (ambits) => ambits,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess: () => {},
  onError: () => ({
    message: 'Error llistant els ambits',
  }),
  onValidationError: () => ({
    message: 'Error llistant els ambits',
  }),
});

export const {
  start: getAmbits,
  success: getAmbitsOk,
  error: getAmbitsKo,
  refresh: getAmbitsRefresh,
  reset: getAmbitsReset,
} = getAmbitsModule.slice.actions;

export default getAmbitsModule;
