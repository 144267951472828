export type ComConegut =
  | 'centre_estudi'
  | 'tutor'
  | 'jb'
  | 'orientador'
  | 'boca_orella'
  | 'web'
  | 'xarxes_socials'
  | 'cartells_publicitaris'
  | 'derivat_servei'
  | 'accions_dinamitzacio'
  | 'altre_servei'
  | 'jove_habitual';

export enum ComConegutEnum {
  'centre_estudi' = 'Centre estudi',
  'tutor' = 'Tutor/a',
  'jb' = 'Joves de Barcelona CE',
  'orientador' = 'Orientador/a',
  'boca_orella' = 'Boca orella',
  'web' = 'Web',
  'xarxes_socials' = 'Xarxes socials',
  'cartells_publicitaris' = 'Cartells Publicitaris',
  'derivat_servei' = "Ve derivat d'un altre servei",
  'accions_dinamitzacio' = 'Accions de dinamització de la informació',
  'altre_servei' = 'Altres serveis',
  'jove_habitual' = 'Jove habitual',
}
