import { updateConsulta } from '../../api/consultes/updateConsulta';
import createApiModule from '../createApiModule';
import { APIError, APIValidationError } from '../../types/api/api';

const updateConsultaModule = createApiModule({
  name: 'updateConsulta',
  apiMethod: updateConsulta,
  onSuccess: () => ({
    message: `La consulta s'ha modificat correctament.`,
    path: '/consultes',
  }),
  onError: (error: APIError) => ({
    message: error ? error.message : 'Error modificant la consulta',
  }),
  onValidationError: (error: APIValidationError) => ({
    message: error ? error.message : 'Error modificant la consulta',
  }),
});

export const {
  start: update,
  success: updateOk,
  error: updateKo,
} = updateConsultaModule.slice.actions;

export default updateConsultaModule;
