import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { Assessoria } from '../../types/assessories/assessoria';

export const newAssessoria = async (
  data: Assessoria,
): Promise<Result<Assessoria, APIValidationError>> => {
  return validatedApiCall<Assessoria>(`/api/assessories`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  });
};
