import { UpdatePasswordDTO } from './../../types/forgotPassword/forgotPassword';
import {
  AuthDTO,
  ChangePasswordDTO,
  LoginDTO,
  ResetPasswordDTO,
  UserInfo,
} from '../../types/auth/auth';
import { apiCall, Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';

export const login = async (
  data: LoginDTO,
): Promise<Result<AuthDTO, APIValidationError>> =>
  validatedApiCall<AuthDTO>(`/api/auth/login`, {
    method: 'POST',
    body: JSON.stringify(data),
  });

export const logout = async (): Promise<void> =>
  apiCall<never>(`/api/auth/logout`, {
    method: 'POST',
    auth: true,
  });

export const resetPassword = async (
  data: ResetPasswordDTO,
): Promise<Result<UserInfo, APIValidationError>> =>
  validatedApiCall<UserInfo>(`/api/auth/password`, {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  });

export const changePassword = async (
  data: ChangePasswordDTO,
): Promise<Result<ChangePasswordDTO, APIValidationError>> =>
  validatedApiCall<ChangePasswordDTO>(`/api/auth/profile`, {
    method: 'PATCH',
    auth: true,
    body: JSON.stringify(data),
  });

export const newPassword = async (
  data: UpdatePasswordDTO,
): Promise<Result<AuthDTO, APIValidationError>> => {
  return validatedApiCall<AuthDTO>(`/api/auth/new-password`, {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  });
};
