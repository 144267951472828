import {
  ActivitatsExport,
  getExportActivitats,
} from '../../api/activitats/getExportActivitats';
import { downloadFileFromContent } from '../../helpers/redirects/downloadFileFromContent';
import createApiModule from '../createApiModule';

const activitatsExportModule = createApiModule({
  name: 'ActivitatsExport',
  apiMethod: getExportActivitats,
  initialData: null,
  parse: (consulta) => consulta,
  onSuccess: (data: ActivitatsExport) => {
    const date_str = new Date().toISOString().replaceAll(':', '-');
    return downloadFileFromContent(
      data.content,
      `activitats_${date_str}.csv`,
      'text/csv;charset=utf-8;',
    );
  },
  onError: () => ({
    message: 'No hem pogut descargar el document',
  }),
  onValidationError: () => ({
    message: 'No hem pogut descargar el document',
  }),
});

export const {
  start: getActivitatsExport,
  success: getActivitatsExportOk,
  error: getActivitatsExportKo,
  reset: resetActivitatsExport,
} = activitatsExportModule.slice.actions;

export default activitatsExportModule;
