import { lazy } from 'react';

const NewPassword = lazy(() => import('./NewPassword'));

const NewPasswordRoutes = [
  {
    path: 'new_password',
    element: <NewPassword />,
  },
];

export default NewPasswordRoutes;
