import { RootState } from '../store';

/******************** GET **************************************/
export const selectAssessoriaSlice = (state: RootState) => state.assessoria;
export const selectAssessoria = (state: RootState) =>
  selectAssessoriaSlice(state).data?.data;
export const selectAssessoriaLoading = (state: RootState) =>
  selectAssessoriaSlice(state).loading;
export const selectAssessoriaErrors = (state: RootState) =>
  selectAssessoriaSlice(state).errors;

/******************** List **************************************/
export const selectAssessoriesSlice = (state: RootState) => state.assessories;
export const selectAssessories = (state: RootState) =>
  selectAssessoriesSlice(state).data.data;
export const selectAssessoriesLoading = (state: RootState) =>
  selectAssessoriesSlice(state).loading;
export const selectAssessoriesPageInfo = (state: RootState) =>
  selectAssessoriesSlice(state).data.meta;

/******************** UPDATE **************************************/
export const selectUpdateAssessoria = (state: RootState) =>
  state.updateAssessoria;
export const selectUpdateAssessoriaLoading = (state: RootState) =>
  selectUpdateAssessoria(state).loading;
export const selectUpdateAssessoriaErrors = (state: RootState) =>
  selectUpdateAssessoria(state).errors;

/******************** DELETE *****************************/
export const selectDeleteAssessoria = (state: RootState) =>
  state.deleteAssessoria.assessoria;

export const selectDeleteAssessoriaActive = (state: RootState) =>
  state.deleteAssessoria.active;

export const selectDeleteAssessoriaLoading = (state: RootState) =>
  state.deleteAssessoria.loading;

/******************** NEW **************************************/
export const selectNewAssessoria = (state: RootState) => state.newAssessoria;
export const selectNewAssessoriaLoading = (state: RootState) =>
  selectNewAssessoria(state).loading;
export const selectNewAssessoriaErrors = (state: RootState) =>
  selectNewAssessoria(state).errors;

/******************** MOBILITAT NEW **************************************/
export const selectNewAssessoriaMobilitat = (state: RootState) =>
  state.newAssessoriaMobilitat;
export const selectNewAssessoriaMobilitatLoading = (state: RootState) =>
  selectNewAssessoriaMobilitat(state).loading;
export const selectNewAssessoriaMobilitatErrors = (state: RootState) =>
  selectNewAssessoriaMobilitat(state).errors;

/******************** ACADEMICA NEW **************************************/
export const selectNewAssessoriaAcademica = (state: RootState) =>
  state.newAssessoriaAcademica;
export const selectNewAssessoriaAcademicaLoading = (state: RootState) =>
  selectNewAssessoriaAcademica(state).loading;
export const selectNewAssessoriaAcademicaErrors = (state: RootState) =>
  selectNewAssessoriaAcademica(state).errors;

/******************** HABITATGE NEW **************************************/
export const selectNewAssessoriaHabitatge = (state: RootState) =>
  state.newAssessoriaHabitatge;
export const selectNewAssessoriaHabitatgeLoading = (state: RootState) =>
  selectNewAssessoriaHabitatge(state).loading;
export const selectNewAssessoriaHabitatgeErrors = (state: RootState) =>
  selectNewAssessoriaHabitatge(state).errors;

/******************** EMPRENEDORIA NEW **************************************/
export const selectNewAssessoriaEmprenedoria = (state: RootState) =>
  state.newAssessoriaEmprenedoria;
export const selectNewAssessoriaEmprenedoriaLoading = (state: RootState) =>
  selectNewAssessoriaEmprenedoria(state).loading;
export const selectNewAssessoriaEmprenedoriaErrors = (state: RootState) =>
  selectNewAssessoriaEmprenedoria(state).errors;

/******************** LABORAL NEW **************************************/
export const selectNewAssessoriaLaboral = (state: RootState) =>
  state.newAssessoriaLaboral;
export const selectNewAssessoriaLaboralLoading = (state: RootState) =>
  selectNewAssessoriaLaboral(state).loading;
export const selectNewAssessoriaLaboralErrors = (state: RootState) =>
  selectNewAssessoriaLaboral(state).errors;
/******************** EXPORT *****************************/
export const selectExportAssessoria = (state: RootState) =>
  state.assessoriaExport;
export const selectExportAssessoriaLoading = (state: RootState) =>
  selectExportAssessoria(state).loading;
export const selectExportAssessoriaErrors = (state: RootState) =>
  selectExportAssessoria(state).errors;
