import InputField from '../../../../components/common/Form/InputField/InputField';
import TextArea from '../../../../components/common/Form/TextArea/TextArea';
import { AssessoriaAcademicaRealitzada } from '../../../../types/assessories/assessoria_academica.d';
import { AssessoriaAcademicaDerivacioEnum } from '../../../../types/assessories/assessoria_academica_derivacio.d';

interface SectionAcademicaProps {
  realitzada: AssessoriaAcademicaRealitzada;
}

const SectionAcademica: React.FC<SectionAcademicaProps> = ({
  realitzada,
}: SectionAcademicaProps) => {
  return (
    <>
      <InputField
        label="LLoc"
        input={{
          type: 'text',
          defaultValue: realitzada.realitzada,
          readOnly: true,
        }}
      />
      <InputField
        label="Assistents"
        input={{
          type: 'text',
          defaultValue: realitzada?.assistents,
          readOnly: true,
        }}
      />
      <InputField
        label="CFGS - CFGM"
        input={{
          type: 'text',
          defaultValue: realitzada?.cfgs_cfgm?.name,
          readOnly: true,
        }}
      />
      <InputField
        label="Universitat"
        input={{
          type: 'text',
          defaultValue: realitzada?.universitat?.name,
          readOnly: true,
        }}
      />
      <TextArea
        label="Temàtica"
        textarea={{
          defaultValue:
            realitzada?.tematiques?.map((el) => el.name).join(', ') || [],
          readOnly: true,
          name: 'tematica',
        }}
      />
      <TextArea
        label="Sub temàtica"
        textarea={{
          defaultValue:
            realitzada?.subtematiques?.map((el) => el.name).join(', ') || [],
          readOnly: true,
          name: 'subtematica',
        }}
      />
      <TextArea
        label="Derivacio"
        textarea={{
          defaultValue:
            realitzada?.derivacio
              ?.map((el) => AssessoriaAcademicaDerivacioEnum[el])
              .join(', ') || [],
          readOnly: true,
          name: 'derivacio',
        }}
      />
      <InputField
        label="Valoració assessor"
        input={{
          type: 'text',
          defaultValue: realitzada?.valoracio_assessor,
          readOnly: true,
        }}
      />
      <TextArea
        label="Comentaris"
        textarea={{
          defaultValue: realitzada?.comentaris,
          readOnly: true,
          name: 'comentaris',
        }}
      />
    </>
  );
};

export default SectionAcademica;
