import { newConsulta as createConsulta } from '../../api/consultes/newConsulta';
import createApiModule from '../createApiModule';

const newConsultaModule = createApiModule({
  name: 'newConsulta',
  apiMethod: createConsulta,
  onSuccess: () => ({
    message: `La consulta s'ha creat correctament.`,
    path: '/consultes',
  }),
  onError: () => ({
    message: 'Error creant la consulta',
  }),
  onValidationError: () => ({
    message: 'Error creant la consulta',
  }),
});

export const {
  start: newConsulta,
  success: newConsultaOk,
  error: newConsultaKo,
} = newConsultaModule.slice.actions;

export default newConsultaModule;
