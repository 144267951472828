import { postActivitat } from '../../api/activitats/postActivitat';
import createApiModule from '../createApiModule';
import { APIError, APIValidationError } from '../../types/api/api';

const postActivitatModule = createApiModule({
  name: 'postActivitat',
  apiMethod: postActivitat,
  onSuccess: () => ({
    message: `La activitat s'ha creat correctament.`,
    path: '/activitats',
  }),
  onError: (error: APIError) => ({
    message: error ? error.message : 'Error creant la activitat',
  }),
  onValidationError: (error: APIValidationError) => ({
    message: error ? error.message : 'Error creant la activitat',
  }),
});

export const {
  start: newActivitat,
  success: newActivitatOk,
  error: newActivitatKo,
} = postActivitatModule.slice.actions;

export default postActivitatModule;
