import { PayloadAction } from '@reduxjs/toolkit';
import { deleteProfessional } from '../../api/professionals/deleteProfessional';
import { GenericState } from '../../types/apiModule';
import { Professional } from '../../types/professionals/professionals';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { listProfessionalsRefresh } from './listProfessionalModule';

export interface deleteProfessionalState extends GenericState<unknown> {
  active: boolean;
  data: Professional[];
  professional: Professional | null;
}
const initialState: deleteProfessionalState = {
  errors: null,
  loading: false,
  data: [],
  active: false,
  professional: null,
};
const deleteProfessionalModule = createApiModule({
  name: 'deleteProfessional',
  initialState,
  apiMethod: deleteProfessional,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: "El/La professional s'ha esborrat correctament.",
    }),
    listProfessionalsRefresh(),
  ],
  onError: () => ({
    message: 'No es pot esborrar el/la professional/a.',
  }),
  onValidationError: (error) => ({
    message: error ? error.message : 'Error esborrant el/la professional/a',
  }),
  reducers: {
    show(state, action: PayloadAction<Professional>) {
      state.active = true;
      state.professional = action.payload;
    },
    hide(state) {
      state.active = false;
    },
  },
});

export const {
  start: del,
  success: delOk,
  error: delKo,
  hide,
  show,
} = deleteProfessionalModule.slice.actions;

export default deleteProfessionalModule;
