import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { Tematica } from '../../types/shared/tematica';

export interface TematiquesDTO {
  data: Tematica[];
}

export const getTematiques = async (
  tipus?: string,
): Promise<Result<TematiquesDTO, APIValidationError>> => {
  return validatedApiCall<TematiquesDTO>(
    `/api/interns/tematiques${tipus === undefined ? '' : `?tipus=${tipus}`}`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
