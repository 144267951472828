import { useParamSelector } from '../../hooks/useParamSelector';
import { selectHasAnyPermission } from '../../store/auth/selectors';
import { createContext, useContext } from 'react';
import { CitaCrudContextType } from '../../types/auth/permissionsContext';
import { AssessoriaTipusKeyEnum } from '../../types/assessories/assessoria_tipus.d';
import { Oficina } from '../../types/shared/oficina';
import { Cita } from '../../types/cites/cita.d';

export const CalendariContext = createContext<CitaCrudContextType>(
  {} as CitaCrudContextType,
);
export const useCalendariContext = () => useContext(CalendariContext);

interface ProviderProps {
  children: React.ReactNode;
}
// eslint-disable-next-line react/prop-types
export const CalendariProvider: React.FC<ProviderProps> = ({ children }) => {
  const canRead = useParamSelector(selectHasAnyPermission, ['read_cita']);
  const canCreate = useParamSelector(selectHasAnyPermission, ['create_cita']);
  const canEdit = useParamSelector(selectHasAnyPermission, ['edit_cita']);
  const canDelete = useParamSelector(selectHasAnyPermission, ['delete_cita']);

  const canReadApp = useParamSelector(selectHasAnyPermission, [
    'read_cita_app',
  ]);
  const canCreateApp = useParamSelector(selectHasAnyPermission, [
    'create_cita_app',
  ]);
  const canEditApp = useParamSelector(selectHasAnyPermission, [
    'edit_cita_app',
  ]);
  const canDeleteApp = useParamSelector(selectHasAnyPermission, [
    'delete_cita_app',
  ]);

  const canReadOficina = useParamSelector(selectHasAnyPermission, [
    'read_cita_oficina',
  ]);
  const canCreateOficina = useParamSelector(selectHasAnyPermission, [
    'create_cita_oficina',
  ]);
  const canEditOficina = useParamSelector(selectHasAnyPermission, [
    'edit_cita_oficina',
  ]);
  const canDeleteOficina = useParamSelector(selectHasAnyPermission, [
    'delete_cita_oficina',
  ]);

  const canEditAssessoriaApp = useParamSelector(selectHasAnyPermission, [
    'edit_assessoria_app',
  ]);
  const canEditAssessoriaOficina = useParamSelector(selectHasAnyPermission, [
    'edit_assessoria_oficina',
  ]);

  const canEditAssessoriaAcademicaApp = useParamSelector(
    selectHasAnyPermission,
    ['edit_assessoria_academica_app'],
  );
  const canEditAssessoriaEmprenedoriaApp = useParamSelector(
    selectHasAnyPermission,
    ['edit_assessoria_emprenedoria_app'],
  );
  const canEditAssessoriaHabitatgeApp = useParamSelector(
    selectHasAnyPermission,
    ['edit_assessoria_habitatge_app'],
  );
  const canEditAssessoriaLaboralApp = useParamSelector(selectHasAnyPermission, [
    'edit_assessoria_laboral_app',
  ]);
  const canEditAssessoriaMobilitatApp = useParamSelector(
    selectHasAnyPermission,
    ['edit_assessoria_mobilitat_app'],
  );

  const canEditAssessoriaAcademicaOficina = useParamSelector(
    selectHasAnyPermission,
    ['edit_assessoria_academica_oficina'],
  );
  const canEditAssessoriaEmprenedoriaOficina = useParamSelector(
    selectHasAnyPermission,
    ['edit_assessoria_emprenedoria_oficina'],
  );
  const canEditAssessoriaHabitatgeOficina = useParamSelector(
    selectHasAnyPermission,
    ['edit_assessoria_habitatge_oficina'],
  );
  const canEditAssessoriaLaboralOficina = useParamSelector(
    selectHasAnyPermission,
    ['edit_assessoria_laboral_oficina'],
  );
  const canEditAssessoriaMobilitatOficina = useParamSelector(
    selectHasAnyPermission,
    ['edit_assessoria_mobilitat_oficina'],
  );

  const checkCanEditAssessoria = (
    hasAssessoria: boolean,
    isRealitzada: boolean,
    cita: Cita,
    oficina_user?: Oficina,
  ): boolean => {
    const hasAssessoriaPendiente = hasAssessoria && !isRealitzada;
    if (
      canEditAssessoriaApp ||
      (canEditAssessoriaAcademicaApp &&
        cita.tipus === AssessoriaTipusKeyEnum.academica) ||
      (canEditAssessoriaEmprenedoriaApp &&
        cita.tipus === AssessoriaTipusKeyEnum.emprenedoria) ||
      (canEditAssessoriaHabitatgeApp &&
        cita.tipus === AssessoriaTipusKeyEnum.habitatge) ||
      (canEditAssessoriaLaboralApp &&
        cita.tipus === AssessoriaTipusKeyEnum.laboral) ||
      (canEditAssessoriaMobilitatApp &&
        cita.tipus === AssessoriaTipusKeyEnum.mobilitat_internacional)
    ) {
      return hasAssessoriaPendiente && true;
    }
    const canEditPorTipo = _checkOficina(cita, oficina_user);
    if (
      (canEditAssessoriaOficina && canEditPorTipo) ||
      (canEditAssessoriaAcademicaOficina &&
        canEditPorTipo &&
        cita.tipus === AssessoriaTipusKeyEnum.academica) ||
      (canEditAssessoriaEmprenedoriaOficina &&
        canEditPorTipo &&
        cita.tipus === AssessoriaTipusKeyEnum.emprenedoria) ||
      (canEditAssessoriaHabitatgeOficina &&
        canEditPorTipo &&
        cita.tipus === AssessoriaTipusKeyEnum.habitatge) ||
      (canEditAssessoriaLaboralOficina &&
        canEditPorTipo &&
        cita.tipus === AssessoriaTipusKeyEnum.laboral) ||
      (canEditAssessoriaMobilitatOficina &&
        canEditPorTipo &&
        cita.tipus === AssessoriaTipusKeyEnum.mobilitat_internacional)
    ) {
      return hasAssessoriaPendiente && true;
    }

    return false;
  };

  const checkCanCreate = (cita: Cita, oficina_user?: Oficina): boolean => {
    if (canCreateApp) return true;
    if (canCreateOficina) {
      return _checkOficina(cita, oficina_user);
    }
    return false;
  };

  const checkCanDelete = (
    hasAssessoria: boolean,
    cita: Cita,
    oficina_user?: Oficina,
  ): boolean => {
    if (hasAssessoria) return false;
    if (canDeleteApp) return true;
    if (canDeleteOficina) {
      return _checkOficina(cita, oficina_user);
    }
    return false;
  };

  const _checkOficina = (cita: Cita, oficina_user?: Oficina): boolean => {
    const oficina_y_antenas_ids: string[] = [];
    if (cita && cita.oficina) {
      oficina_y_antenas_ids.push(cita.oficina.id);
      const antenas = cita.oficina.antenas;
      if (antenas) {
        for (const antena of cita.oficina.antenas) {
          oficina_y_antenas_ids.push(antena.id);
        }
      }
    }
    if (oficina_user && oficina_y_antenas_ids.includes(oficina_user?.id)) {
      return true;
    }
    return false;
  };

  const value = {
    canRead,
    canCreate,
    canEdit,
    canDelete,

    canReadApp,
    canCreateApp,
    canEditApp,
    canDeleteApp,

    canReadOficina,
    canCreateOficina,
    canEditOficina,
    canDeleteOficina,

    checkCanEditAssessoria,
    checkCanCreate,
    checkCanDelete,
  };

  return (
    <CalendariContext.Provider value={value}>
      {children}
    </CalendariContext.Provider>
  );
};
