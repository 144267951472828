import { useParamSelector } from '../../hooks/useParamSelector';
import { selectHasAnyPermission } from '../../store/auth/selectors';
import { createContext, useContext } from 'react';
import { AssessoriaCrudContextType } from '../../types/auth/permissionsContext';
import {
  AssessoriaTipus,
  AssessoriaTipusEnum,
  AssessoriaTipusKeyEnum,
} from '../../types/assessories/assessoria_tipus.d';
import { Oficina } from '../../types/shared/oficina';
import { Role, RoleEnum } from '../../types/auth/roles.d';
import { Permission, PermissionEnum } from '../../types/auth/permissions.d';

export const AssessoriesContext = createContext<AssessoriaCrudContextType>(
  {} as AssessoriaCrudContextType,
);
export const useAssessoriesContext = () => useContext(AssessoriesContext);

interface ProviderProps {
  children: React.ReactNode;
}
export const AssessoriesProvider: React.FC<ProviderProps> = ({
  // eslint-disable-next-line react/prop-types
  children,
}) => {
  const canRead = useParamSelector(selectHasAnyPermission, ['read_assessoria']);
  const canCreate = useParamSelector(selectHasAnyPermission, [
    'create_assessoria',
  ]);
  const canEdit = useParamSelector(selectHasAnyPermission, ['edit_assessoria']);
  const canDelete = useParamSelector(selectHasAnyPermission, [
    'delete_assessoria',
  ]);

  const canReadApp = useParamSelector(selectHasAnyPermission, [
    'read_assessoria_app',
  ]);
  const canCreateApp = useParamSelector(selectHasAnyPermission, [
    'create_assessoria_app',
  ]);
  const canEditApp = useParamSelector(selectHasAnyPermission, [
    'edit_assessoria_app',
  ]);
  const canDeleteApp = useParamSelector(selectHasAnyPermission, [
    'delete_assessoria_app',
  ]);
  const canReadOficina = useParamSelector(selectHasAnyPermission, [
    'read_assessoria_oficina',
  ]);
  const canCreateOficina = useParamSelector(selectHasAnyPermission, [
    'create_assessoria_oficina',
  ]);
  const canEditOficina = useParamSelector(selectHasAnyPermission, [
    'edit_assessoria_oficina',
  ]);
  const canDeleteOficina = useParamSelector(selectHasAnyPermission, [
    'delete_assessoria_oficina',
  ]);

  const canReadAcademicaApp = useParamSelector(selectHasAnyPermission, [
    'read_assessoria_academica_app',
  ]);
  const canCreateAcademicaApp = useParamSelector(selectHasAnyPermission, [
    'create_assessoria_academica_app',
  ]);
  const canEditAcademicaApp = useParamSelector(selectHasAnyPermission, [
    'edit_assessoria_academica_app',
  ]);
  const canDeleteAcademicaApp = useParamSelector(selectHasAnyPermission, [
    'delete_assessoria_academica_app',
  ]);
  const canReadAcademicaOficina = useParamSelector(selectHasAnyPermission, [
    'read_assessoria_academica_oficina',
  ]);
  const canCreateAcademicaOficina = useParamSelector(selectHasAnyPermission, [
    'create_assessoria_academica_oficina',
  ]);
  const canEditAcademicaOficina = useParamSelector(selectHasAnyPermission, [
    'edit_assessoria_academica_oficina',
  ]);
  const canDeleteAcademicaOficina = useParamSelector(selectHasAnyPermission, [
    'delete_assessoria_academica_oficina',
  ]);

  const canReadEmprenedoriaApp = useParamSelector(selectHasAnyPermission, [
    'read_assessoria_emprenedoria_app',
  ]);
  const canCreateEmprenedoriaApp = useParamSelector(selectHasAnyPermission, [
    'create_assessoria_emprenedoria_app',
  ]);
  const canEditEmprenedoriaApp = useParamSelector(selectHasAnyPermission, [
    'edit_assessoria_emprenedoria_app',
  ]);
  const canDeleteEmprenedoriaApp = useParamSelector(selectHasAnyPermission, [
    'delete_assessoria_emprenedoria_app',
  ]);
  const canReadEmprenedoriaOficina = useParamSelector(selectHasAnyPermission, [
    'read_assessoria_emprenedoria_oficina',
  ]);
  const canCreateEmprenedoriaOficina = useParamSelector(
    selectHasAnyPermission,
    ['create_assessoria_emprenedoria_oficina'],
  );
  const canEditEmprenedoriaOficina = useParamSelector(selectHasAnyPermission, [
    'edit_assessoria_emprenedoria_oficina',
  ]);
  const canDeleteEmprenedoriaOficina = useParamSelector(
    selectHasAnyPermission,
    ['delete_assessoria_emprenedoria_oficina'],
  );

  const canReadHabitatgeApp = useParamSelector(selectHasAnyPermission, [
    'read_assessoria_habitatge_app',
  ]);
  const canCreateHabitatgeApp = useParamSelector(selectHasAnyPermission, [
    'create_assessoria_habitatge_app',
  ]);
  const canEditHabitatgeApp = useParamSelector(selectHasAnyPermission, [
    'edit_assessoria_habitatge_app',
  ]);
  const canDeleteHabitatgeApp = useParamSelector(selectHasAnyPermission, [
    'delete_assessoria_habitatge_app',
  ]);
  const canReadHabitatgeOficina = useParamSelector(selectHasAnyPermission, [
    'read_assessoria_habitatge_oficina',
  ]);
  const canCreateHabitatgeOficina = useParamSelector(selectHasAnyPermission, [
    'create_assessoria_habitatge_oficina',
  ]);
  const canEditHabitatgeOficina = useParamSelector(selectHasAnyPermission, [
    'edit_assessoria_habitatge_oficina',
  ]);
  const canDeleteHabitatgeOficina = useParamSelector(selectHasAnyPermission, [
    'delete_assessoria_habitatge_oficina',
  ]);

  const canReadLaboralApp = useParamSelector(selectHasAnyPermission, [
    'read_assessoria_laboral_app',
  ]);
  const canCreateLaboralApp = useParamSelector(selectHasAnyPermission, [
    'create_assessoria_laboral_app',
  ]);
  const canEditLaboralApp = useParamSelector(selectHasAnyPermission, [
    'edit_assessoria_laboral_app',
  ]);
  const canDeleteLaboralApp = useParamSelector(selectHasAnyPermission, [
    'delete_assessoria_laboral_app',
  ]);
  const canReadLaboralOficina = useParamSelector(selectHasAnyPermission, [
    'read_assessoria_laboral_oficina',
  ]);
  const canCreateLaboralOficina = useParamSelector(selectHasAnyPermission, [
    'create_assessoria_laboral_oficina',
  ]);
  const canEditLaboralOficina = useParamSelector(selectHasAnyPermission, [
    'edit_assessoria_laboral_oficina',
  ]);
  const canDeleteLaboralOficina = useParamSelector(selectHasAnyPermission, [
    'delete_assessoria_laboral_oficina',
  ]);

  const canReadMobilitatApp = useParamSelector(selectHasAnyPermission, [
    'read_assessoria_mobilitat_app',
  ]);
  const canCreateMobilitatApp = useParamSelector(selectHasAnyPermission, [
    'create_assessoria_mobilitat_app',
  ]);
  const canEditMobilitatApp = useParamSelector(selectHasAnyPermission, [
    'edit_assessoria_mobilitat_app',
  ]);
  const canDeleteMobilitatApp = useParamSelector(selectHasAnyPermission, [
    'delete_assessoria_mobilitat_app',
  ]);
  const canReadMobilitatOficina = useParamSelector(selectHasAnyPermission, [
    'read_assessoria_mobilitat_oficina',
  ]);
  const canCreateMobilitatOficina = useParamSelector(selectHasAnyPermission, [
    'create_assessoria_mobilitat_oficina',
  ]);
  const canEditMobilitatOficina = useParamSelector(selectHasAnyPermission, [
    'edit_assessoria_mobilitat_oficina',
  ]);
  const canDeleteMobilitatOficina = useParamSelector(selectHasAnyPermission, [
    'delete_assessoria_mobilitat_oficina',
  ]);

  const checkCanRead = (
    isRealitzada: boolean,
    tipus: AssessoriaTipus,
    oficina_cita: Oficina,
    oficina_user?: Oficina,
  ): boolean => {
    if (
      canReadApp ||
      (canReadAcademicaApp && tipus === AssessoriaTipusKeyEnum.academica) ||
      (canReadEmprenedoriaApp &&
        tipus === AssessoriaTipusKeyEnum.emprenedoria) ||
      (canReadHabitatgeApp && tipus === AssessoriaTipusKeyEnum.habitatge) ||
      (canReadLaboralApp && tipus === AssessoriaTipusKeyEnum.laboral) ||
      (canReadMobilitatApp &&
        tipus === AssessoriaTipusKeyEnum.mobilitat_internacional)
    ) {
      return !isRealitzada && true;
    }
    const canReadPorTipo = _checkOficina(oficina_cita, oficina_user);
    if (
      (canReadOficina && canReadPorTipo) ||
      (canReadAcademicaOficina &&
        canReadPorTipo &&
        tipus === AssessoriaTipusKeyEnum.academica) ||
      (canReadEmprenedoriaOficina &&
        canReadPorTipo &&
        tipus === AssessoriaTipusKeyEnum.emprenedoria) ||
      (canReadHabitatgeOficina &&
        canReadPorTipo &&
        tipus === AssessoriaTipusKeyEnum.habitatge) ||
      (canReadLaboralOficina &&
        canReadPorTipo &&
        tipus === AssessoriaTipusKeyEnum.laboral) ||
      (canReadMobilitatOficina &&
        canReadPorTipo &&
        tipus === AssessoriaTipusKeyEnum.mobilitat_internacional)
    ) {
      return !isRealitzada && true;
    }
    return false;
  };

  const checkCanCreate = (
    tipus: AssessoriaTipus,
    oficina_cita: Oficina,
    oficina_user?: Oficina,
  ): boolean => {
    if (
      canCreateApp ||
      (canCreateAcademicaApp && tipus === AssessoriaTipusKeyEnum.academica) ||
      (canCreateEmprenedoriaApp &&
        tipus === AssessoriaTipusKeyEnum.emprenedoria) ||
      (canCreateHabitatgeApp && tipus === AssessoriaTipusKeyEnum.habitatge) ||
      (canCreateLaboralApp && tipus === AssessoriaTipusKeyEnum.laboral) ||
      (canCreateMobilitatApp &&
        tipus === AssessoriaTipusKeyEnum.mobilitat_internacional)
    ) {
      return true;
    }
    const canCreatePorTipo = _checkOficina(oficina_cita, oficina_user);
    if (
      (canCreateOficina && canCreatePorTipo) ||
      (canCreateAcademicaOficina &&
        canCreatePorTipo &&
        tipus === AssessoriaTipusKeyEnum.academica) ||
      (canCreateEmprenedoriaOficina &&
        canCreatePorTipo &&
        tipus === AssessoriaTipusKeyEnum.emprenedoria) ||
      (canCreateHabitatgeOficina &&
        canCreatePorTipo &&
        tipus === AssessoriaTipusKeyEnum.habitatge) ||
      (canCreateLaboralOficina &&
        canCreatePorTipo &&
        tipus === AssessoriaTipusKeyEnum.laboral) ||
      (canCreateMobilitatOficina &&
        canCreatePorTipo &&
        tipus === AssessoriaTipusKeyEnum.mobilitat_internacional)
    ) {
      return true;
    }
    return false;
  };

  const checkCanEdit = (
    isRealitzada: boolean,
    tipus: AssessoriaTipus,
    oficina_cita: Oficina,
    oficina_user?: Oficina,
  ): boolean => {
    if (
      canEditApp ||
      (canEditAcademicaApp && tipus === AssessoriaTipusKeyEnum.academica) ||
      (canEditEmprenedoriaApp &&
        tipus === AssessoriaTipusKeyEnum.emprenedoria) ||
      (canEditHabitatgeApp && tipus === AssessoriaTipusKeyEnum.habitatge) ||
      (canEditLaboralApp && tipus === AssessoriaTipusKeyEnum.laboral) ||
      (canEditMobilitatApp &&
        tipus === AssessoriaTipusKeyEnum.mobilitat_internacional)
    ) {
      return !isRealitzada && true;
    }
    const canEditPorTipo = _checkOficina(oficina_cita, oficina_user);
    if (
      (canEditOficina && canEditPorTipo) ||
      (canEditAcademicaOficina &&
        canEditPorTipo &&
        tipus === AssessoriaTipusKeyEnum.academica) ||
      (canEditEmprenedoriaOficina &&
        canEditPorTipo &&
        tipus === AssessoriaTipusKeyEnum.emprenedoria) ||
      (canEditHabitatgeOficina &&
        canEditPorTipo &&
        tipus === AssessoriaTipusKeyEnum.habitatge) ||
      (canEditLaboralOficina &&
        canEditPorTipo &&
        tipus === AssessoriaTipusKeyEnum.laboral) ||
      (canEditMobilitatOficina &&
        canEditPorTipo &&
        tipus === AssessoriaTipusKeyEnum.mobilitat_internacional)
    ) {
      return !isRealitzada && true;
    }
    return false;
  };

  const checkCanDelete = (
    isRealitzada: boolean,
    tipus: AssessoriaTipus,
    oficina_cita: Oficina,
    oficina_user?: Oficina,
  ): boolean => {
    if (
      canDeleteApp ||
      (canDeleteAcademicaApp && tipus === AssessoriaTipusKeyEnum.academica) ||
      (canDeleteEmprenedoriaApp &&
        tipus === AssessoriaTipusKeyEnum.emprenedoria) ||
      (canDeleteHabitatgeApp && tipus === AssessoriaTipusKeyEnum.habitatge) ||
      (canDeleteLaboralApp && tipus === AssessoriaTipusKeyEnum.laboral) ||
      (canDeleteMobilitatApp &&
        tipus === AssessoriaTipusKeyEnum.mobilitat_internacional)
    ) {
      return !isRealitzada && true;
    }
    const canDeletePorTipo = _checkOficina(oficina_cita, oficina_user);
    if (
      (canDeleteOficina && canDeletePorTipo) ||
      (canDeleteAcademicaOficina &&
        canDeletePorTipo &&
        tipus === AssessoriaTipusKeyEnum.academica) ||
      (canDeleteEmprenedoriaOficina &&
        canDeletePorTipo &&
        tipus === AssessoriaTipusKeyEnum.emprenedoria) ||
      (canDeleteHabitatgeOficina &&
        canDeletePorTipo &&
        tipus === AssessoriaTipusKeyEnum.habitatge) ||
      (canDeleteLaboralOficina &&
        canDeletePorTipo &&
        tipus === AssessoriaTipusKeyEnum.laboral) ||
      (canDeleteMobilitatOficina &&
        canDeletePorTipo &&
        tipus === AssessoriaTipusKeyEnum.mobilitat_internacional)
    ) {
      return !isRealitzada && true;
    }
    return false;
  };

  const getFilteredTipus = (
    user_role?: Role,
  ): [string, AssessoriaTipusEnum][] => {
    return Object.entries(AssessoriaTipusEnum).filter(
      (item) =>
        user_role &&
        ((RoleEnum[user_role] === RoleEnum.assesor_academica &&
          item[1] === AssessoriaTipusEnum.academica) ||
          (RoleEnum[user_role] === RoleEnum.assesor_emprenedoria &&
            item[1] === AssessoriaTipusEnum.emprenedoria) ||
          (RoleEnum[user_role] === RoleEnum.assesor_habitatge &&
            item[1] === AssessoriaTipusEnum.habitatge) ||
          (RoleEnum[user_role] === RoleEnum.assesor_laboral &&
            item[1] === AssessoriaTipusEnum.laboral) ||
          (RoleEnum[user_role] === RoleEnum.assesor_mobilitat &&
            item[1] === AssessoriaTipusEnum.mobilitat_internacional) ||
          !user_role.includes('assesor')),
    );
  };

  const getFilteredOficines = (
    permission: Permission,
    oficines: Oficina[],
    oficina_user?: Oficina,
  ): Oficina[] => {
    if (permission === PermissionEnum.edit_assessoria) {
      if (
        canEditApp ||
        canEditAcademicaApp ||
        canEditEmprenedoriaApp ||
        canEditHabitatgeApp ||
        canEditLaboralApp ||
        canEditMobilitatApp
      ) {
        return oficines;
      }
      if (
        canEditOficina ||
        canEditAcademicaOficina ||
        canEditEmprenedoriaOficina ||
        canEditHabitatgeOficina ||
        canEditLaboralOficina ||
        canEditMobilitatOficina
      ) {
        const filtered_oficines: Oficina[] = [];
        if (oficina_user) {
          filtered_oficines.push(oficina_user);
          const antenas = oficina_user.antenas;
          if (antenas) {
            for (const antena of antenas) {
              filtered_oficines.push(antena as Oficina);
            }
          }
        }
        return filtered_oficines;
      }
    }
    return oficines;
  };

  const _checkOficina = (
    oficina_cita: Oficina,
    oficina_user?: Oficina,
  ): boolean => {
    const oficina_y_antenas_ids: string[] = [];
    if (oficina_cita) {
      oficina_y_antenas_ids.push(oficina_cita.id);
      const antenas = oficina_cita.antenas;
      if (antenas) {
        for (const antena of antenas) {
          oficina_y_antenas_ids.push(antena.id);
        }
      }
    }
    if (oficina_user && oficina_y_antenas_ids.includes(oficina_user?.id)) {
      return true;
    }
    return false;
  };

  const value = {
    canRead,
    canCreate,
    canEdit,
    canDelete,

    canReadApp,
    canCreateApp,
    canEditApp,
    canDeleteApp,

    canReadOficina,
    canCreateOficina,
    canEditOficina,
    canDeleteOficina,

    checkCanRead,
    checkCanCreate,
    checkCanEdit,
    checkCanDelete,

    getFilteredTipus,
    getFilteredOficines,
  };

  return (
    <AssessoriesContext.Provider value={value}>
      {children}
    </AssessoriesContext.Provider>
  );
};
