import React from 'react';
import { connect } from 'react-redux';
import {
  hide,
  del as deleteConsulta,
} from '../../../store/consultes/deleteConsultaModule';

import { AppDispatch, RootState } from '../../../store/store';
import { UUID } from '../../../types/standard';
import Button from '../../common/Buttons/Button/Button';
import Loading from '../../common/Loading/Loading';
import Modal from '../../common/Modal/Modal';
import styles from '../changeActions.module.scss';
import { ReactComponent as IconTrash } from '../../../assets/moreMenu/trash.svg';
import ButtonsGroup from '../../common/Buttons/ButtonsGroup/ButtonsGroup';
import renderWhen from '../../../helpers/renderWhen';

import { Consulta } from '../../../types/consultes/consulta.d';
import {
  selectDeleteConsulta,
  selectDeleteConsultaActive,
  selectDeleteConsultaLoading,
} from '../../../store/consultes/selectors';

interface DeleteConsultaProps {
  consulta: Consulta | null;
  active: boolean;
  loading: boolean;
  closeModal: () => void;
  deleteConsulta: (id: UUID | undefined) => void;
}

const DeleteConsulta: React.FC<DeleteConsultaProps> = ({
  consulta,
  active,
  loading,
  closeModal,
  deleteConsulta,
}) => {
  // 'active' controls if the modal is open or close
  if (!active) return null;
  return (
    <>
      {consulta && (
        <Modal closeModal={closeModal}>
          <div className={styles.container}>
            <h1 className={styles.title}>
              <IconTrash className={`${styles.icon} ${styles.isIconTrash}`} />
              Eliminar consulta
            </h1>
            <p className={styles.description}>
              Estàs segur/a que vols eliminar la consulta?
            </p>
            <div className={styles.appointmentWrapper}>
              <div className={styles.description}>
                <p></p>
              </div>
            </div>
            <ButtonsGroup variant="center">
              <Button type="button" variant="negative" onClick={closeModal}>
                Cancel·lar
              </Button>
              {consulta.id && (
                <Button
                  variant="positive"
                  onClick={() => {
                    if (consulta) {
                      deleteConsulta(consulta.id);
                    }
                    closeModal();
                  }}
                  data-testid="submit_button"
                >
                  Eliminar
                </Button>
              )}
            </ButtonsGroup>
            {loading && <Loading />}
          </div>
        </Modal>
      )}
    </>
  );
};

const ConnectedDeleteConsulta = connect(
  (state: RootState) => ({
    consulta: selectDeleteConsulta(state),
    active: selectDeleteConsultaActive(state),
    loading: selectDeleteConsultaLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    deleteConsulta: (payload: UUID | undefined) =>
      dispatch(deleteConsulta(payload)),
    closeModal: () => dispatch(hide()),
  }),
)(DeleteConsulta);

export default renderWhen(ConnectedDeleteConsulta, selectDeleteConsultaActive);
