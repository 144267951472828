import {
  AssessoriaExport,
  getExportAssessoria,
} from '../../api/assessories/getExportAssessoria';
import { downloadFileFromContent } from '../../helpers/redirects/downloadFileFromContent';
import createApiModule from '../createApiModule';

const assessoriaExportModule = createApiModule({
  name: 'AssessoriaExport',
  apiMethod: getExportAssessoria,
  initialData: null,
  parse: (consulta) => consulta,
  onSuccess: (data: AssessoriaExport) => {
    const date_str = new Date().toISOString().replaceAll(':', '-');
    return downloadFileFromContent(
      data.content,
      `assessorias_${date_str}.csv`,
      'text/csv;charset=utf-8;',
    );
  },
  onError: () => ({
    message: 'No hem pogut descargar el document',
  }),
  onValidationError: () => ({
    message: 'No hem pogut descargar el document',
  }),
});

export const {
  start: getAssessoriaExport,
  success: getAssessoriaExportOk,
  error: getAssessoriaExportKo,
  reset: resetAssessoriaExport,
} = assessoriaExportModule.slice.actions;

export default assessoriaExportModule;
