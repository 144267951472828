import { useParamSelector } from '../../../../hooks/useParamSelector';
import { selectHasAnyPermission } from '../../../../store/auth/selectors';
import { createContext, useContext } from 'react';
import { ControlCrudContextType } from '../../../../types/auth/permissionsContext';

export const ControlContext = createContext<ControlCrudContextType>(
  {} as ControlCrudContextType,
);
export const useControlContext = () => useContext(ControlContext);

interface ProviderProps {
  children: React.ReactNode;
}
export const ControlProvider: React.FC<ProviderProps> = ({
  // eslint-disable-next-line react/prop-types
  children,
}) => {
  const canReadApp = useParamSelector(selectHasAnyPermission, [
    'read_configuracio_app',
  ]);
  const canEditApp = useParamSelector(selectHasAnyPermission, [
    'edit_configuracio_app',
  ]);

  const value = {
    canReadApp,
    canEditApp,
  };

  return (
    <ControlContext.Provider value={value}>{children}</ControlContext.Provider>
  );
};
