import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { Assessoria } from '../../types/assessories/assessoria.d';

export const updateAssessoria = async (
  data: Assessoria,
): Promise<Result<Assessoria, APIValidationError>> => {
  return validatedApiCall<Assessoria>(`/api/assessories/${data.id}`, {
    method: 'PATCH',
    auth: true,
    body: JSON.stringify(data),
  });
};
