export const formatDate = (
  date?: string | Date,
  withHour?: boolean,
): string => {
  if (!date) {
    return '-';
  }
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: withHour ? '2-digit' : undefined,
    minute: withHour ? '2-digit' : undefined,
  };

  return date ? new Date(date).toLocaleDateString('es-ES', options) : '-';
};
