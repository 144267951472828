import * as Yup from 'yup';

const validation = Yup.object().shape({
  tematica_id: Yup.array()
    .min(1, 'Indica almenys una temàtica')
    .required('Indica almenys una temàtica'),
  subtematica_id: Yup.array()
    .min(1, 'Indica almenys una subtemàtica')
    .required('Indica almenys una subtemàtica'),
});

export default validation;
