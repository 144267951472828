import {
  ConsultaExport,
  getExportConsulta,
} from '../../api/consultes/getExportConsulta';
import { downloadFileFromContent } from '../../helpers/redirects/downloadFileFromContent';
import createApiModule from '../createApiModule';

const consultaExportModule = createApiModule({
  name: 'ConsultaExport',
  apiMethod: getExportConsulta,
  parse: (consulta) => consulta,
  onSuccess: (data: ConsultaExport) => {
    const date_str = new Date().toISOString().replaceAll(':', '-');
    return downloadFileFromContent(
      data.content,
      `consultes_${date_str}.csv`,
      'text/csv;charset=utf-8;',
    );
  },
  onError: () => ({
    message: 'No hem pogut descargar el document',
  }),
  onValidationError: () => ({
    message: 'No hem pogut descargar el document',
  }),
});

export const {
  start: getConsultaExport,
  success: getConsultaExportOk,
  error: getConsultaExportKo,
  reset: resetConsultaExport,
} = consultaExportModule.slice.actions;

export default consultaExportModule;
