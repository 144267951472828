import React from 'react';
import { connect } from 'react-redux';
import {
  hide,
  del as deleteAssessoria,
} from '../../../store/assessories/deleteAssessoriaModule';

import { AppDispatch, RootState } from '../../../store/store';
import { UUID } from '../../../types/standard';
import Button from '../../common/Buttons/Button/Button';
import Loading from '../../common/Loading/Loading';
import Modal from '../../common/Modal/Modal';
import styles from '../changeActions.module.scss';
import { ReactComponent as IconTrash } from '../../../assets/moreMenu/trash.svg';
import ButtonsGroup from '../../common/Buttons/ButtonsGroup/ButtonsGroup';
import renderWhen from '../../../helpers/renderWhen';
import { Assessoria } from '../../../types/assessories/assessoria';
import {
  selectDeleteAssessoria,
  selectDeleteAssessoriaActive,
  selectDeleteAssessoriaLoading,
} from '../../../store/assessories/selectors';

interface DeleteAssessoriaProps {
  assessoria: Assessoria | null;
  active: boolean;
  loading: boolean;
  closeModal: () => void;
  deleteAssessoria: (id: UUID | undefined) => void;
}

const DeleteAssessoria: React.FC<DeleteAssessoriaProps> = ({
  assessoria,
  active,
  loading,
  closeModal,
  deleteAssessoria,
}) => {
  // 'active' controls if the modal is open or close
  if (!active) return null;
  return (
    <>
      {assessoria && (
        <Modal closeModal={closeModal}>
          <div className={styles.container}>
            <h1 className={styles.title}>
              <IconTrash className={`${styles.icon} ${styles.isIconTrash}`} />
              Eliminar assessoria
            </h1>
            <p className={styles.description}>
              Estàs segur/a que vols eliminar l&apos;assessoria?
            </p>
            <div className={styles.appointmentWrapper}>
              <div className={styles.description}>
                <p></p>
              </div>
            </div>
            <ButtonsGroup variant="center">
              <Button type="button" variant="negative" onClick={closeModal}>
                Cancel·lar
              </Button>
              {assessoria.id && (
                <Button
                  variant="positive"
                  onClick={() => {
                    if (assessoria) {
                      deleteAssessoria(assessoria.id);
                    }
                    closeModal();
                  }}
                  data-testid="submit_button"
                >
                  Eliminar
                </Button>
              )}
            </ButtonsGroup>
            {loading && <Loading />}
          </div>
        </Modal>
      )}
    </>
  );
};

const ConnectedDeleteAssessoria = connect(
  (state: RootState) => ({
    assessoria: selectDeleteAssessoria(state),
    active: selectDeleteAssessoriaActive(state),
    loading: selectDeleteAssessoriaLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    deleteAssessoria: (payload: UUID | undefined) =>
      dispatch(deleteAssessoria(payload)),
    closeModal: () => dispatch(hide()),
  }),
)(DeleteAssessoria);

export default renderWhen(
  ConnectedDeleteAssessoria,
  selectDeleteAssessoriaActive,
);
