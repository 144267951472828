import {
  GetCentreEducatiu,
  getCentreEducatius as getCentreEducatiusApi,
} from '../../api/centreEducatius/getCentreEducatius';
import { GenericState } from '../../types/apiModule';
import { CentreEducatiu } from '../../types/centreEducatiu/centreEducatiu.d';
import createApiModule from '../createApiModule';

export interface CentreEducatiuModule extends GenericState<unknown> {
  data: GetCentreEducatiu;
}

const initialState: CentreEducatiuModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as CentreEducatiu[],
  },
};

const getCentreEducatiuModule = createApiModule({
  name: 'getCentreEducatiu',
  apiMethod: getCentreEducatiusApi,
  initialState,
  parse: (centreEducatius) => centreEducatius,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess: () => {},
  onError: () => ({
    message: 'Error llistant els centres educatius',
  }),
  onValidationError: () => ({
    message: 'Error llistant els centres educatius',
  }),
});

export const {
  start: getCentreEducatius,
  success: getCentreEducatiusOk,
  error: getCentreEducatiusKo,
  refresh: getCentreEducatiusRefresh,
  reset: getCentreEducatiusReset,
} = getCentreEducatiuModule.slice.actions;

export default getCentreEducatiuModule;
