import { newCita as createCita } from '../../api/cites/newCita';
import createApiModule from '../createApiModule';

const newCitaModule = createApiModule({
  name: 'newCita',
  apiMethod: createCita,
  onSuccess: () => ({
    message: 'La cita ha estat creada',
    path: '/calendari',
  }),
  onError: (error) => ({
    message: error.message ?? 'Error creant la cita',
  }),
  onValidationError: () => ({
    message: 'Error creant la cita',
  }),
});

export const {
  start: newCita,
  success: newCitaOk,
  error: newCitaKo,
} = newCitaModule.slice.actions;

export default newCitaModule;
