export type SituacioAdministrativa =
  | 'dni'
  | 'nie_residencia'
  | 'nie_treball'
  | 'passaport';

export enum SituacioAdministrativaEnum {
  'dni' = 'DNI',
  'nie_residencia' = 'NIE amb permís de residència',
  'nie_treball' = 'NIE amb permís de treball',
  'passaport' = 'Passaport',
}
