import { patchActivitat } from '../../api/activitats/patchActivitat';
import createApiModule from '../createApiModule';
import { APIError, APIValidationError } from '../../types/api/api';

const patchActivitatModule = createApiModule({
  name: 'patchActivitat',
  apiMethod: patchActivitat,
  onSuccess: () => ({
    message: `La activitat s'ha modificat correctament.`,
    path: '/activitats',
  }),
  onError: (error: APIError) => ({
    message: error ? error.message : 'Error modificant la activitat',
  }),
  onValidationError: (error: APIValidationError) => ({
    message: error ? error.message : 'Error modificant la activitat',
  }),
});

export const {
  start: updateActivitat,
  success: updateActivitatOk,
  error: updateActivitatKo,
} = patchActivitatModule.slice.actions;

export default patchActivitatModule;
