import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import toQueryParams from '../../utils/toQueryParams';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { Professional } from '../../types/professionals/professionals';

export interface GetProfessionalsDTO {
  role_id?: string;
  oficina_id?: string;
  page?: number;
  size?: number;
}

export interface ProfessionalsDTO {
  data: Professional[];
  links: LinksPage;
  meta: PageInfo;
}

export const getProfessionals = async (
  data: GetProfessionalsDTO,
): Promise<Result<ProfessionalsDTO, APIValidationError>> => {
  return validatedApiCall<ProfessionalsDTO>(
    `/api/professionals?${toQueryParams(
      data as unknown as Record<string, string>,
    )}`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
