import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { Assessoria } from '../../types/assessories/assessoria';
import { UUID } from '../../types/standard';

export const patchAssessoriaAssistit = async (
  id: UUID,
): Promise<Result<Assessoria, APIValidationError>> => {
  return validatedApiCall<Assessoria>(`/api/assessories/${id}/assistit`, {
    method: 'PATCH',
    auth: true,
    body: JSON.stringify(id),
  });
};
