import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import toQueryParams from '../../utils/toQueryParams';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { Assessoria } from '../../types/assessories/assessoria.d';
import { UUID, ISO8601DateString } from '../../types/standard';

export interface GetAssessoriesDTO {
  oficina_id?: UUID;
  tipus?: string;
  from: ISO8601DateString;
  to: ISO8601DateString;
  page: number;
  size: number;
}

export interface AssessoriesDTO {
  data: Assessoria[];
  links: LinksPage;
  meta: PageInfo;
}

export const getAssessories = async (
  data: GetAssessoriesDTO,
): Promise<Result<AssessoriesDTO, APIValidationError>> => {
  return validatedApiCall<AssessoriesDTO>(
    `/api/assessories?${toQueryParams(
      data as unknown as Record<string, string>,
    )}`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
