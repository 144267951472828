export type Permission =
  | 'read_usuari'
  | 'create_usuari'
  | 'edit_usuari'
  | 'delete_usuari'
  | 'read_consulta'
  | 'create_consulta'
  | 'edit_consulta'
  | 'delete_consulta'
  | 'read_cita'
  | 'create_cita'
  | 'edit_cita'
  | 'delete_cita'
  | 'read_activitat'
  | 'create_activitat'
  | 'edit_activitat'
  | 'delete_activitat'
  | 'read_assessoria'
  | 'create_assessoria'
  | 'edit_assessoria'
  | 'delete_assessoria'
  | 'read_configuracio_app'
  | 'edit_configuracio_app'
  | 'read_usuari_app'
  | 'create_usuari_app'
  | 'edit_usuari_app'
  | 'delete_usuari_app'
  | 'read_consulta_app'
  | 'create_consulta_app'
  | 'edit_consulta_app'
  | 'delete_consulta_app'
  | 'filters_consulta_app'
  | 'read_cita_app'
  | 'create_cita_app'
  | 'edit_cita_app'
  | 'delete_cita_app'
  | 'read_activitat_app'
  | 'create_activitat_app'
  | 'edit_activitat_app'
  | 'delete_activitat_app'
  | 'filters_activitat_app'
  | 'read_assessoria_app'
  | 'create_assessoria_app'
  | 'edit_assessoria_app'
  | 'delete_assessoria_app'
  | 'read_assessoria_academica_app'
  | 'create_assessoria_academica_app'
  | 'edit_assessoria_academica_app'
  | 'delete_assessoria_academica_app'
  | 'read_assessoria_emprenedoria_app'
  | 'create_assessoria_emprenedoria_app'
  | 'edit_assessoria_emprenedoria_app'
  | 'delete_assessoria_emprenedoria_app'
  | 'read_assessoria_habitatge_app'
  | 'create_assessoria_habitatge_app'
  | 'edit_assessoria_habitatge_app'
  | 'delete_assessoria_habitatge_app'
  | 'read_assessoria_laboral_app'
  | 'create_assessoria_laboral_app'
  | 'edit_assessoria_laboral_app'
  | 'delete_assessoria_laboral_app'
  | 'read_assessoria_mobilitat_app'
  | 'create_assessoria_mobilitat_app'
  | 'edit_assessoria_mobilitat_app'
  | 'delete_assessoria_mobilitat_app'
  | 'read_usuari_oficina'
  | 'create_usuari_oficina'
  | 'edit_usuari_oficina'
  | 'delete_usuari_oficina'
  | 'read_consulta_oficina'
  | 'create_consulta_oficina'
  | 'edit_consulta_oficina'
  | 'delete_consulta_oficina'
  | 'filters_consulta_oficina'
  | 'read_cita_oficina'
  | 'create_cita_oficina'
  | 'edit_cita_oficina'
  | 'delete_cita_oficina'
  | 'read_activitat_oficina'
  | 'create_activitat_oficina'
  | 'edit_activitat_oficina'
  | 'delete_activitat_oficina'
  | 'filters_activitat_oficina'
  | 'read_assessoria_oficina'
  | 'create_assessoria_oficina'
  | 'edit_assessoria_oficina'
  | 'delete_assessoria_oficina'
  | 'read_assessoria_academica_oficina'
  | 'create_assessoria_academica_oficina'
  | 'edit_assessoria_academica_oficina'
  | 'delete_assessoria_academica_oficina'
  | 'read_assessoria_emprenedoria_oficina'
  | 'create_assessoria_emprenedoria_oficina'
  | 'edit_assessoria_emprenedoria_oficina'
  | 'delete_assessoria_emprenedoria_oficina'
  | 'read_assessoria_habitatge_oficina'
  | 'create_assessoria_habitatge_oficina'
  | 'edit_assessoria_habitatge_oficina'
  | 'delete_assessoria_habitatge_oficina'
  | 'read_assessoria_laboral_oficina'
  | 'create_assessoria_laboral_oficina'
  | 'edit_assessoria_laboral_oficina'
  | 'delete_assessoria_laboral_oficina'
  | 'read_assessoria_mobilitat_oficina'
  | 'create_assessoria_mobilitat_oficina'
  | 'edit_assessoria_mobilitat_oficina'
  | 'delete_assessoria_mobilitat_oficina';

export enum PermissionEnum {
  'read_usuari' = 'read_usuari',
  'create_usuari' = 'create_usuari',
  'edit_usuari' = 'edit_usuari',
  'delete_usuari' = 'delete_usuari',
  'read_consulta' = 'read_consulta',
  'create_consulta' = 'create_consulta',
  'edit_consulta' = 'edit_consulta',
  'delete_consulta' = 'delete_consulta',
  'read_cita' = 'read_cita',
  'create_cita' = 'create_cita',
  'edit_cita' = 'edit_cita',
  'delete_cita' = 'delete_cita',
  'read_activitat' = 'read_activitat',
  'create_activitat' = 'create_activitat',
  'edit_activitat' = 'edit_activitat',
  'delete_activitat' = 'delete_activitat',
  'read_assessoria' = 'read_assessoria',
  'create_assessoria' = 'create_assessoria',
  'edit_assessoria' = 'edit_assessoria',
  'delete_assessoria' = 'delete_assessoria',
  'read_configuracio_app' = 'read_configuracio_app',
  'edit_configuracio_app' = 'edit_configuracio_app',
  'read_usuari_app' = 'read_usuari_app',
  'create_usuari_app' = 'create_usuari_app',
  'edit_usuari_app' = 'edit_usuari_app',
  'delete_usuari_app' = 'delete_usuari_app',
  'read_consulta_app' = 'read_consulta_app',
  'create_consulta_app' = 'create_consulta_app',
  'edit_consulta_app' = 'edit_consulta_app',
  'delete_consulta_app' = 'delete_consulta_app',
  'filters_consulta_app' = 'filters_consulta_app',
  'read_cita_app' = 'read_cita_app',
  'create_cita_app' = 'create_cita_app',
  'edit_cita_app' = 'edit_cita_app',
  'delete_cita_app' = 'delete_cita_app',
  'read_activitat_app' = 'read_activitat_app',
  'create_activitat_app' = 'create_activitat_app',
  'edit_activitat_app' = 'edit_activitat_app',
  'delete_activitat_app' = 'delete_activitat_app',
  'filters_activitat_app' = 'filters_activitat_app',
  'read_assessoria_app' = 'read_assessoria_app',
  'create_assessoria_app' = 'create_assessoria_app',
  'edit_assessoria_app' = 'edit_assessoria_app',
  'delete_assessoria_app' = 'delete_assessoria_app',
  'read_assessoria_academica_app' = 'read_assessoria_academica_app',
  'create_assessoria_academica_app' = 'create_assessoria_academica_app',
  'edit_assessoria_academica_app' = 'edit_assessoria_academica_app',
  'delete_assessoria_academica_app' = 'delete_assessoria_academica_app',
  'read_assessoria_emprenedoria_app' = 'read_assessoria_emprenedoria_app',
  'create_assessoria_emprenedoria_app' = 'create_assessoria_emprenedoria_app',
  'edit_assessoria_emprenedoria_app' = 'edit_assessoria_emprenedoria_app',
  'delete_assessoria_emprenedoria_app' = 'delete_assessoria_emprenedoria_app',
  'read_assessoria_habitatge_app' = 'read_assessoria_habitatge_app',
  'create_assessoria_habitatge_app' = 'create_assessoria_habitatge_app',
  'edit_assessoria_habitatge_app' = 'edit_assessoria_habitatge_app',
  'delete_assessoria_habitatge_app' = 'delete_assessoria_habitatge_app',
  'read_assessoria_laboral_app' = 'read_assessoria_laboral_app',
  'create_assessoria_laboral_app' = 'create_assessoria_laboral_app',
  'edit_assessoria_laboral_app' = 'edit_assessoria_laboral_app',
  'delete_assessoria_laboral_app' = 'delete_assessoria_laboral_app',
  'read_assessoria_mobilitat_app' = 'read_assessoria_mobilitat_app',
  'create_assessoria_mobilitat_app' = 'create_assessoria_mobilitat_app',
  'edit_assessoria_mobilitat_app' = 'edit_assessoria_mobilitat_app',
  'delete_assessoria_mobilitat_app' = 'delete_assessoria_mobilitat_app',
  'read_usuari_oficina' = 'read_usuari_oficina',
  'create_usuari_oficina' = 'create_usuari_oficina',
  'edit_usuari_oficina' = 'edit_usuari_oficina',
  'delete_usuari_oficina' = 'delete_usuari_oficina',
  'read_consulta_oficina' = 'read_consulta_oficina',
  'create_consulta_oficina' = 'create_consulta_oficina',
  'edit_consulta_oficina' = 'edit_consulta_oficina',
  'delete_consulta_oficina' = 'delete_consulta_oficina',
  'filters_consulta_oficina' = 'filters_consulta_oficina',
  'read_cita_oficina' = 'read_cita_oficina',
  'create_cita_oficina' = 'create_cita_oficina',
  'edit_cita_oficina' = 'edit_cita_oficina',
  'delete_cita_oficina' = 'delete_cita_oficina',
  'read_activitat_oficina' = 'read_activitat_oficina',
  'create_activitat_oficina' = 'create_activitat_oficina',
  'edit_activitat_oficina' = 'edit_activitat_oficina',
  'delete_activitat_oficina' = 'delete_activitat_oficina',
  'filters_activitat_oficina' = 'filters_activitat_oficina',
  'read_assessoria_oficina' = 'read_assessoria_oficina',
  'create_assessoria_oficina' = 'create_assessoria_oficina',
  'edit_assessoria_oficina' = 'edit_assessoria_oficina',
  'delete_assessoria_oficina' = 'delete_assessoria_oficina',
  'read_assessoria_academica_oficina' = 'read_assessoria_academica_oficina',
  'create_assessoria_academica_oficina' = 'create_assessoria_academica_oficina',
  'edit_assessoria_academica_oficina' = 'edit_assessoria_academica_oficina',
  'delete_assessoria_academica_oficina' = 'delete_assessoria_academica_oficina',
  'read_assessoria_emprenedoria_oficina' = 'read_assessoria_emprenedoria_oficina',
  'create_assessoria_emprenedoria_oficina' = 'create_assessoria_emprenedoria_oficina',
  'edit_assessoria_emprenedoria_oficina' = 'edit_assessoria_emprenedoria_oficina',
  'delete_assessoria_emprenedoria_oficina' = 'delete_assessoria_emprenedoria_oficina',
  'read_assessoria_habitatge_oficina' = 'read_assessoria_habitatge_oficina',
  'create_assessoria_habitatge_oficina' = 'create_assessoria_habitatge_oficina',
  'edit_assessoria_habitatge_oficina' = 'edit_assessoria_habitatge_oficina',
  'delete_assessoria_habitatge_oficina' = 'delete_assessoria_habitatge_oficina',
  'read_assessoria_laboral_oficina' = 'read_assessoria_laboral_oficina',
  'create_assessoria_laboral_oficina' = 'create_assessoria_laboral_oficina',
  'edit_assessoria_laboral_oficina' = 'edit_assessoria_laboral_oficina',
  'delete_assessoria_laboral_oficina' = 'delete_assessoria_laboral_oficina',
  'read_assessoria_mobilitat_oficina' = 'read_assessoria_mobilitat_oficina',
  'create_assessoria_mobilitat_oficina' = 'create_assessoria_mobilitat_oficina',
  'edit_assessoria_mobilitat_oficina' = 'edit_assessoria_mobilitat_oficina',
  'delete_assessoria_mobilitat_oficina' = 'delete_assessoria_mobilitat_oficina',
}
