import { UUID } from '../standard';
import { ReactSelectOption } from '../../components/common/Form/MultiSelectField/MultiSelectField';

export type Tematica = {
  subtematica?: Tematica[];
  ambit: string;
  name: string;
  id: UUID;
  tipus: TematicaTipus[];
};

export type TematicaTipus = {
  id: UUID;
  tipus: AssessoriaTipus;
};

export const tematicaToSelectOption = (
  tematica: Tematica,
): ReactSelectOption => {
  return {
    value: tematica.id,
    label: tematica.name,
    childs: tematica.subtematica || [],
  };
};
