import { lazy } from 'react';

const SendPassword = lazy(() => import('./SendPassword'));
const ResetPassword = lazy(() => import('./ResetPassword'));

const ForgotPasswordRoutes = [
  {
    path: 'recordar_contrasenya',
    children: [
      {
        index: true,
        element: <SendPassword />,
      },
      {
        path: ':token/:email',
        element: <ResetPassword />,
      },
    ],
  },
];

export default ForgotPasswordRoutes;
