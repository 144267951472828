import { Result, validatedApiCall } from '../../api';
import { APIValidationError } from '../../../types/api/api';
import { DataRules } from '../../../types/configuracio/control/control';

export const updateDataRules = async (
  data: DataRules,
): Promise<Result<DataRules, APIValidationError>> => {
  return validatedApiCall<DataRules>(`/api/config/data_rules`, {
    method: 'PATCH',
    auth: true,
    body: JSON.stringify(data),
  });
};
