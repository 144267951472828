import { PayloadAction } from '@reduxjs/toolkit';
import { deleteAssessoria } from '../../api/assessories/deleteAssessoria';
import { GenericState } from '../../types/apiModule';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { Assessoria } from '../../types/assessories/assessoria';
import { listAssessoriesRefresh } from './listAssessoriaModule';

export interface deleteConsultaState extends GenericState<unknown> {
  active: boolean;
  data: Assessoria[];
  assessoria: Assessoria | null;
}
const initialState: deleteConsultaState = {
  errors: null,
  loading: false,
  data: [],
  active: false,
  assessoria: null,
};
const deleteAssessoriaModule = createApiModule({
  name: 'deleteAssessoria',
  initialState,
  apiMethod: deleteAssessoria,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: "L'assessoria s'ha esborrat correctament.",
    }),
    listAssessoriesRefresh(),
  ],
  onError: () => ({
    message: "No es pot esborrar l'assessoria.",
  }),
  onValidationError: (error) => ({
    message: error ? error.message : "Error esborrant l'assessoria",
  }),
  reducers: {
    show(state, action: PayloadAction<Assessoria>) {
      state.active = true;
      state.assessoria = action.payload;
    },
    hide(state) {
      state.active = false;
    },
  },
});

export const {
  start: del,
  success: delOk,
  error: delKo,
  hide,
  show,
} = deleteAssessoriaModule.slice.actions;

export default deleteAssessoriaModule;
