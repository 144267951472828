import React, { ReactElement } from 'react';
import { FieldError, FieldValues } from 'react-hook-form';
import isFieldError from '../../../../helpers/isFieldError';
import styles from './textArea.module.scss';

export interface TextAreaProps<T extends FieldValues> {
  textarea: React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > & {
    name: keyof T;
  };
  label?: string | ReactElement;
  error?: string | FieldError;
}

const TextArea = <T extends FieldValues>({
  textarea,
  label,
  error,
}: TextAreaProps<T>): ReactElement => {
  const id = textarea.id || textarea.name;
  const errorMessage: string | JSX.Element = isFieldError(error)
    ? error.message || ''
    : error || '';

  return (
    <div className={styles.row}>
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      )}
      {textarea.readOnly ? (
        <div className={styles.readOnly}>{textarea.defaultValue}</div>
      ) : (
        <textarea
          data-testid={id}
          className={`${styles.textarea} ${error && styles.hasError}`}
          id={id}
          {...textarea}
        />
      )}
      <p className={styles.errorText}>{errorMessage}</p>
    </div>
  );
};

export default TextArea;
