import { newAssessoriaHabitatge as createAssessoria } from '../../api/assessories/realitzades/newAssessoriaHabitatge';
import createApiModule from '../createApiModule';
import { APIError, APIValidationError } from '../../types/api/api';

const newAssessoriaHabitatgeModule = createApiModule({
  name: 'newAssessoriaHabitatge',
  apiMethod: createAssessoria,
  onSuccess: () => ({
    message: "L'assessoria s'ha realitzat correctament.",
    path: '/assessories',
  }),
  onError: (error: APIError) => ({
    message: error ? error.message : "Error realitzant l'assessoria",
  }),
  onValidationError: (error: APIValidationError) => ({
    message: error ? error.message : "Error realitzant l'assessoria",
  }),
});

export const {
  start: newAssessoriaHabitatge,
  success: newAssessoriaHabitatgeOk,
  error: newAssessoriaHabitatgeKo,
} = newAssessoriaHabitatgeModule.slice.actions;

export default newAssessoriaHabitatgeModule;
