import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIError } from '../../types/api/api';
import { Oficina } from '../../types/professionals/oficines';

export type OficinaState = {
  loading: boolean;
  errors: APIError | null;
  oficines: Oficina[];
};

const initialState: OficinaState = {
  loading: false,
  errors: null,
  oficines: [],
};

const oficinesSlice = createSlice({
  name: 'oficina',
  initialState,
  reducers: {
    loadOficines(state) {
      state.loading = true;
      state.errors = null;
      state.oficines = [];
    },
    loadOficinesOk(state, action: PayloadAction<Oficina[]>) {
      state.loading = false;
      state.errors = null;
      state.oficines = action.payload;
    },
    loadOficinesKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.errors = action.payload;
      state.oficines = [];
    },
    resetOficines() {
      return { ...initialState };
    },
  },
});

export const { loadOficines, loadOficinesOk, loadOficinesKo, resetOficines } =
  oficinesSlice.actions;

export default oficinesSlice;
