import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';

export interface ActivitatsExport {
  content: string;
}

export const getExportActivitats = async (): Promise<
  Result<ActivitatsExport, APIValidationError>
> => {
  return validatedApiCall<ActivitatsExport>(`/api/activitats/exports/csv`, {
    method: 'GET',
    auth: true,
  });
};
