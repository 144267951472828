export type Curs =
  | 'sense_estudis'
  | 'cursant_estudis_obligatoris'
  | 'cursant_estudis_postobligatoris'
  | 'cursant_estudis_universitaris'
  | 'estudis_obligatoris'
  | 'estudis_postobligatoris'
  | 'estudis_universitaris';

export enum CursEnum {
  'sense_estudis' = 'Sense estudis',
  'cursant_estudis_obligatoris' = 'Cursant estudis obligatoris',
  'cursant_estudis_postobligatoris' = 'Cursant estudis post-obligatoris',
  'cursant_estudis_universitaris' = 'Cursant estudis universitaris',
  'estudis_obligatoris' = 'Estudis obligatoris',
  'estudis_postobligatoris' = 'Estudis post-obligatoris',
  'estudis_universitaris' = 'Estudis universitaris',
}
