import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { Area } from '../../types/areas/area.d';

export interface GetAreas {
  data: Area[];
}

export const getAreas = async (): Promise<
  Result<GetAreas, APIValidationError>
> => {
  return validatedApiCall<GetAreas>(`/api/interns/areas`, {
    method: 'GET',
    auth: true,
  });
};
