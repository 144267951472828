import * as Yup from 'yup';

const validation = Yup.object().shape({
  realitzada: Yup.string().required("Indica on s'ha realitzat"),
  tematica_id: Yup.array()
    .min(1, 'Indica almenys una temàtica')
    .required('Indica almenys una temàtica'),
});

export default validation;
