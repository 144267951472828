import {
  ActivitatsDTO,
  getActivitats,
} from '../../api/activitats/getActivitats';
import { Activitat } from '../../types/activitat/Activitat';
import { GenericState } from '../../types/apiModule';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import createApiModule from '../createApiModule';

export interface ActivitatsModule extends GenericState<unknown> {
  data: ActivitatsDTO;
}

const initialState: ActivitatsModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Activitat[],
    links: {} as LinksPage,
    meta: {} as PageInfo,
  },
};

const listActivitatsModule = createApiModule({
  name: 'listActivitats',
  apiMethod: getActivitats,
  initialState,
  parse: (activitats) => activitats,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess: () => {},
  onError: () => ({
    message: 'Error llistant les activitats',
  }),
  onValidationError: () => ({
    message: 'Error llistant les activitats',
  }),
});

export const {
  start: listActivitats,
  success: listActivitatsOk,
  error: listActivitatsKo,
  refresh: listActivitatsRefresh,
  reset: listActivitatsReset,
} = listActivitatsModule.slice.actions;

export default listActivitatsModule;
