import { newAssessoriaMobilitat as createAssessoria } from '../../api/assessories/realitzades/newAssessoriaMobilitat';
import createApiModule from '../createApiModule';
import { APIError, APIValidationError } from '../../types/api/api';

const newAssessoriaMobilitatModule = createApiModule({
  name: 'newAssessoriaMobilitat',
  apiMethod: createAssessoria,
  onSuccess: () => ({
    message: "L'assessoria s'ha realitzat correctament.",
    path: '/assessories',
  }),
  onError: (error: APIError) => ({
    message: error ? error.message : "Error realitzant l'assessoria",
  }),
  onValidationError: (error: APIValidationError) => ({
    message: error ? error.message : "Error realitzant l'assessoria",
  }),
});

export const {
  start: newAssessoriaMobilitat,
  success: newAssessoriaMobilitatOk,
  error: newAssessoriaMobilitatKo,
} = newAssessoriaMobilitatModule.slice.actions;

export default newAssessoriaMobilitatModule;
