import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { Oficina } from '../../types/professionals/oficines';

interface OficinesDTO {
  data: Oficina[];
}

export const loadOficines = async (): Promise<
  Result<OficinesDTO, APIValidationError>
> => {
  return validatedApiCall<OficinesDTO>(`/api/interns/oficines`, {
    method: 'GET',
    auth: true,
  });
};
