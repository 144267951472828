export const sortArrayByKey = (collection: any[], key: string) =>
  collection.slice().sort((a, b) => {
    if (!(key in a)) return 0;
    const x = a[key].toLowerCase();
    const y = b[key].toLowerCase();
    if (x > y) {
      return 1;
    }
    if (x < y) {
      return -1;
    }
    return 0;
  });
