import React from 'react';
import styles from './sectionHeading.module.scss';
import LineSeparator from '../LineSeparator/LineSeparator';

interface SectionHeadingProps {
  title: React.ReactNode;
}

const SectionHeading: React.FC<SectionHeadingProps> = ({ title }) => (
  <div className={styles.wrapper}>
    <h5 className={styles.title}>{title}</h5>
    <LineSeparator />
  </div>
);
export default SectionHeading;
