import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { SectorProfessional } from '../../types/assessories/sector_professional.d';

export interface SectorsProfessionalsDTO {
  data: SectorProfessional[];
}

export const getSectorsProfessionals = async (): Promise<
  Result<SectorsProfessionalsDTO, APIValidationError>
> => {
  return validatedApiCall<SectorsProfessionalsDTO>(
    `/api/interns/sector_professionals`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
