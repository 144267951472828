import React, { ReactElement, Ref } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import { getError, Paths } from '../../../../helpers/inputs/inputHelper';
import useIsRequired from '../../../../hooks/useIsRequired';
import styles from '../SelectField/selectField.module.scss';
import MultiSelectField, {
  MultiSelectFieldProps,
  ReactSelectOption,
} from '../MultiSelectField/MultiSelectField';
import Select from 'react-select/base';
import { GroupBase } from 'react-select';

interface ValidatedTextSelect<T extends FieldValues>
  extends MultiSelectFieldProps {
  select: React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > & {
    name: Paths<T, 2>;
  };
  innerRef?: Ref<Select<ReactSelectOption, true, GroupBase<ReactSelectOption>>>;
  defaultValue?: any;
  options: any;
  errors: FieldErrors<T>;
  schema: any;
  label: string | ReactElement;
  placeholder?: string;
  strict?: boolean;
  controller: any;
  control: any;
  onChange?: (e: any) => void;
}

const ValidatedMultiSelect = <T extends FieldValues>({
  defaultValue,
  options,
  errors,
  schema,
  label,
  select,
  placeholder,
  strict = false,
  controller,
  control,
  onChange,
  innerRef,
}: ValidatedTextSelect<T>): ReactElement => {
  const fname = select.name as string;
  const error = getError(errors, fname);
  const required = useIsRequired(fname, schema);

  return (
    <MultiSelectField
      innerRef={innerRef}
      onChange={onChange}
      control={control}
      controller={controller}
      defaultValue={defaultValue}
      options={options}
      select={select}
      error={error}
      placeholder={placeholder}
      strict={strict}
      // We change the label directly intead of passing the required prop
      label={
        label && required ? (
          <React.Fragment>
            {label}
            <span className={styles.asterisk}>{'\u00a0*'}</span>
          </React.Fragment>
        ) : (
          label
        )
      }
    />
  );
};

export default ValidatedMultiSelect;
