import { RootState } from '../store';

/******************** NEW Activitat **************************************/
export const selectNewActivitat = (state: RootState) => state.postActivitat;
export const selectNewActivitatLoading = (state: RootState) =>
  selectNewActivitat(state).loading;
export const selectNewActivitatErrors = (state: RootState) =>
  selectNewActivitat(state).errors;
/******************** LIST Activitat **************************************/
export const selectListActivitatsSlice = (state: RootState) => state.activitats;
export const selectListActivitats = (state: RootState) =>
  selectListActivitatsSlice(state).data.data;
export const selectListActivitatsLoading = (state: RootState) =>
  selectListActivitatsSlice(state).loading;
export const selectListActivitatsErrors = (state: RootState) =>
  selectListActivitatsSlice(state).errors;
export const selectListActivitatsPageInfo = (state: RootState) =>
  selectListActivitatsSlice(state).data.meta;
/******************** UPDATE Activitat **************************************/
export const selectUpdateActivitat = (state: RootState) =>
  state.updateActivitats;
export const selectUpdateActivitatLoading = (state: RootState) =>
  selectUpdateActivitat(state).loading;
export const selectUpdateActivitatErrors = (state: RootState) =>
  selectUpdateActivitat(state).errors;
/******************** GET Activitat **************************************/
export const selectActivitatSlice = (state: RootState) => state.activitat;
export const selectActivitat = (state: RootState) =>
  selectActivitatSlice(state).data?.data;
export const selectActivitatLoading = (state: RootState) =>
  selectActivitatSlice(state).loading;
export const selectActivitatErrors = (state: RootState) =>
  selectActivitatSlice(state).errors;
export const selectActivitatCentreEscolar = (state: RootState) =>
  selectActivitatSlice(state).data?.data.centre_escolar !== null
    ? selectActivitatSlice(state).data?.data.centre_escolar
    : undefined;
export const selectActivitatPresencial = (state: RootState) =>
  selectActivitatSlice(state).data?.data.presencial !== null
    ? selectActivitatSlice(state).data?.data.presencial
    : undefined;
export const selectActivitatVirtual = (state: RootState) =>
  selectActivitatSlice(state).data?.data.virtual !== null
    ? selectActivitatSlice(state).data?.data.virtual
    : undefined;
export const selectActivitatValoracio = (state: RootState) =>
  selectActivitatSlice(state).data?.data.activitatValoracio !== null
    ? selectActivitatSlice(state).data?.data.activitatValoracio
    : undefined;
/******************** DELETE Activitat *****************************/
export const selectDeleteActivitat = (state: RootState) =>
  state.deleteActivitat.activitat;

export const selectDeleteActivitatActive = (state: RootState) =>
  state.deleteActivitat.active;

export const selectDeleteActivitatLoading = (state: RootState) =>
  state.deleteActivitat.loading;
/******************** EXPORT *****************************/
export const selectExportActivitats = (state: RootState) =>
  state.activitatsExport;
export const selectExportActivitatsLoading = (state: RootState) =>
  selectExportActivitats(state).loading;
export const selectExportActivitatsErrors = (state: RootState) =>
  selectExportActivitats(state).errors;
