import { resetPassword } from '../../api/forgotPassword/resetPassword';
import createApiModule from '../createApiModule';

const resetPasswordModule = createApiModule({
  name: 'resetPassword',
  apiMethod: resetPassword,
  onSuccess: () => ({
    message: 'Se ha modificado la contraseña.',
    path: '/login',
  }),
  onError: () => ({
    message: 'El/La usuario/a no existe.',
  }),
  onValidationError: () => ({
    message: 'El/La usuario/a no existe.',
  }),
});

export const {
  start: reset,
  success: resetPasswordOk,
  error: resetPasswordKo,
} = resetPasswordModule.slice.actions;

export default resetPasswordModule;
