import { updateProfessional } from '../../api/professionals/updateProfessional';
import createApiModule from '../createApiModule';

const updateProfessionalModule = createApiModule({
  name: 'updateProfessional',
  apiMethod: updateProfessional,
  onSuccess: () => ({
    message: `El professional s'ha modificat correctament.`,
    path: '/professionals',
  }),
  onError: () => ({
    message: 'Error al modificar el/la professional',
  }),
  onValidationError: () => ({
    message: 'Error al modificar el/la professional',
  }),
});

export const {
  start: update,
  success: updateOk,
  error: updateKo,
} = updateProfessionalModule.slice.actions;

export default updateProfessionalModule;
