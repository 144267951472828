import React from 'react';

function NotFoundPage() {
  return (
    <section>
      <h1>Oops! You seem to be lost.</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </section>
  );
}

export default NotFoundPage;
