import React from 'react';
import styles from '../privateLayout.module.scss';
import { NavLink } from 'react-router-dom';

interface MenuItemProps {
  children?: React.ReactNode;
  path: string;
  text: string;
  disabled?: boolean;
  submenu?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({
  children,
  path,
  text,
  disabled,
  submenu,
}) => {
  const handleClick = (e) => {
    if (disabled) e.preventDefault();
  };

  return (
    <li className={!submenu ? styles.menuItem : styles.submenuItem}>
      <NavLink
        onClick={handleClick}
        to={path}
        className={({ isActive }) =>
          !disabled
            ? `${styles.menuLink} ${isActive ? styles.isCurrentPage : ''}`
            : `${styles.menuLinkDisabled}`
        }
      >
        <div className={styles.iconWrapper}>{children}</div>
        <span className={styles.menuText}>{text}</span>
      </NavLink>
    </li>
  );
};

export default MenuItem;
