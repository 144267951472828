import { lazy } from 'react';

const Home = lazy(() => import('./Home'));

const HomeRoutes = [
  {
    index: true,
    element: <Home />,
  },
];

export default HomeRoutes;
