import React from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { APIError } from '../../types/api/api';
import iconEmail from '../../assets/icon_email.svg';
import iconPassword from '../../assets/icon_password.svg';
import ValidatedInput from '../../components/common/Form/ValidatedInput/ValidatedInput';
import Loading from '../../components/common/Loading/Loading';
import useValidatorAPI from '../../hooks/useValidatorAPI';
import { login } from '../../store/auth/authSlice';
import { AppDispatch, RootState } from '../../store/store';
import { LoginDTO } from '../../types/auth/auth';
import styles from './login.module.scss';
import Button from '../../components/common/Buttons/Button/Button';
import {
  selectAuthErrors,
  selectAuthLoading,
} from '../../store/auth/selectors';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required('Indica tu e-mail')
    .email('Introduce un e-mail válido'),
  password: yup.string().required('Introduce tu contraseña'),
});

interface LoginProps {
  login: (payload: LoginDTO) => void;
  serverErrors: APIError | null;
  loading: boolean;
}

const Login: React.FC<LoginProps> = ({ login, serverErrors, loading }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    formState,
  } = useForm<LoginDTO>({
    mode: 'onSubmit',
    resolver: yupResolver(loginSchema),
  });

  const [globalError] = useValidatorAPI(serverErrors, setError, formState);

  return (
    <React.Fragment>
      <form className="form" onSubmit={handleSubmit(login)}>
        <p className="title">{t('login.Inicio de sesión')}</p>
        <p className="subtitle">
          {t('login.Introduce tus datos para iniciar sesión')}
        </p>
        <div className="inputWrapper hasIcon">
          <img src={iconEmail} className="icon email" alt="icon email" />
          <ValidatedInput
            variant="default"
            errors={errors}
            schema={loginSchema}
            label=""
            input={{ ...register('email'), type: 'email' }}
          />
        </div>
        <div className="inputWrapper hasIcon">
          <img
            src={iconPassword}
            className="icon password"
            alt="icon password"
          />
          <ValidatedInput
            variant="login"
            errors={errors}
            schema={loginSchema}
            label=""
            input={{
              ...register('password'),
              type: 'password',
            }}
          />
        </div>
        {/* Server-side global error */}
        {globalError && <p className={styles.errorText}>{globalError}</p>}
        {loading ? (
          <Loading />
        ) : (
          <div className={styles.buttonWrapper}>
            <Button variant="default" className="lg">
              {t('login.INICIAR SESIÓN')}
            </Button>
          </div>
        )}
        <Link
          className="text2"
          to="/"
          onClick={(e) => {
            e.preventDefault();
            navigate('/recordar_contrasenya');
          }}
        >
          {t('login.He olvidado mi contraseña')}
        </Link>
      </form>
    </React.Fragment>
  );
};

export default connect(
  (state: RootState) => ({
    serverErrors: selectAuthErrors(state),
    loading: selectAuthLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    login: (payload: LoginDTO) => {
      dispatch(login(payload));
    },
  }),
)(Login);
