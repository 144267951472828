import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import toQueryParams from '../../utils/toQueryParams';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { Activitat, Ubicacio } from '../../types/activitat/Activitat';
import { ISO8601DateString, UUID } from '../../types/standard';

export interface GetActivitats {
  ubicacio?: Ubicacio | '';
  tematica_id?: UUID;
  realitzada?: number;
  fecha_ini?: ISO8601DateString;
  fecha_fin?: ISO8601DateString;
  page: number;
  size: number;
  centre_escolar?: string;
}

export interface ActivitatsDTO {
  data: Activitat[];
  links: LinksPage;
  meta: PageInfo;
}

export const getActivitats = async (
  data: GetActivitats,
): Promise<Result<ActivitatsDTO, APIValidationError>> => {
  return validatedApiCall<ActivitatsDTO>(
    `/api/activitats?${toQueryParams(
      data as unknown as Record<string, string>,
    )}`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
