import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'location',
  initialState: '/',
  reducers: {
    setLocation: (state, action) => action.payload,
  },
});

export const { setLocation } = slice.actions;
export default slice.reducer;
