import { Activitat } from '../../types/activitat/Activitat';
import { APIValidationError } from '../../types/api/api';
import { UUID } from '../../types/standard';
import { Result, validatedApiCall } from '../api';

export interface ActivitatDto {
  data: Activitat;
}

export const getActivitat = async (
  id: UUID,
): Promise<Result<ActivitatDto, APIValidationError>> => {
  return validatedApiCall<ActivitatDto>(`/api/activitats/${id}`, {
    method: 'GET',
    auth: true,
  });
};
