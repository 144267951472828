import { newAssessoriaAcademica as createAssessoria } from '../../api/assessories/realitzades/newAssessoriaAcademica';
import createApiModule from '../createApiModule';
import { APIError, APIValidationError } from '../../types/api/api';

const newAssessoriaAcademicaModule = createApiModule({
  name: 'newAssessoriaAcademica',
  apiMethod: createAssessoria,
  onSuccess: () => ({
    message: "L'assessoria s'ha realitzat correctament.",
    path: '/assessories',
  }),
  onError: (error: APIError) => ({
    message: error ? error.message : "Error realitzant l'assessoria",
  }),
  onValidationError: (error: APIValidationError) => ({
    message: error ? error.message : "Error realitzant l'assessoria",
  }),
});

export const {
  start: newAssessoriaAcademica,
  success: newAssessoriaAcademicaOk,
  error: newAssessoriaAcademicaKo,
} = newAssessoriaAcademicaModule.slice.actions;

export default newAssessoriaAcademicaModule;
