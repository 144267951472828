import Login from './Login';

const LoginRoutes = [
  {
    path: 'login',
    element: <Login />,
  },
];

export default LoginRoutes;
