import { GenericState } from '../../types/apiModule';
import createApiModule from '../createApiModule';
import { GetAreas, getAreas as getAreasApi } from '../../api/areas/getAreas';
import { Area } from '../../types/areas/area.d';

export interface AreasModule extends GenericState<unknown> {
  data: GetAreas;
}

const initialState: AreasModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Area[],
  },
};

const getAreasModule = createApiModule({
  name: 'getAreas',
  apiMethod: getAreasApi,
  initialState,
  parse: (areas) => areas,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess: () => {},
  onError: () => ({
    message: 'Error llistant les àrees',
  }),
  onValidationError: () => ({
    message: 'Error llistant les àrees',
  }),
});

export const {
  start: getAreas,
  success: getAreasOk,
  error: getAreasKo,
  refresh: getAreasRefresh,
  reset: getAreasReset,
} = getAreasModule.slice.actions;

export default getAreasModule;
