import { UpdatePasswordDTO } from './../../types/forgotPassword/forgotPassword';
import { all, put, takeEvery, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { isApiError, isAPIValidationError, setToken } from '../../api/api';
import {
  login,
  logout,
  changePassword,
  newPassword,
} from '../../api/auth/auth';
import { Await } from '../../types/api/api';
import { ChangePasswordDTO, LoginDTO } from '../../types/auth/auth';
import snackBarSlice from '../snackBar/snackBarSlice';
import authSlice from './authSlice';

function* loginSaga(
  action: PayloadAction<LoginDTO>,
): Generator<any, void, any> {
  try {
    const result = (yield call(login, {
      email: action.payload.email,
      password: action.payload.password,
    })) as Await<ReturnType<typeof login>>;
    switch (result.type) {
      case 'ok':
        setToken(result.value.data.token.plainTextToken);
        yield put(authSlice.actions.loginOk(result.value.data));
        return;
      case 'validation-error':
        yield put(authSlice.actions.loginKo(result.value));
        return;
    }
  } catch (e: any) {
    if (isAPIValidationError(e)) {
      yield put(authSlice.actions.loginKo(e));
    } else if (isApiError(e)) {
      yield put(authSlice.actions.loginKo(e));
    } else {
      yield put(authSlice.actions.loginKo(e));
    }
  }
}

function* logoutSaga() {
  // This is only to delete the token from the server
  // ... if fails, well... so be it.
  try {
    yield call(logout);
  } catch (e) {
    /* empty */
  } finally {
    setToken(null);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* changePasswordSaga(
  action: PayloadAction<ChangePasswordDTO>,
): Generator<any, void, any> {
  try {
    const result = (yield call(changePassword, action.payload)) as Await<
      ReturnType<typeof changePassword>
    >;

    switch (result.type) {
      case 'ok':
        yield put(
          snackBarSlice.actions.showSnackBar({
            message: 'Contraseña actualizada correctamente.',
            path: '/perfil',
            severity: 'success',
          }),
        );
        yield put(authSlice.actions.changePasswordOk());
        return;
      case 'validation-error':
        yield put(authSlice.actions.changePasswordKo(result.value));
        return;
    }
  } catch (e) {
    if (isApiError(e)) {
      yield all([
        put(authSlice.actions.changePasswordKo(e)),
        put(
          snackBarSlice.actions.showSnackBar({
            message: 'Error al actualizar contraseña',
            severity: 'error',
          }),
        ),
      ]);
    }
    throw e;
  }
}

function* createNewPasswordSaga(
  action: PayloadAction<UpdatePasswordDTO>,
): Generator<any, void, any> {
  try {
    const result = (yield call(newPassword, {
      token: '',
      email: action.payload.email,
      password: action.payload.password,
      password_confirmation: action.payload.password,
    })) as Await<ReturnType<typeof newPassword>>;
    switch (result.type) {
      case 'ok':
        setToken(result.value.data.token.plainTextToken);
        yield put(authSlice.actions.loginOk(result.value.data));
        return;
      case 'validation-error':
        yield put(authSlice.actions.loginKo(result.value));
        return;
    }
  } catch (e: any) {
    if (isAPIValidationError(e)) {
      yield put(authSlice.actions.loginKo(e));
    } else if (isApiError(e)) {
      yield put(authSlice.actions.loginKo(e));
    } else {
      yield put(authSlice.actions.loginKo(e));
    }
  }
}

const sagas = [
  takeLatest<PayloadAction<LoginDTO>>(authSlice.actions.login.type, loginSaga),
  takeLatest<PayloadAction<UpdatePasswordDTO>>(
    authSlice.actions.createNewPassword.type,
    createNewPasswordSaga,
  ),
  takeEvery<PayloadAction<never>>(authSlice.actions.logout.type, logoutSaga),
  takeEvery<PayloadAction<ChangePasswordDTO>>(
    authSlice.actions.changePassword.type,
    changePasswordSaga,
  ),
];

export default sagas;
