import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import locationReducer from './location/slice';
import rootSaga from './root-saga';
import snackBarSlice from './snackBar/snackBarSlice';
import authSlice from './auth/authSlice';
import sendPasswordModule from './forgotPassword/sendPasswordModule';
import resetPasswordModule from './forgotPassword/resetPasswordModule';
import professionalModule from './professionals/professionalModule';
import listProfessionalModule from './professionals/listProfessionalModule';
import updateProfessionalModule from './professionals/updateProfessionalModule';
import registerProfessionalModule from './professionals/registerProfessionalModule';
import deleteProfessionalModule from './professionals/deleteProfessionalModule';
import oficinesSlice from './oficines/oficinesSlice';
import newCitaModule from './cites/newCitaModule';
import newConsultaModule from './consultes/newConsultaModule';
import updateConsultaModule from './consultes/updateConsultaModule';
import getAmbitsModule from './ambits/getAmbitsModule';
import getTematiquesModule from './ambits/getTematiquesModule';
import getMarcsModule from './marcs/getMarcsModule';
import listConsultaModule from './consultes/listConsultaModule';
import deleteConsultaModule from './consultes/deleteConsultaModule';
import consultaModule from './consultes/consultaModule';
import assessoriaModule from './assessories/assessoriaModule';
import listAssessoriaModule from './assessories/listAssessoriaModule';
import updateAssessoriaModule from './assessories/updateAssessoriaModule';
import deleteAssessoriaModule from './assessories/deleteAssessoriaModule';
import newAssessoriaModule from './assessories/newAssessoriaModule';
import findCitesModule from './cites/findCitesModule';
import listCitaModule from './cites/listCitaModule';
import deleteCitaModule from './cites/deleteCitaModule';
import getSectorsProfessionalsModule from './sectorsProfessionals/getSectorsProfessionalsModule';
import getPaisosModule from './paisos/getPaisosModule';
import newAssessoriaMobilitatModule from './assessories/newAssessoriaMobilitatModule';
import newAssessoriaAcademicaModule from './assessories/newAssessoriaAcademicaModule';
import getAcademiquesModule from './academiques/getAcademiquesModule';
import newAssessoriaHabitatgeModule from './assessories/newAssessoriaHabitatgeModule';
import newAssessoriaEmprenedoriaModule from './assessories/newAssessoriaEmprenedoriaModule';
import newAssessoriaLaboralModule from './assessories/newAssessoriaLaboralModule';
import assessoriaAssistitModule from './assessories/assessoriaAssistitModule';
import getAreasModule from './areas/getAreasModule';
import consultaExportModule from './consultes/consultaExportModule';
import assessoriaExportModule from './assessories/assessoriaExportModule';
import postActivitatModule from './activitat/postActivitatModule';
import getCentreEducatiuModule from './centreEducatiu/getCentreEducatiuModule';
import listActivitatsModule from './activitat/listActivitatsModule';
import patchActivitatModule from './activitat/patchActivitatModule';
import getActivitatModule from './activitat/getActivitatModule';
import deleteActivitatModule from './activitat/deleteActivitatModule';
import activitatsExportModule from './activitat/activitatsExportModule';
import dataRulesModule from './configuracio/control/dataRulesModule';
import updateDataRulesModule from './configuracio/control/updateDataRulesModule';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const appReducer = combineReducers({
  location: locationReducer,
  snackBar: snackBarSlice.reducer,
  auth: authSlice.reducer,
  sendPassword: sendPasswordModule.slice.reducer,
  resetPassword: resetPasswordModule.slice.reducer,
  professional: professionalModule.slice.reducer,
  professionals: listProfessionalModule.slice.reducer,
  registerProfessional: registerProfessionalModule.slice.reducer,
  deleteProfessional: deleteProfessionalModule.slice.reducer,
  updateProfessional: updateProfessionalModule.slice.reducer,
  oficines: oficinesSlice.reducer,
  newCita: newCitaModule.slice.reducer,
  newConsulta: newConsultaModule.slice.reducer,
  updateConsulta: updateConsultaModule.slice.reducer,
  consulta: consultaModule.slice.reducer,
  consultes: listConsultaModule.slice.reducer,
  deleteConsulta: deleteConsultaModule.slice.reducer,
  ambits: getAmbitsModule.slice.reducer,
  tematiques: getTematiquesModule.slice.reducer,
  marcs: getMarcsModule.slice.reducer,
  assessoria: assessoriaModule.slice.reducer,
  assessories: listAssessoriaModule.slice.reducer,
  updateAssessoria: updateAssessoriaModule.slice.reducer,
  deleteAssessoria: deleteAssessoriaModule.slice.reducer,
  newAssessoria: newAssessoriaModule.slice.reducer,
  disponibilitats: findCitesModule.slice.reducer,
  cites: listCitaModule.slice.reducer,
  deleteCita: deleteCitaModule.slice.reducer,
  sectors_professionals: getSectorsProfessionalsModule.slice.reducer,
  paisos: getPaisosModule.slice.reducer,
  newAssessoriaMobilitat: newAssessoriaMobilitatModule.slice.reducer,
  newAssessoriaAcademica: newAssessoriaAcademicaModule.slice.reducer,
  academiques: getAcademiquesModule.slice.reducer,
  newAssessoriaHabitatge: newAssessoriaHabitatgeModule.slice.reducer,
  newAssessoriaEmprenedoria: newAssessoriaEmprenedoriaModule.slice.reducer,
  newAssessoriaLaboral: newAssessoriaLaboralModule.slice.reducer,
  patchAssessoriaLaboral: assessoriaAssistitModule.slice.reducer,
  areas: getAreasModule.slice.reducer,
  consultaExport: consultaExportModule.slice.reducer,
  assessoriaExport: assessoriaExportModule.slice.reducer,
  postActivitat: postActivitatModule.slice.reducer,
  activitats: listActivitatsModule.slice.reducer,
  centreEducatius: getCentreEducatiuModule.slice.reducer,
  updateActivitats: patchActivitatModule.slice.reducer,
  activitat: getActivitatModule.slice.reducer,
  deleteActivitat: deleteActivitatModule.slice.reducer,
  activitatsExport: activitatsExportModule.slice.reducer,
  dataRules: dataRulesModule.slice.reducer,
  updateDataRules: updateDataRulesModule.slice.reducer,
});

/**
 * Reducer that wraps the regular app reducer to guarantee
 * than all of the app's state gets reset when the professional logs
 * out of the application.
 *
 * @param state application state
 * @param action action that has been perfomed
 * @return next state
 */
const rootReducer: typeof appReducer = (state, action): RootState => {
  if (action.type === authSlice.actions.logout.type) {
    state = undefined;
  }
  return appReducer(state, action);
};

const pReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: pReducer,
  middleware: [sagaMiddleware] as const, // prevent this from becoming just `Array<Middleware>`
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;

export const persistor = persistStore(store);

export default store;
