import React from 'react';
import ValidatedSelect from '../../../../../components/common/Form/ValidatedSelect/ValidatedSelect';
import { Controller, useFormContext } from 'react-hook-form';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../store/store';
import { getSectorsProfessionals } from '../../../../../store/sectorsProfessionals/getSectorsProfessionalsModule';
import {
  selectSectorsProfessionals,
  selectSectorsProfessionalsErrors,
  selectSectorsProfessionalsLoading,
} from '../../../../../store/sectorsProfessionals/selectors';
import { APIError } from '../../../../../types/api/api';
import { SectorProfessional } from '../../../../../types/assessories/sector_professional';
import { useMountEffect } from '../../../../../hooks/useMountEffect';
import { getTematiques } from '../../../../../store/ambits/getTematiquesModule';
import {
  selectTematiques,
  selectTematiquesErrors,
  selectTematiquesLoading,
} from '../../../../../store/ambits/selectors';
import {
  Tematica,
  tematicaToSelectOption,
} from '../../../../../types/shared/tematica.d';
import { AssessoriaZonaGeograficaEnum } from '../../../../../types/assessories/assessoria_zona_geografica.d';
import { ApiInternItem } from '../../../../../types/standard';
import { getPaisos } from '../../../../../store/paisos/getPaisosModule';
import { AssessoriaTemporalitatEnum } from '../../../../../types/assessories/assessoria_temporalitat.d';
import {
  selectPaisos,
  selectPaisosErrors,
  selectPaisosLoading,
} from '../../../../../store/paisos/selectors';
import { AssessoriaTipus } from '../../../../../types/assessories/assessoria_tipus';
import { AssessoriaMobilitatDerivacioEnum } from '../../../../../types/assessories/assessoria_mobilitat_derivacio.d';
import ValidatedMultiSelect from '../../../../../components/common/Form/ValidatedMultiSelect/ValidatedMultiSelect';
import { enumToSelectOptionArray } from '../../../../../utils/enumToSelectOptionArray';
import useTematica from '../../../../../hooks/useTematica';
import { sortArrayByKey } from '../../../../../utils/sortArrayByKey';
import { sortEnumByValue } from '../../../../../utils/sortEnumByValue';

interface AssessoriaMobilitatDataFormProps {
  schema: any;
  loadSectors: () => void;
  sectorsServerErrors: APIError | null;
  sectorsLoading: boolean;
  sectors: SectorProfessional[];
  loadTematiques: (payload: AssessoriaTipus) => void;
  tematiquesServerErrors: APIError | null;
  tematiquesLoading: boolean;
  tematiques: Tematica[];
  loadPaisos: () => void;
  paisosServerErrors: APIError | null;
  paisosLoading: boolean;
  paisos: ApiInternItem[];
}

const AssessoriaMobilitatDataForm = ({
  schema,
  loadSectors,
  sectorsLoading,
  sectors,
  loadTematiques,
  tematiques,
  loadPaisos,
  paisosLoading,
  paisos,
}: AssessoriaMobilitatDataFormProps) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  useMountEffect(loadPaisos);
  useMountEffect(loadSectors);
  useMountEffect(() => loadTematiques('mobilitat_internacional'));

  function getSectorsPlaceholder() {
    return sectorsLoading
      ? 'Carregant sectors professionals...'
      : sectors.length > 0
        ? 'Selecciona un sector professional'
        : 'No hi ha sectors professionals';
  }

  function getPaisosPlaceholder() {
    return paisosLoading
      ? 'Carregant països...'
      : paisos.length > 0
        ? 'Selecciona un país'
        : 'No hi ha països';
  }

  const {
    tematiquesPlaceholder,
    subTematiquesPlaceholder,
    handleTematicaChange,
    groupedSubTematiques,
  } = useTematica();

  return (
    <>
      <ValidatedSelect
        schema={schema}
        label="Temporalitat"
        errors={errors}
        placeholder="Selecciona la temporalitat"
        select={{
          ...register('temporalitat'),
        }}
      >
        {Object.entries(AssessoriaTemporalitatEnum).map((item) => (
          <option key={item[0]} value={item[0]}>
            {[item[1]]}
          </option>
        ))}
      </ValidatedSelect>
      <ValidatedSelect
        schema={schema}
        label="Sector professional"
        errors={errors}
        placeholder={getSectorsPlaceholder()}
        select={{
          ...register('sector_professional_id'),
        }}
      >
        {sortArrayByKey(sectors, 'name').map((sector) => (
          <option key={sector.id} value={sector.id}>
            {sector.name}
          </option>
        ))}
      </ValidatedSelect>
      <ValidatedMultiSelect
        schema={schema}
        label="Temàtica"
        errors={errors}
        placeholder={tematiquesPlaceholder}
        select={{
          ...register('tematica_id'),
        }}
        onChange={handleTematicaChange}
        options={sortArrayByKey(tematiques, 'name').map(tematicaToSelectOption)}
        control={control}
        controller={Controller}
      />
      <ValidatedMultiSelect
        schema={schema}
        label="Subtemàtica"
        errors={errors}
        placeholder={subTematiquesPlaceholder}
        select={{
          ...register('subtematica_id'),
        }}
        options={groupedSubTematiques}
        control={control}
        controller={Controller}
      />
      <ValidatedSelect
        schema={schema}
        label="Zona geogràfica"
        errors={errors}
        placeholder="Selecciona una zona"
        select={{
          ...register('zona_geografica'),
        }}
      >
        {Object.entries(sortEnumByValue(AssessoriaZonaGeograficaEnum)).map(
          (item) => (
            <option key={item[0]} value={item[0]}>
              {[item[1]]}
            </option>
          ),
        )}
      </ValidatedSelect>
      <ValidatedSelect
        schema={schema}
        label="País"
        errors={errors}
        placeholder={getPaisosPlaceholder()}
        select={{
          ...register('pais'),
        }}
      >
        {sortArrayByKey(paisos, 'nom').map((pais) => (
          <option key={pais.id} value={pais.id}>
            {pais.nom}
          </option>
        ))}
      </ValidatedSelect>
      <ValidatedMultiSelect
        schema={schema}
        label="Derivació?"
        errors={errors}
        placeholder="Selecciona si és una derivació"
        select={{
          ...register('derivacio'),
        }}
        options={enumToSelectOptionArray(
          sortEnumByValue(AssessoriaMobilitatDerivacioEnum),
        )}
        control={control}
        controller={Controller}
      />
    </>
  );
};

export default connect(
  (state: RootState) => ({
    sectors: selectSectorsProfessionals(state),
    sectorsServerErrors: selectSectorsProfessionalsErrors(state),
    sectorsLoading: selectSectorsProfessionalsLoading(state),
    tematiques: selectTematiques(state),
    tematiquesServerErrors: selectTematiquesErrors(state),
    tematiquesLoading: selectTematiquesLoading(state),
    paisos: selectPaisos(state),
    paisosServerErrors: selectPaisosErrors(state),
    paisosLoading: selectPaisosLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    loadSectors: () => {
      dispatch(getSectorsProfessionals({}));
    },
    loadTematiques: (payload: string) => {
      dispatch(getTematiques(payload));
    },
    loadPaisos: () => {
      dispatch(getPaisos({}));
    },
  }),
)(AssessoriaMobilitatDataForm);
