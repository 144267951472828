import { useParamSelector } from '../../hooks/useParamSelector';
import { selectHasAnyPermission } from '../../store/auth/selectors';
import { createContext, useContext } from 'react';
import { ActivitatCrudContextType } from '../../types/auth/permissionsContext';
import { Activitat } from '../../types/activitat/Activitat';
import { Oficina } from '../../types/shared/oficina';
import { CentreEducatiu } from '../../types/centreEducatiu/centreEducatiu';

export const ActivitatsContext = createContext<ActivitatCrudContextType>(
  {} as ActivitatCrudContextType,
);
export const useActivitatsContext = () => useContext(ActivitatsContext);

interface ProviderProps {
  children: React.ReactNode;
}
export const ActivitatsProvider: React.FC<ProviderProps> = ({
  // eslint-disable-next-line react/prop-types
  children,
}) => {
  const canRead = useParamSelector(selectHasAnyPermission, ['read_activitat']);
  const canCreate = useParamSelector(selectHasAnyPermission, [
    'create_activitat',
  ]);
  const canEdit = useParamSelector(selectHasAnyPermission, ['edit_activitat']);
  const canDelete = useParamSelector(selectHasAnyPermission, [
    'delete_activitat',
  ]);

  const canReadApp = useParamSelector(selectHasAnyPermission, [
    'read_activitat_app',
  ]);
  const canCreateApp = useParamSelector(selectHasAnyPermission, [
    'create_activitat_app',
  ]);
  const canEditApp = useParamSelector(selectHasAnyPermission, [
    'edit_activitat_app',
  ]);
  const canDeleteApp = useParamSelector(selectHasAnyPermission, [
    'delete_activitat_app',
  ]);

  const canReadOficina = useParamSelector(selectHasAnyPermission, [
    'read_activitat_oficina',
  ]);
  const canCreateOficina = useParamSelector(selectHasAnyPermission, [
    'create_activitat_oficina',
  ]);
  const canEditOficina = useParamSelector(selectHasAnyPermission, [
    'edit_activitat_oficina',
  ]);
  const canDeleteOficina = useParamSelector(selectHasAnyPermission, [
    'delete_activitat_oficina',
  ]);

  const canFilterApp = useParamSelector(selectHasAnyPermission, [
    'filters_activitat_app',
  ]);
  const canFilterOficina = useParamSelector(selectHasAnyPermission, [
    'filters_activitat_oficina',
  ]);

  const checkCanRead = (
    activitat: Activitat,
    oficina_user?: Oficina,
  ): boolean => {
    if (canReadApp) return true;
    if (canReadOficina) {
      return _checkOficina(activitat, oficina_user);
    }
    return false;
  };
  const checkCanCreate = (
    activitat: Activitat,
    oficina_user?: Oficina,
  ): boolean => {
    if (canCreateApp) return true;
    if (canCreateOficina) {
      return _checkOficina(activitat, oficina_user);
    }
    return false;
  };
  const checkCanEdit = (
    activitat: Activitat,
    oficina_user?: Oficina,
  ): boolean => {
    if (canEditApp) return true;
    if (canEditOficina) {
      return _checkOficina(activitat, oficina_user);
    }
    return false;
  };
  const checkCanDelete = (
    activitat: Activitat,
    oficina_user?: Oficina,
  ): boolean => {
    if (canDeleteApp) return true;
    if (canDeleteOficina) {
      return _checkOficina(activitat, oficina_user);
    }
    return false;
  };
  const getFilteredCentres = (
    centres: CentreEducatiu[],
    oficina_user?: Oficina,
  ): CentreEducatiu[] => {
    if (canFilterApp) return centres;
    if (canFilterOficina) {
      const districte_user = oficina_user?.districte;
      if (!districte_user) return centres;
      return centres.filter(
        (centre: CentreEducatiu) => centre.districte === districte_user,
      );
    }
    return [];
  };
  const _checkOficina = (
    activitat: Activitat,
    oficina_user?: Oficina,
  ): boolean => {
    const oficina_y_antenas_ids: string[] = [];
    if (activitat && activitat.oficina) {
      oficina_y_antenas_ids.push(activitat.oficina.id);
      const antenas = activitat.oficina.antenas;
      if (antenas) {
        for (const antena of antenas) {
          oficina_y_antenas_ids.push(antena.id);
        }
      }
    }
    if (oficina_user && oficina_y_antenas_ids.includes(oficina_user?.id)) {
      return true;
    }
    return false;
  };

  const value = {
    canRead,
    canCreate,
    canEdit,
    canDelete,

    canReadApp,
    canCreateApp,
    canEditApp,
    canDeleteApp,

    canReadOficina,
    canCreateOficina,
    canEditOficina,
    canDeleteOficina,

    canFilterApp,
    canFilterOficina,

    checkCanRead,
    checkCanCreate,
    checkCanEdit,
    checkCanDelete,

    getFilteredCentres,
  };

  return (
    <ActivitatsContext.Provider value={value}>
      {children}
    </ActivitatsContext.Provider>
  );
};
