import { getTematiques as tematiques } from '../../api/ambits/getTematiques';
import { GenericState } from '../../types/apiModule';
import createApiModule from '../createApiModule';
import { Tematica } from '../../types/shared/tematica';
import { TematiquesDTO } from '../../api/ambits/getTematiques';

export interface TematiquesModule extends GenericState<unknown> {
  data: TematiquesDTO;
}

const initialState: TematiquesModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Tematica[],
  },
};

const getTematiquesModule = createApiModule({
  name: 'getTematiques',
  apiMethod: tematiques,
  initialState,
  parse: (tematiques) => tematiques,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess: () => {},
  onError: () => ({
    message: 'Error llistant els tematiques',
  }),
  onValidationError: () => ({
    message: 'Error llistant els tematiques',
  }),
});

export const {
  start: getTematiques,
  success: getTematiquesOk,
  error: getTematiquesKo,
  refresh: getTematiquesRefresh,
  reset: getTematiquesReset,
} = getTematiquesModule.slice.actions;

export default getTematiquesModule;
