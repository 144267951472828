import { updateAssessoria } from '../../api/assessories/updateAssessoria';
import createApiModule from '../createApiModule';
import { APIError, APIValidationError } from '../../types/api/api';

const updateAssessoriaModule = createApiModule({
  name: 'updateAssessoria',
  apiMethod: updateAssessoria,
  onSuccess: () => ({
    message: "L'assessoria s'ha modificat correctament.",
    path: '/assessories',
  }),
  onError: (error: APIError) => ({
    message: error ? error.message : "Error modificant l'assessoria",
  }),
  onValidationError: (error: APIValidationError) => ({
    message: error ? error.message : "Error modificant l'assessoria",
  }),
});

export const {
  start: update,
  success: updateOk,
  error: updateKo,
} = updateAssessoriaModule.slice.actions;

export default updateAssessoriaModule;
