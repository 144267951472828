import { lazy } from 'react';
import { ConsultesProvider } from './ConsultesContext';
const ConsultesList = lazy(() => import('./Components/List/ConsultesList'));
const ConsultaNewEdit = lazy(
  () => import('./Components/NewEdit/ConsultaNewEdit'),
);

const CalendariRoutes = [
  {
    path: 'consultes',
    element: (
      <ConsultesProvider>
        <ConsultesList />
      </ConsultesProvider>
    ),
  },
  {
    path: 'consultes/nova',
    element: (
      <ConsultesProvider>
        <ConsultaNewEdit />
      </ConsultesProvider>
    ),
  },
  {
    path: 'consultes/:id/editar',
    element: (
      <ConsultesProvider>
        <ConsultaNewEdit />
      </ConsultesProvider>
    ),
  },
];

export default CalendariRoutes;
