import { getAssessoria as getAsse } from '../../api/assessories/getAssessoria';
import createApiModule from '../createApiModule';

const assessoriaModule = createApiModule({
  name: 'assessoria',
  apiMethod: getAsse,
  initialData: null,
  parse: (assessoria) => assessoria,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess: () => {},
  onError: () => ({
    message: "No hem pogut carregar l'assessoria",
  }),
  onValidationError: () => ({
    message: "No hem pogut carregar l'assessoria",
  }),
});

export const {
  start: getAssessoria,
  success: getAssessoriaOk,
  error: getAssessoriaKo,
  reset: resetAssessoria,
} = assessoriaModule.slice.actions;

export default assessoriaModule;
