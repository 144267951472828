import { getDataRules as getDR } from '../../../api/configuracio/control/getDataRules';
import createApiModule from '../../createApiModule';

const dataRulesModule = createApiModule({
  name: 'dataRules',
  apiMethod: getDR,
  initialData: null,
  parse: (dataRules) => dataRules,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess: () => {},
  onError: () => ({
    message: 'No hem pogut carregar les regles de dates',
  }),
  onValidationError: () => ({
    message: 'No hem pogut carregar les regles de dates',
  }),
});

export const {
  start: getDataRules,
  success: getDataRulesOk,
  error: getDataRulesKo,
  reset: resetDataRules,
} = dataRulesModule.slice.actions;

export default dataRulesModule;
