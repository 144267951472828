export type AssessoriaAcompanyant =
  | 'tutor_legal'
  | 'company'
  | 'amistat'
  | 'educador'
  | 'parella';

export enum AssessoriaAcompanyantEnum {
  'tutor_legal' = 'Tutor legal',
  'company' = 'Company/a',
  'amistat' = 'Amistat',
  'educador' = 'Educador',
  'parella' = 'Parella',
}
