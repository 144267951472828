import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../store/store';
import { getSectorsProfessionals } from '../../../../../store/sectorsProfessionals/getSectorsProfessionalsModule';
import {
  selectSectorsProfessionals,
  selectSectorsProfessionalsErrors,
  selectSectorsProfessionalsLoading,
} from '../../../../../store/sectorsProfessionals/selectors';
import { APIError } from '../../../../../types/api/api';
import {
  SectorProfessional,
  sectorProfessionalToSelectOption,
} from '../../../../../types/assessories/sector_professional.d';
import { useMountEffect } from '../../../../../hooks/useMountEffect';
import { getTematiques } from '../../../../../store/ambits/getTematiquesModule';
import {
  selectTematiques,
  selectTematiquesErrors,
  selectTematiquesLoading,
} from '../../../../../store/ambits/selectors';
import {
  Tematica,
  tematicaToSelectOption,
} from '../../../../../types/shared/tematica.d';
import { AssessoriaTipus } from '../../../../../types/assessories/assessoria_tipus';
import { AssessoriaLaboralDerivacioEnum } from '../../../../../types/assessories/assessoria_laboral_derivacio.d';
import { enumToSelectOptionArray } from '../../../../../utils/enumToSelectOptionArray';
import ValidatedMultiSelect from '../../../../../components/common/Form/ValidatedMultiSelect/ValidatedMultiSelect';
import useTematica from '../../../../../hooks/useTematica';
import { sortArrayByKey } from '../../../../../utils/sortArrayByKey';
import { sortEnumByValue } from '../../../../../utils/sortEnumByValue';

interface AssessoriaLaboralDataFormProps {
  schema: any;
  loadSectors: () => void;
  sectorsServerErrors: APIError | null;
  sectorsLoading: boolean;
  sectors: SectorProfessional[];
  loadTematiques: (payload: AssessoriaTipus) => void;
  tematiquesServerErrors: APIError | null;
  tematiquesLoading: boolean;
  tematiques: Tematica[];
}

const AssessoriaLaboralDataForm = ({
  schema,
  loadSectors,
  sectorsLoading,
  sectors,
  loadTematiques,
  tematiques,
}: AssessoriaLaboralDataFormProps) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  useMountEffect(loadSectors);
  useMountEffect(() => loadTematiques('laboral'));

  function getSectorsPlaceholder() {
    return sectorsLoading
      ? 'Carregant sectors professionals...'
      : sectors.length > 0
        ? 'Selecciona un sector professional'
        : 'No hi ha sectors professionals';
  }

  const {
    tematiquesPlaceholder,
    subTematiquesPlaceholder,
    handleTematicaChange,
    groupedSubTematiques,
  } = useTematica();

  return (
    <>
      <ValidatedMultiSelect
        schema={schema}
        label="Objectiu professional"
        errors={errors}
        placeholder={getSectorsPlaceholder()}
        select={{
          ...register('sector_professional_id'),
        }}
        options={sortArrayByKey(sectors, 'name').map(
          sectorProfessionalToSelectOption,
        )}
        control={control}
        controller={Controller}
      />
      <ValidatedMultiSelect
        schema={schema}
        label="Temàtica"
        errors={errors}
        placeholder={tematiquesPlaceholder}
        select={{
          ...register('tematica_id'),
        }}
        onChange={handleTematicaChange}
        options={sortArrayByKey(tematiques, 'name').map(tematicaToSelectOption)}
        control={control}
        controller={Controller}
      />
      <ValidatedMultiSelect
        schema={schema}
        label="Subtemàtica"
        errors={errors}
        placeholder={subTematiquesPlaceholder}
        select={{
          ...register('subtematica_id'),
        }}
        options={groupedSubTematiques}
        control={control}
        controller={Controller}
      />
      <ValidatedMultiSelect
        schema={schema}
        label="Derivació?"
        errors={errors}
        placeholder="Selecciona si és una derivació"
        select={{
          ...register('derivacio'),
        }}
        options={enumToSelectOptionArray(
          sortEnumByValue(AssessoriaLaboralDerivacioEnum),
        )}
        control={control}
        controller={Controller}
      />
    </>
  );
};

export default connect(
  (state: RootState) => ({
    sectors: selectSectorsProfessionals(state),
    sectorsServerErrors: selectSectorsProfessionalsErrors(state),
    sectorsLoading: selectSectorsProfessionalsLoading(state),
    tematiques: selectTematiques(state),
    tematiquesServerErrors: selectTematiquesErrors(state),
    tematiquesLoading: selectTematiquesLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    loadSectors: () => {
      dispatch(getSectorsProfessionals({}));
    },
    loadTematiques: (payload: string) => {
      dispatch(getTematiques(payload));
    },
  }),
)(AssessoriaLaboralDataForm);
