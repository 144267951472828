import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAssessoria,
  resetAssessoria,
} from '../../../../store/assessories/assessoriaModule';
import { UUID } from '../../../../types/standard';
import {
  selectAssessoria,
  selectAssessoriaLoading,
} from '../../../../store/assessories/selectors';

export function ViewModel() {
  const dispatch = useDispatch();
  // view state scope
  const [values] = useState({});

  function _get(id: UUID) {
    dispatch(getAssessoria(id));
  }
  function _reset() {
    dispatch(resetAssessoria());
  }
  const isLoading = useSelector(selectAssessoriaLoading);
  const assessoria = useSelector(selectAssessoria);

  return {
    ...values,
    get: _get,
    reset: _reset,
    isLoading,
    assessoria,
  };
}
