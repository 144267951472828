import { RootState } from '../store';

/******************** GET Academiques **************************************/
export const selectAcademiquesSlice = (state: RootState) => state.academiques;
export const selectAcademiques = (state: RootState) =>
  selectAcademiquesSlice(state).data.data;
export const selectAcademiquesLoading = (state: RootState) =>
  selectAcademiquesSlice(state).loading;
export const selectAcademiquesErrors = (state: RootState) =>
  selectAcademiquesSlice(state).errors;
