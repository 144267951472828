import { lazy } from 'react';

import { ControlProvider } from './Subsections/Control/ControlContext';

const NewEdit = lazy(
  () => import('./Subsections/Control/NewEdit/ControlNewEdit'),
);

const ConfiguracioRoutes = [
  {
    path: '/configuracio/control',
    element: (
      <ControlProvider>
        <NewEdit />
      </ControlProvider>
    ),
  },
];

export default ConfiguracioRoutes;
