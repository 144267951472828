export type AssessoriaAcademicaDerivacio =
  | 'associacionisme'
  | 'laboral'
  | 'mobilitat_internacional'
  | 'emprenedoria'
  | 'habitatge'
  | 'ate';

export enum AssessoriaAcademicaDerivacioEnum {
  'associacionisme' = 'Associacionisme',
  'laboral' = 'Laboral',
  'mobilitat_internacional' = 'Mobilitat internacional',
  'emprenedoria' = 'Emprenedoria',
  'habitatge' = 'Habitatge',
  'ate' = 'ATE',
}
