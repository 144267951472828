import { useParamSelector } from '../../hooks/useParamSelector';
import { selectHasAnyPermission } from '../../store/auth/selectors';
import { createContext, useContext } from 'react';
import { CrudContextType } from '../../types/auth/permissionsContext';

export const ProfessionalsContext = createContext<CrudContextType>(
  {} as CrudContextType,
);
export const useProfessionalsContext = () => useContext(ProfessionalsContext);

interface ProviderProps {
  children: React.ReactNode;
}
export const ProfessionalsProvider: React.FC<ProviderProps> = ({
  // eslint-disable-next-line react/prop-types
  children,
}) => {
  const canRead = useParamSelector(selectHasAnyPermission, ['read_usuari']);
  const canCreate = useParamSelector(selectHasAnyPermission, ['create_usuari']);
  const canEdit = useParamSelector(selectHasAnyPermission, ['edit_usuari']);
  const canDelete = useParamSelector(selectHasAnyPermission, ['delete_usuari']);

  const canReadApp = useParamSelector(selectHasAnyPermission, [
    'read_usuari_app',
  ]);
  const canCreateApp = useParamSelector(selectHasAnyPermission, [
    'create_usuari_app',
  ]);
  const canEditApp = useParamSelector(selectHasAnyPermission, [
    'edit_usuari_app',
  ]);
  const canDeleteApp = useParamSelector(selectHasAnyPermission, [
    'delete_usuari_app',
  ]);

  const canReadOficina = useParamSelector(selectHasAnyPermission, [
    'read_usuari_oficina',
  ]);
  const canCreateOficina = useParamSelector(selectHasAnyPermission, [
    'create_usuari_oficina',
  ]);
  const canEditOficina = useParamSelector(selectHasAnyPermission, [
    'edit_usuari_oficina',
  ]);
  const canDeleteOficina = useParamSelector(selectHasAnyPermission, [
    'delete_usuari_oficina',
  ]);

  const value = {
    canRead,
    canCreate,
    canEdit,
    canDelete,

    canReadApp,
    canCreateApp,
    canEditApp,
    canDeleteApp,

    canReadOficina,
    canCreateOficina,
    canEditOficina,
    canDeleteOficina,
  };

  return (
    <ProfessionalsContext.Provider value={value}>
      {children}
    </ProfessionalsContext.Provider>
  );
};
