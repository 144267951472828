import {
  AssessoriaAcademica,
  AssessoriaAcademicaRealitzada,
} from '../../../../types/assessories/assessoria_academica.d';
import { AssessoriaEmprenedoria } from '../../../../types/assessories/assessoria_emprenedoria.d';
import { AssessoriaHabitatge } from '../../../../types/assessories/assessoria_habitatge.d';
import { AssessoriaLaboral } from '../../../../types/assessories/assessoria_laboral.d';
import { AssessoriaMobilitat } from '../../../../types/assessories/assessoria_mobilitat.d';
import { AssessoriaTipus } from '../../../../types/assessories/assessoria_tipus.d';
import SectionLaboral from './SectionLaboral';
import SectionAcademica from './SectionAcademica';
import SectionEmprenedoria from './SectionEmprenedoria';
import SectionHabitatge from './SectionHabitatge';
import SectionMobilitatInternacional from './SectionMobilitatInternacional';

interface SectionRealitzadaProps {
  realitzada?:
    | AssessoriaAcademica
    | AssessoriaMobilitat
    | AssessoriaEmprenedoria
    | AssessoriaLaboral
    | AssessoriaHabitatge;
  tipus: AssessoriaTipus;
}

export const SectionRealitzada: React.FC<SectionRealitzadaProps> = ({
  realitzada,
  tipus,
}: SectionRealitzadaProps) => {
  return (
    <>
      {tipus === 'laboral' && (
        <SectionLaboral
          realitzada={realitzada as AssessoriaLaboral}
        ></SectionLaboral>
      )}
      {tipus === 'academica' && (
        <SectionAcademica
          realitzada={realitzada as AssessoriaAcademicaRealitzada}
        ></SectionAcademica>
      )}
      {tipus === 'emprenedoria' && (
        <SectionEmprenedoria
          realitzada={realitzada as AssessoriaEmprenedoria}
        ></SectionEmprenedoria>
      )}
      {tipus === 'habitatge' && (
        <SectionHabitatge
          realitzada={realitzada as AssessoriaHabitatge}
        ></SectionHabitatge>
      )}
      {tipus === 'mobilitat_internacional' && (
        <SectionMobilitatInternacional
          realitzada={realitzada as AssessoriaMobilitat}
        ></SectionMobilitatInternacional>
      )}
    </>
  );
};
