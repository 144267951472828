import { Result, validatedApiCall } from '../api';
import { UpdatePasswordDTO } from '../../types/forgotPassword/forgotPassword';
import { APIValidationError } from '../../types/api/api';

export const resetPassword = async (
  data: UpdatePasswordDTO,
): Promise<Result<UpdatePasswordDTO, APIValidationError>> =>
  validatedApiCall<UpdatePasswordDTO>(`/api/auth/reset-password`, {
    method: 'POST',
    auth: false,
    body: JSON.stringify(data),
  });
