export type AssessoriaLaboralDerivacio =
  | 'associacionisme'
  | 'academica'
  | 'mobilitat_internacional'
  | 'emprenedoria'
  | 'habitatge'
  | 'ate'
  | 'coaching_laboral'
  | 'eal'
  | 'entitats_socials'
  | 'impulsores_cg'
  | 'pdl_basa'
  | 'programes_basa'
  | 'programes_gj'
  | 'saier'
  | 'soc'
  | 'soj';

export enum AssessoriaLaboralDerivacioEnum {
  'associacionisme' = 'Associacionisme',
  'academica' = 'Acadèmica',
  'mobilitat_internacional' = 'Mobilitat internacional',
  'emprenedoria' = 'Emprenedoria',
  'habitatge' = 'Habitatge',
  'ate' = 'ATE',
  'coaching_laboral' = 'Coaching laboral',
  'eal' = 'EAL',
  'entitats_socials' = 'Entitats socials',
  'impulsores_cg' = 'Impulsores GJ',
  'pdl_basa' = 'PDL BASA',
  'programes_basa' = 'Programes BASA',
  'programes_gj' = 'Programes GJ',
  'saier' = 'SAIER',
  'soc' = 'SOC',
  'soj' = 'SOJ',
}
