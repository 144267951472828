export type AssessoriaHabitatgeDerivacio =
  | 'associacionisme'
  | 'academica'
  | 'mobilitat_internacional'
  | 'emprenedoria'
  | 'laboral'
  | 'olh'
  | 'pae';

export enum AssessoriaHabitatgeDerivacioEnum {
  'academica' = 'Acadèmica',
  'associacionisme' = 'Associacionisme',
  'emprenedoria' = 'Emprenedoria',
  'laboral' = 'Laboral',
  'mobilitat_internacional' = 'Mobilitat internacional',
  'olh' = 'OLH',
  'pae' = 'PAE',
}
