import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { ReactComponent as IconLogout } from '../../../../assets/icon_logout.svg';
import { ReactComponent as IconUser } from '../../../../assets/asideMenu/icon_usuarios.svg';
import logoApp from '../../../../assets/logos/Logo_principal_blanc.png';

import MenuGestion from './Menu/Menu';

import { selectUserFullname } from '../../../../store/auth/selectors';
import { logout } from '../../../../store/auth/authSlice';
import { AppDispatch, RootState } from '../../../../store/store';
import styles from './privateLayout.module.scss';
import { Outlet } from 'react-router-dom';
import SnackBar from '../../SnackBar/SnackBar';
import {
  selectPasswordChange,
  selectIsLoggedIn,
} from '../../../../store/auth/selectors';
import { canAccess } from '../../../../store/routePermissions/selectors';
import { useTranslation } from 'react-i18next';
import PopupActions from '../../../PopupActions/PopupActions';
import Button from '../../Buttons/Button/Button';

interface LayoutProps {
  userAvatar: string | null;
  logout: () => void;
  title?: string;
  isPasswordChange: boolean;
  isAuthenticated: boolean;
  isAllowed?: boolean;
  userFullname: string;
}

const PrivateLayout: React.FC<LayoutProps> = ({
  logout,
  isPasswordChange,
  isAuthenticated,
  isAllowed,
  userFullname,
}) => {
  const { t } = useTranslation();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const isStaging = window.location.href.includes('staging');
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to={'/login'} state={{ from: location }} replace />;
  }

  if (isAuthenticated && isPasswordChange) {
    return <Navigate to={'/new_password'} state={{ from: location }} replace />;
  }

  if (!isAllowed) {
    return <Navigate to={'/'} state={{ from: location }} replace />;
  }

  return (
    <div className={`${styles.root} `}>
      <header className={styles.header}>
        <Link className={styles.logoLink} to="/">
          <img
            className={styles.logoImg}
            src={logoApp}
            alt={`${t('alt.logo')}`}
          />
        </Link>
        {isStaging && (
          <Button type="button" style={{ float: 'left' }} variant="positive">
            Entorn de proves
          </Button>
        )}
        <ul className={styles.headerMenu}>
          <li
            className={`${styles.headerMenuItem} ${styles.headerMenuUserFullname}`}
          >
            <IconUser className={styles.headerUserIcon} />
            {userFullname}
          </li>
          <li className={styles.headerMenuItem}>
            <button
              className={styles.headerMenuButton}
              onClick={() => logout()}
            >
              <IconLogout className={styles.headerMenuIcon} />
              {t('Cerrar sesión')}
            </button>
          </li>
        </ul>
      </header>
      <MenuGestion isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />
      <main className={`${styles.mainContent}`}>
        <Outlet />
      </main>
      <PopupActions />
      <SnackBar />
    </div>
  );
};

export default connect(
  (state: RootState) => ({
    isPasswordChange: selectPasswordChange(state),
    isAuthenticated: selectIsLoggedIn(state),
    isAllowed: canAccess(state, location.pathname),
    userFullname: selectUserFullname(state),
  }),
  (dispatch: AppDispatch) => ({
    logout: () => {
      dispatch(logout());
    },
  }),
)(PrivateLayout);
