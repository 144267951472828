import { RootState } from '../store';

/******************** NEW Cita **************************************/
export const selectNewCita = (state: RootState) => state.newCita;
export const selectNewCitaLoading = (state: RootState) =>
  selectNewCita(state).loading;
export const selectNewCitaErrors = (state: RootState) =>
  selectNewCita(state).errors;

/******************** Disponibilitats **************************************/
export const selectDisponibilitatsSlice = (state: RootState) =>
  state.disponibilitats;
export const selectDisponibilitats = (state: RootState) =>
  selectDisponibilitatsSlice(state).data.data;
export const selectDisponibilitatsLoading = (state: RootState) =>
  selectDisponibilitatsSlice(state).loading;

/******************** List **************************************/
export const selectCitesSlice = (state: RootState) => state.cites;
export const selectCites = (state: RootState) =>
  selectCitesSlice(state).data.data;
export const selectCitesLoading = (state: RootState) =>
  selectCitesSlice(state).loading;
export const selectCitesPageInfo = (state: RootState) =>
  selectCitesSlice(state).data.meta;

/******************** DELETE *****************************/
export const selectDeleteCita = (state: RootState) => state.deleteCita.cita;

export const selectDeleteCitaActive = (state: RootState) =>
  state.deleteCita.active;

export const selectDeleteCitaLoading = (state: RootState) =>
  state.deleteCita.loading;
