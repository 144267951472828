import { lazy } from 'react';
import { ProfessionalsProvider } from './ProfessionalsContext';
const List = lazy(() => import('./Components/List/ProfessionalList'));
const ProfessionalNewEdit = lazy(
  () => import('./Components/NewEdit/ProfesssionalNewEdit'),
);

const ProfessionalsRoutes = [
  {
    path: 'professionals',
    element: (
      <ProfessionalsProvider>
        <List />
      </ProfessionalsProvider>
    ),
  },
  {
    path: 'professionals/nou',
    element: (
      <ProfessionalsProvider>
        <ProfessionalNewEdit />
      </ProfessionalsProvider>
    ),
  },
  {
    path: 'professionals/:id/editar',
    element: (
      <ProfessionalsProvider>
        <ProfessionalNewEdit />
      </ProfessionalsProvider>
    ),
  },
];

export default ProfessionalsRoutes;
