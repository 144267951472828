import React, { Suspense } from 'react';
import { Outlet, useRoutes } from 'react-router-dom';
import Loading from '../components/common/Loading/Loading';
import PublicLayout from '../components/common/Layouts/PublicLayout/PublicLayout';
import PrivateLayout from '../components/common/Layouts/PrivateLayout/PrivateLayout';

import LoginRoutes from './Login/routes';
import ForgotPasswordRoutes from './ForgotPassword/ForgotPasswordRoutes';
import NewPasswordRoutes from './ForgotPassword/NewPasswordRoutes';
import ErrorPage from './ErrorPage';
import NotFoundPage from './404';
import ProfessionalsRoutes from './Professionals/routes';
import HomeRoutes from './Home/routes';
import CalendariRoutes from './Calendari/routes';
import ConsultesRoutes from './Consultes/routes';
import AssessoriesRoutes from './Assessories/routes';
import CentresRoutes from './Centres/routes';
import ConfiguracioRoutes from './Configuracio/routes';
import ActivitatsRoutes from './Activitats/routes';

const SuspenseLayout = () => (
  <Suspense fallback={<Loading />}>
    <Outlet />
  </Suspense>
);

function Index() {
  return useRoutes([
    {
      element: <SuspenseLayout />,
      errorElement: <ErrorPage />,
      children: [
        // Public Routes
        {
          element: <PublicLayout />,
          children: [
            ...LoginRoutes,
            ...ForgotPasswordRoutes,
            ...NewPasswordRoutes,
          ],
        },
        {
          element: <PrivateLayout userAvatar={null} />,
          children: [
            ...HomeRoutes,
            ...ProfessionalsRoutes,
            ...CalendariRoutes,
            ...ConsultesRoutes,
            ...AssessoriesRoutes,
            ...CentresRoutes,
            ...ConfiguracioRoutes,
            ...ActivitatsRoutes,
          ],
        },
      ],
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ]);
}

export default Index;
