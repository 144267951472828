import { RootState } from '../store';
import { Tematica } from '../../types/shared/tematica';

/******************** GET Ambits **************************************/
export const selectAmbitsSlice = (state: RootState) => state.ambits;
export const selectAmbits = (state: RootState) =>
  selectAmbitsSlice(state).data.data;
export const selectAmbitsLoading = (state: RootState) =>
  selectAmbitsSlice(state).loading;

/******************** GET Tematiques **************************************/
export const selectTematiquesSlice = (state: RootState) => state.tematiques;
export const selectTematiques = (state: RootState) =>
  selectTematiquesSlice(state).data.data;
export const selectTematiquesLoading = (state: RootState) =>
  selectTematiquesSlice(state).loading;
export const selectTematiquesErrors = (state: RootState) =>
  selectTematiquesSlice(state).errors;

export const selectTematicaById = (state: RootState, id: string) =>
  selectTematiques(state).filter((tematica: Tematica) => {
    return tematica.id === id;
  });

export const selectTematicaInputSelectPlaceholder = (state: RootState) =>
  selectTematiquesLoading(state)
    ? 'Carregant temàtiques...'
    : selectTematiques(state).length > 0
      ? 'Selecciona una temàtica'
      : 'No hi ha temàtiques disponibles';
