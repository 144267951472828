import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';

export interface AssessoriaExport {
  content: string;
}

export const getExportAssessoria = async (): Promise<
  Result<AssessoriaExport, APIValidationError>
> => {
  return validatedApiCall<AssessoriaExport>(`/api/assessories/exports/csv`, {
    method: 'GET',
    auth: true,
  });
};
