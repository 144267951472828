import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { Academiques } from '../../types/assessories/academiques';

export interface AcademiquesDTO {
  data: Academiques[];
}

export const getAcademiques = async (): Promise<
  Result<AcademiquesDTO, APIValidationError>
> => {
  return validatedApiCall<AcademiquesDTO>(`/api/interns/academiques`, {
    method: 'GET',
    auth: true,
  });
};
