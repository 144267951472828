import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation from '../assets/translations/ca/translation.json';

const resources = {
  ca: {
    translation,
  },
};

i18next.use(initReactI18next).init({
  lng: 'ca', // if you're using a language detector, do not define the lng option
  debug: true,
  resources,
});

export default i18next;

export function languageCodeOnly(fullyQualifiedCode: string) {
  return fullyQualifiedCode.split('-')[0];
}

export function getCurrentLanguage() {
  return i18next.language;
}

export function changeLanguage(language: string | undefined) {
  return i18next.changeLanguage(language);
}

export function onLanguageChanged(callback: (language: any) => void) {
  return i18next.on('languageChanged', callback);
}
