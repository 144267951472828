export type AssessoriaInscripcio =
  | 'cita_previa'
  | 'espontania'
  | 'recuperada'
  | 'anulada';

export enum AssessoriaInscripcioEnum {
  'cita_previa' = 'Cita prèvia',
  'espontania' = 'Espontània',
  'recuperada' = 'Recuperada',
  'anulada' = 'Anul·lada',
}
