import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import toQueryParams from '../../utils/toQueryParams';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { Consulta } from '../../types/consultes/consulta.d';
import { ConsultaLloc } from '../../types/consultes/consulta_lloc.d';
import { UUID } from '../../types/standard';

export interface GetConsultesDTO {
  lloc?: ConsultaLloc | '';
  oficina_id?: UUID;
  antena_id?: UUID;
  centre_educatiu_id?: UUID;
  page: number;
  size: number;
}

export interface ConsultesDTO {
  data: Consulta[];
  links: LinksPage;
  meta: PageInfo;
}

export const getConsultes = async (
  data: GetConsultesDTO,
): Promise<Result<ConsultesDTO, APIValidationError>> => {
  return validatedApiCall<ConsultesDTO>(
    `/api/consultes?${toQueryParams(
      data as unknown as Record<string, string>,
    )}`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
