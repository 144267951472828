import {
  getSectorsProfessionals as sectors,
  SectorsProfessionalsDTO,
} from '../../api/sectorsProfessionals/getSectorsProfessionals';
import { GenericState } from '../../types/apiModule';
import createApiModule from '../createApiModule';
import { SectorProfessional } from '../../types/assessories/sector_professional';

export interface SectorsProfessionalsModule extends GenericState<unknown> {
  data: SectorsProfessionalsDTO;
}

const initialState: SectorsProfessionalsModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as SectorProfessional[],
  },
};

const getSectorsProfessionalsModule = createApiModule({
  name: 'getSectorsProfessionals',
  apiMethod: sectors,
  initialState,
  parse: (sectors) => sectors,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess: () => {},
  onError: () => ({
    message: 'Error llistant els sectors professionals',
  }),
  onValidationError: () => ({
    message: 'Error llistant els sectors professionals',
  }),
});

export const {
  start: getSectorsProfessionals,
  success: geSectorsProfessionalsOk,
  error: getSectorsProfessionalsKo,
  refresh: getSectorsProfessionalsRefresh,
  reset: getSectorsProfessionalsReset,
} = getSectorsProfessionalsModule.slice.actions;

export default getSectorsProfessionalsModule;
