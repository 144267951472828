import { newAssessoria as createAssessoria } from '../../api/assessories/newAssessoria';
import createApiModule from '../createApiModule';
import { APIError, APIValidationError } from '../../types/api/api';

const newAssessoriaModule = createApiModule({
  name: 'newAssessoria',
  apiMethod: createAssessoria,
  onSuccess: () => ({
    message: "L'assessoria s'ha creat correctament.",
    path: '/assessories',
  }),
  onError: (error: APIError) => ({
    message: error ? error.message : "Error creant l'assessoria",
  }),
  onValidationError: (error: APIValidationError) => ({
    message: error ? error.message : "Error creant l'assessoria",
  }),
});

export const {
  start: newAssessoria,
  success: newAssessoriaOk,
  error: newAssessoriaKo,
} = newAssessoriaModule.slice.actions;

export default newAssessoriaModule;
