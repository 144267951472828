export function sortEnumByValue<T extends Record<string, string>>(
  enumObject: T,
): T {
  const sortedEnum: T = {} as T;
  const sortedValues = Object.values(enumObject).sort((a, b) =>
    a.localeCompare(b, 'en', { sensitivity: 'base' }),
  );

  for (const value of sortedValues) {
    const key = Object.keys(enumObject).find((k) => enumObject[k] === value);
    if (key) {
      sortedEnum[key as keyof T] = value as T[keyof T];
    }
  }

  return sortedEnum;
}
