import { getMarcs as marcs, MarcsAccioDTO } from '../../api/marcs/getMarcs';
import { GenericState } from '../../types/apiModule';
import createApiModule from '../createApiModule';
import { MarcAccio } from '../../types/consultes/marc_accio';

export interface MarcsModule extends GenericState<unknown> {
  data: MarcsAccioDTO;
}

const initialState: MarcsModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as MarcAccio[],
  },
};

const getMarcsModule = createApiModule({
  name: 'getMarcs',
  apiMethod: marcs,
  initialState,
  parse: (marcs) => marcs,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess: () => {},
  onError: () => ({
    message: 'Error llistant els marcs',
  }),
  onValidationError: () => ({
    message: 'Error llistant els marcs',
  }),
});

export const {
  start: getMarcs,
  success: getMarcsOk,
  error: getMarcsKo,
  refresh: getMarcsRefresh,
  reset: getMarcsReset,
} = getMarcsModule.slice.actions;

export default getMarcsModule;
