import React from 'react';

interface IErrorComponentRequiredProps {
  msg?: string;
}

const ErrorPage = (props: IErrorComponentRequiredProps) => {
  const { msg } = props;

  return (
    <section>
      <p>{`Error: ${msg}`}</p>
    </section>
  );
};

export default ErrorPage;
