import { all } from '@redux-saga/core/effects';
import authSagas from './auth/sagas';
import resetPasswordModule from './forgotPassword/resetPasswordModule';
import sendPasswordModule from './forgotPassword/sendPasswordModule';
import registerProfessionalModule from './professionals/registerProfessionalModule';
import updateProfessionalModule from './professionals/updateProfessionalModule';
import deleteProfessionalModule from './professionals/deleteProfessionalModule';
import listProfessionalModule from './professionals/listProfessionalModule';
import professionalModule from './professionals/professionalModule';
import oficinesSagas from './oficines/sagas';
import newCitaModule from './cites/newCitaModule';
import newConsultaModule from './consultes/newConsultaModule';
import updateConsultaModule from './consultes/updateConsultaModule';
import getAmbitsModule from './ambits/getAmbitsModule';
import getTematiquesModule from './ambits/getTematiquesModule';
import getMarcsModule from './marcs/getMarcsModule';
import listConsultaModule from './consultes/listConsultaModule';
import deleteConsultaModule from './consultes/deleteConsultaModule';
import consultaModule from './consultes/consultaModule';
import assessoriaModule from './assessories/assessoriaModule';
import listAssessoriaModule from './assessories/listAssessoriaModule';
import updateAssessoriaModule from './assessories/updateAssessoriaModule';
import deleteAssessoriaModule from './assessories/deleteAssessoriaModule';
import newAssessoriaModule from './assessories/newAssessoriaModule';
import findCitesModule from './cites/findCitesModule';
import listCitaModule from './cites/listCitaModule';
import deleteCitaModule from './cites/deleteCitaModule';
import getSectorsProfessionalsModule from './sectorsProfessionals/getSectorsProfessionalsModule';
import getPaisosModule from './paisos/getPaisosModule';
import newAssessoriaMobilitatModule from './assessories/newAssessoriaMobilitatModule';
import newAssessoriaAcademicaModule from './assessories/newAssessoriaAcademicaModule';
import getAcademiquesModule from './academiques/getAcademiquesModule';
import newAssessoriaHabitatgeModule from './assessories/newAssessoriaHabitatgeModule';
import newAssessoriaEmprenedoriaModule from './assessories/newAssessoriaEmprenedoriaModule';
import newAssessoriaLaboralModule from './assessories/newAssessoriaLaboralModule';
import assessoriaAssistitModule from './assessories/assessoriaAssistitModule';
import getAreasModule from './areas/getAreasModule';
import consultaExportModule from './consultes/consultaExportModule';
import assessoriaExportModule from './assessories/assessoriaExportModule';
import postActivitatModule from './activitat/postActivitatModule';
import getCentreEducatiuModule from './centreEducatiu/getCentreEducatiuModule';
import listActivitatsModule from './activitat/listActivitatsModule';
import patchActivitatModule from './activitat/patchActivitatModule';
import getActivitatModule from './activitat/getActivitatModule';
import deleteActivitatModule from './activitat/deleteActivitatModule';
import activitatsExportModule from './activitat/activitatsExportModule';
import dataRulesModule from './configuracio/control/dataRulesModule';
import updateDataRulesModule from './configuracio/control/updateDataRulesModule';

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...professionalModule.sagas,
    ...registerProfessionalModule.sagas,
    ...listProfessionalModule.sagas,
    ...deleteProfessionalModule.sagas,
    ...updateProfessionalModule.sagas,
    ...resetPasswordModule.sagas,
    ...sendPasswordModule.sagas,
    ...oficinesSagas,
    ...newCitaModule.sagas,
    ...consultaModule.sagas,
    ...newConsultaModule.sagas,
    ...updateConsultaModule.sagas,
    ...listConsultaModule.sagas,
    ...deleteConsultaModule.sagas,
    ...getAmbitsModule.sagas,
    ...getTematiquesModule.sagas,
    ...getMarcsModule.sagas,
    ...assessoriaModule.sagas,
    ...listAssessoriaModule.sagas,
    ...updateAssessoriaModule.sagas,
    ...deleteAssessoriaModule.sagas,
    ...newAssessoriaModule.sagas,
    ...findCitesModule.sagas,
    ...listCitaModule.sagas,
    ...deleteCitaModule.sagas,
    ...getSectorsProfessionalsModule.sagas,
    ...getPaisosModule.sagas,
    ...newAssessoriaMobilitatModule.sagas,
    ...newAssessoriaAcademicaModule.sagas,
    ...getAcademiquesModule.sagas,
    ...newAssessoriaHabitatgeModule.sagas,
    ...newAssessoriaEmprenedoriaModule.sagas,
    ...newAssessoriaLaboralModule.sagas,
    ...assessoriaAssistitModule.sagas,
    ...getAreasModule.sagas,
    ...consultaExportModule.sagas,
    ...assessoriaExportModule.sagas,
    ...postActivitatModule.sagas,
    ...getCentreEducatiuModule.sagas,
    ...listActivitatsModule.sagas,
    ...patchActivitatModule.sagas,
    ...getActivitatModule.sagas,
    ...deleteActivitatModule.sagas,
    ...activitatsExportModule.sagas,
    ...dataRulesModule.sagas,
    ...updateDataRulesModule.sagas,
  ]);
}
