import React from 'react';
import DeleteProfessional from './DeleteProfessional/DeleteProfessional';
import DeleteConsulta from './DeleteConsulta/DeleteConsulta';
import DeleteAssessoria from './DeleteAssessoria/DeleteAssessoria';
import DeleteCita from './DeleteCita/DeleteCita';
import DeleteActivitat from './DeleteActivitat/DeleteActivitat';

const PopupActions = () => {
  return (
    <>
      <DeleteProfessional />
      <DeleteConsulta />
      <DeleteAssessoria />
      <DeleteCita />
      <DeleteActivitat />
    </>
  );
};

export default PopupActions;
