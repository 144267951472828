export type AssessoriaMobilitatDerivacio =
  | 'associacionisme'
  | 'laboral'
  | 'academica'
  | 'emprenedoria'
  | 'habitatge'
  | 'ate';

export enum AssessoriaMobilitatDerivacioEnum {
  'associacionisme' = 'Associacionisme',
  'academica' = 'Acadèmica',
  'emprenedoria' = 'Emprenedoria',
  'laboral' = 'Laboral',
  'habitatge' = 'Habitatge',
  'ate' = 'ATE',
}
