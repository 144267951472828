export type Districte =
  | 'ciutat_vella'
  | 'eixample'
  | 'sants_montjuic'
  | 'les_corts'
  | 'sarria_sant_gervasi'
  | 'gracia'
  | 'horta_guinardo'
  | 'nou_barris'
  | 'sant_andreu'
  | 'sant_marti';

export enum DistricteEnum {
  'ciutat_vella' = 'Ciutat Vella',
  'eixample' = 'Eixample',
  'sants_montjuic' = 'Sants Montjuïc',
  'les_corts' = 'Les Corts',
  'sarria_sant_gervasi' = 'Sarria SAnt Gervasi',
  'gracia' = 'Gràcia',
  'horta_guinardo' = 'Horta Guinardó',
  'nou_barris' = 'Nou Barris',
  'sant_andreu' = 'Sant Andreu',
  'sant_marti' = 'Sant Martí',
}
