import * as Yup from 'yup';
import * as yup from 'yup';

const validation = Yup.object().shape({
  inscripcio: yup.string().required("Indica com s'ha inscrit"),
  assistents: yup.string().required("Indica el nombre d'assistents"),
  valoracio: yup.string().required("Valora l'assesssoria"),
});

export default validation;
