import { useParamSelector } from '../../hooks/useParamSelector';
import { selectHasAnyPermission } from '../../store/auth/selectors';
import { createContext, useContext } from 'react';
import { ConsultaCrudContextType } from '../../types/auth/permissionsContext';
import { Consulta } from '../../types/consultes/consulta.d';
import { Oficina } from '../../types/shared/oficina';
import { CentreEducatiu } from '../../types/centreEducatiu/centreEducatiu';

export const ConsultesContext = createContext<ConsultaCrudContextType>(
  {} as ConsultaCrudContextType,
);
export const useConsultesContext = () => useContext(ConsultesContext);

interface ProviderProps {
  children: React.ReactNode;
}
// eslint-disable-next-line react/prop-types
export const ConsultesProvider: React.FC<ProviderProps> = ({ children }) => {
  const canRead = useParamSelector(selectHasAnyPermission, ['read_consulta']);
  const canCreate = useParamSelector(selectHasAnyPermission, [
    'create_consulta',
  ]);
  const canEdit = useParamSelector(selectHasAnyPermission, ['edit_consulta']);
  const canDelete = useParamSelector(selectHasAnyPermission, [
    'delete_consulta',
  ]);

  const canReadApp = useParamSelector(selectHasAnyPermission, [
    'read_consulta_app',
  ]);
  const canCreateApp = useParamSelector(selectHasAnyPermission, [
    'create_consulta_app',
  ]);
  const canEditApp = useParamSelector(selectHasAnyPermission, [
    'edit_consulta_app',
  ]);
  const canDeleteApp = useParamSelector(selectHasAnyPermission, [
    'delete_consulta_app',
  ]);
  const canReadOficina = useParamSelector(selectHasAnyPermission, [
    'read_consulta_oficina',
  ]);
  const canCreateOficina = useParamSelector(selectHasAnyPermission, [
    'create_consulta_oficina',
  ]);
  const canEditOficina = useParamSelector(selectHasAnyPermission, [
    'edit_consulta_oficina',
  ]);
  const canDeleteOficina = useParamSelector(selectHasAnyPermission, [
    'delete_consulta_oficina',
  ]);
  const canFilterApp = useParamSelector(selectHasAnyPermission, [
    'filters_consulta_app',
  ]);
  const canFilterOficina = useParamSelector(selectHasAnyPermission, [
    'filters_consulta_oficina',
  ]);

  const checkCanRead = (
    consulta: Consulta,
    oficina_user?: Oficina,
  ): boolean => {
    if (canReadApp) return true;
    if (canReadOficina) {
      return _checkOficina(consulta, oficina_user);
    }
    return false;
  };
  const checkCanCreate = (
    consulta: Consulta,
    oficina_user?: Oficina,
  ): boolean => {
    if (canCreateApp) return true;
    if (canCreateOficina) {
      return _checkOficina(consulta, oficina_user);
    }
    return false;
  };
  const checkCanEdit = (
    consulta: Consulta,
    oficina_user?: Oficina,
  ): boolean => {
    if (canEditApp) return true;
    if (canEditOficina) {
      return _checkOficina(consulta, oficina_user);
    }
    return false;
  };
  const checkCanDelete = (
    consulta: Consulta,
    oficina_user?: Oficina,
  ): boolean => {
    if (canDeleteApp) return true;
    if (canDeleteOficina) {
      return _checkOficina(consulta, oficina_user);
    }
    return false;
  };
  const getFilteredCentres = (
    centres: CentreEducatiu[],
    oficina_user?: Oficina,
  ): CentreEducatiu[] => {
    if (canFilterApp) return centres;
    if (canFilterOficina) {
      const districte_user = oficina_user?.districte;
      if (!districte_user) return centres;
      return centres.filter(
        (centre: CentreEducatiu) => centre.districte === districte_user,
      );
    }
    return [];
  };
  const getFilteredAntenas = (
    antenas: Oficina[],
    oficina_user?: Oficina,
  ): Oficina[] => {
    if (canFilterApp) return antenas;
    if (canFilterOficina) {
      const antenas_user = oficina_user?.antenas;
      if (antenas_user && antenas_user.length > 0) {
        const filtered_antenas: Oficina[] = [];
        for (const antena of antenas) {
          for (const antena_user of antenas_user) {
            if (antena.id === antena_user.id) {
              filtered_antenas.push(antena);
              break;
            }
          }
        }
        return filtered_antenas;
      }
    }
    return [];
  };

  const _checkOficina = (
    consulta?: Consulta,
    oficina_user?: Oficina,
  ): boolean => {
    const oficina_y_antenas_ids: string[] = [];
    if (consulta) {
      oficina_y_antenas_ids.push(consulta.oficina.id);
    }
    const antenas = consulta?.oficina.antenas;
    if (antenas) {
      for (const antena of antenas) {
        oficina_y_antenas_ids.push(antena.id);
      }
    }
    if (oficina_user && oficina_y_antenas_ids.includes(oficina_user?.id)) {
      return true;
    }
    return false;
  };

  const value = {
    canRead,
    canCreate,
    canEdit,
    canDelete,

    canReadApp,
    canCreateApp,
    canEditApp,
    canDeleteApp,

    canReadOficina,
    canCreateOficina,
    canEditOficina,
    canDeleteOficina,

    canFilterApp,
    canFilterOficina,

    checkCanRead,
    checkCanCreate,
    checkCanEdit,
    checkCanDelete,

    getFilteredCentres,
    getFilteredAntenas,
  };

  return (
    <ConsultesContext.Provider value={value}>
      {children}
    </ConsultesContext.Provider>
  );
};
