import { RootState } from '../store';

/******************** GET **************************************/
export const selectConsultaSlice = (state: RootState) => state.consulta;
export const selectConsulta = (state: RootState) =>
  selectConsultaSlice(state).data?.data;
export const selectConsultaLoading = (state: RootState) =>
  selectConsultaSlice(state).loading;
export const selectConsultaErrors = (state: RootState) =>
  selectConsultaSlice(state).errors;

/******************** NEW Consulta **************************************/
export const selectNewConsulta = (state: RootState) => state.newConsulta;
export const selectNewConsultaLoading = (state: RootState) =>
  selectNewConsulta(state).loading;
export const selectNewConsultaErrors = (state: RootState) =>
  selectNewConsulta(state).errors;

/******************** UPDATE  **************************************/
export const selectUpdateConsulta = (state: RootState) => state.updateConsulta;
export const selectUpdateConsultaLoading = (state: RootState) =>
  selectUpdateConsulta(state).loading;
export const selectUpdateConsultaErrors = (state: RootState) =>
  selectUpdateConsulta(state).errors;

/******************** List **************************************/
export const selectConsultesSlice = (state: RootState) => state.consultes;
export const selectConsultes = (state: RootState) =>
  selectConsultesSlice(state).data.data;
export const selectConsultesLoading = (state: RootState) =>
  selectConsultesSlice(state).loading;
export const selectConsultesPageInfo = (state: RootState) =>
  selectConsultesSlice(state).data.meta;

/******************** DELETE *****************************/
export const selectDeleteConsulta = (state: RootState) =>
  state.deleteConsulta.consulta;

export const selectDeleteConsultaActive = (state: RootState) =>
  state.deleteConsulta.active;

export const selectDeleteConsultaLoading = (state: RootState) =>
  state.deleteConsulta.loading;

/******************** EXPORT *****************************/
export const selectExportConsulta = (state: RootState) => state.consultaExport;

export const selectExportConsultaLoading = (state: RootState) =>
  selectExportConsulta(state).loading;
export const selectExportConsultaErrors = (state: RootState) =>
  selectExportConsulta(state).errors;
