export type Role =
  | 'admin'
  | 'informador'
  | 'informador_centre_escolar'
  | 'informador_integral'
  | 'informador_ciaj'
  | 'assesor_laboral'
  | 'assesor_mobilitat'
  | 'assesor_habitatge'
  | 'assesor_emprenedoria'
  | 'assesor_academica'
  | 'departament_joventut'
  | 'suport_coordinacio';

export enum RoleEnum {
  'admin' = 'Admin',
  'informador' = 'Informador/a',
  'informador_centre_escolar' = 'Informador/a centre escolar',
  'informador_integral' = 'Informador/a integral',
  'informador_ciaj' = 'Informador/a CIAJ',
  'assesor_laboral' = 'Assessor/a laboral',
  'assesor_mobilitat' = 'Assessor/a mobilitat',
  'assesor_habitatge' = 'Assessor/a habitatge',
  'assesor_emprenedoria' = 'Assessor/a emprenedoria',
  'assesor_academica' = 'Assessor/a academica',
  'departament_joventut' = 'Departament joventut',
  'suport_coordinacio' = 'Suport coordinació',
}
export enum RoleChangeableEnum {
  'informador' = 'Informador/a',
  'informador_centre_escolar' = 'Informador/a centre escolar',
  'informador_integral' = 'Informador/a integral',
  'informador_ciaj' = 'Informador/a CIAJ',
  'assesor_laboral' = 'Assessor/a laboral',
  'assesor_mobilitat' = 'Assessor/a mobilitat',
  'assesor_habitatge' = 'Assessor/a habitatge',
  'assesor_emprenedoria' = 'Assessor/a emprenedoria',
  'assesor_academica' = 'Assessor/a academica',
  'departament_joventut' = 'Departament joventut',
  'suport_coordinacio' = 'Suport coordinació',
}
