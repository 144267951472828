import { newAssessoriaLaboral as createAssessoria } from '../../api/assessories/realitzades/newAssessoriaLaboral';
import createApiModule from '../createApiModule';
import { APIError, APIValidationError } from '../../types/api/api';

const newAssessoriaLaboralModule = createApiModule({
  name: 'newAssessoriaLaboral',
  apiMethod: createAssessoria,
  onSuccess: () => ({
    message: "L'assessoria s'ha realitzat correctament.",
    path: '/assessories',
  }),
  onError: (error: APIError) => ({
    message: error ? error.message : "Error realitzant l'assessoria",
  }),
  onValidationError: (error: APIValidationError) => ({
    message: error ? error.message : "Error realitzant l'assessoria",
  }),
});

export const {
  start: newAssessoriaLaboral,
  success: newAssessoriaLaboralOk,
  error: newAssessoriaLaboralKo,
} = newAssessoriaLaboralModule.slice.actions;

export default newAssessoriaLaboralModule;
