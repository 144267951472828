import { getActivitat as getActivitatApi } from '../../api/activitats/getActivitat';
import createApiModule from '../createApiModule';

const getActivitatModule = createApiModule({
  name: 'activitat',
  apiMethod: getActivitatApi,
  initialData: null,
  parse: (activitat) => activitat,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess: () => {},
  onError: () => ({
    message: "No hem pogut carregar l'activitat",
  }),
  onValidationError: () => ({
    message: "No hem pogut carregar l'activitat",
  }),
});

export const {
  start: getActivitat,
  success: getActivitatOk,
  error: getActivitatKo,
  reset: resetActivitat,
} = getActivitatModule.slice.actions;

export default getActivitatModule;
