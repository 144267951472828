import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../store/store';
import {
  Tematica,
  tematicaToSelectOption,
} from '../../../../../types/shared/tematica.d';
import {
  selectTematiques,
  selectTematiquesErrors,
  selectTematiquesLoading,
} from '../../../../../store/ambits/selectors';
import { getTematiques } from '../../../../../store/ambits/getTematiquesModule';
import { useMountEffect } from '../../../../../hooks/useMountEffect';
import { AssessoriaTipus } from '../../../../../types/assessories/assessoria_tipus';
import { APIError } from '../../../../../types/api/api';
import { AssessoriaHabitatgeDerivacioEnum } from '../../../../../types/assessories/assessoria_habitatge_derivacio.d';
import ValidatedMultiSelect from '../../../../../components/common/Form/ValidatedMultiSelect/ValidatedMultiSelect';
import { enumToSelectOptionArray } from '../../../../../utils/enumToSelectOptionArray';
import useTematica from '../../../../../hooks/useTematica';
import { sortArrayByKey } from '../../../../../utils/sortArrayByKey';
import { sortEnumByValue } from '../../../../../utils/sortEnumByValue';

interface AssessoriaHabitatgeDataFormProps {
  schema: any;
  loadTematiques: (payload: AssessoriaTipus) => void;
  tematiquesServerErrors: APIError | null;
  tematiquesLoading: boolean;
  tematiques: Tematica[];
}

const AssessoriaHabitatgeDataForm = ({
  schema,
  loadTematiques,
  tematiques,
}: AssessoriaHabitatgeDataFormProps) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  useMountEffect(() => loadTematiques('habitatge'));

  const {
    tematiquesPlaceholder,
    subTematiquesPlaceholder,
    handleTematicaChange,
    groupedSubTematiques,
  } = useTematica();

  return (
    <>
      <ValidatedMultiSelect
        schema={schema}
        label="Temàtica"
        errors={errors}
        placeholder={tematiquesPlaceholder}
        select={{
          ...register('tematica_id'),
        }}
        onChange={handleTematicaChange}
        options={sortArrayByKey(tematiques, 'name').map(tematicaToSelectOption)}
        control={control}
        controller={Controller}
      />
      <ValidatedMultiSelect
        schema={schema}
        label="Subtemàtica"
        errors={errors}
        placeholder={subTematiquesPlaceholder}
        select={{
          ...register('subtematica_id'),
        }}
        options={groupedSubTematiques}
        control={control}
        controller={Controller}
      />
      <ValidatedMultiSelect
        schema={schema}
        label="Derivació?"
        errors={errors}
        placeholder="Selecciona si és una derivació"
        select={{
          ...register('derivacio'),
        }}
        options={enumToSelectOptionArray(
          sortEnumByValue(AssessoriaHabitatgeDerivacioEnum),
        )}
        control={control}
        controller={Controller}
      />
    </>
  );
};

export default connect(
  (state: RootState) => ({
    tematiques: selectTematiques(state),
    tematiquesServerErrors: selectTematiquesErrors(state),
    tematiquesLoading: selectTematiquesLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    loadTematiques: (payload: string) => {
      dispatch(getTematiques(payload));
    },
  }),
)(AssessoriaHabitatgeDataForm);
