import {
  getAssessories,
  AssessoriesDTO,
} from '../../api/assessories/getAssessories';
import { GenericState } from '../../types/apiModule';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { Assessoria } from '../../types/assessories/assessoria.d';
import createApiModule from '../createApiModule';

export interface assessoriesModule extends GenericState<unknown> {
  data: AssessoriesDTO;
}

const initialState: assessoriesModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Assessoria[],
    links: {} as LinksPage,
    meta: {} as PageInfo,
  },
};

const listAssessoriaModule = createApiModule({
  name: 'listAssessories',
  apiMethod: getAssessories,
  initialState,
  parse: (assessories) => assessories,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess: () => {},
  onError: () => ({
    message: 'Error llistant les assessories',
  }),
  onValidationError: () => ({
    message: 'Error llistant les assessories',
  }),
});

export const {
  start: listAssessories,
  success: listAssessoriesOk,
  error: listAssessoriesKo,
  refresh: listAssessoriesRefresh,
  reset: listAssessoriesReset,
} = listAssessoriaModule.slice.actions;

export default listAssessoriaModule;
