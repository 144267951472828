import { newAssessoriaEmprenedoria as createAssessoria } from '../../api/assessories/realitzades/newAssessoriaEmprenedoria';
import createApiModule from '../createApiModule';
import { APIError, APIValidationError } from '../../types/api/api';

const newAssessoriaEmprenedoriaModule = createApiModule({
  name: 'newAssessoriaEmprenedoria',
  apiMethod: createAssessoria,
  onSuccess: () => ({
    message: "L'assessoria s'ha realitzat correctament.",
    path: '/assessories',
  }),
  onError: (error: APIError) => ({
    message: error ? error.message : "Error realitzant l'assessoria",
  }),
  onValidationError: (error: APIValidationError) => ({
    message: error ? error.message : "Error realitzant l'assessoria",
  }),
});

export const {
  start: newAssessoriaEmprenedoria,
  success: newAssessoriaEmprenedoriaOk,
  error: newAssessoriaEmprenedoriaKo,
} = newAssessoriaEmprenedoriaModule.slice.actions;

export default newAssessoriaEmprenedoriaModule;
