import { registerProfessional } from '../../api/professionals/registerProfessional';
import createApiModule from '../createApiModule';

const registerProfessionalModule = createApiModule({
  name: 'registerProfessional',
  apiMethod: registerProfessional,
  onSuccess: (professional) => ({
    message: `El/La professional ${professional.nom} ${professional.cognom} ${professional.segon_cognom} s'ha creat correctament.`,
    path: '/professionals',
  }),
  onError: () => ({
    message: 'Error creant el/la professional',
  }),
  onValidationError: () => ({
    message: 'Error creant el/la professional',
  }),
});

export const {
  start: register,
  success: registerOk,
  error: registerKo,
} = registerProfessionalModule.slice.actions;

export default registerProfessionalModule;
