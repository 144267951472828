import { useParamSelector } from '../../hooks/useParamSelector';
import { selectHasAnyPermission } from '../../store/auth/selectors';
import { createContext, useContext } from 'react';

interface CrudContextType {
  canRead: boolean;
  canWrite: boolean;
  canAssign: boolean;
}

export const CentresContext = createContext<CrudContextType>(
  {} as CrudContextType,
);
export const useCentresContext = () => useContext(CentresContext);

interface ProviderProps {
  children: React.ReactNode;
}
// eslint-disable-next-line react/prop-types
export const CentresProvider: React.FC<ProviderProps> = ({ children }) => {
  const canWrite = useParamSelector(selectHasAnyPermission, []);

  const canRead = useParamSelector(selectHasAnyPermission, []);

  const canAssign = useParamSelector(selectHasAnyPermission, []);

  const value = {
    canWrite,
    canRead,
    canAssign,
  };

  return (
    <CentresContext.Provider value={value}>{children}</CentresContext.Provider>
  );
};
