import React from 'react';
import { connect } from 'react-redux';
import { hide, del as deleteCita } from '../../../store/cites/deleteCitaModule';

import { AppDispatch, RootState } from '../../../store/store';
import { UUID } from '../../../types/standard';
import Button from '../../common/Buttons/Button/Button';
import Loading from '../../common/Loading/Loading';
import Modal from '../../common/Modal/Modal';
import styles from '../changeActions.module.scss';
import { ReactComponent as IconTrash } from '../../../assets/moreMenu/trash.svg';
import ButtonsGroup from '../../common/Buttons/ButtonsGroup/ButtonsGroup';
import renderWhen from '../../../helpers/renderWhen';

import { Cita } from '../../../types/cites/cita.d';
import {
  selectDeleteCita,
  selectDeleteCitaActive,
  selectDeleteCitaLoading,
} from '../../../store/cites/selectors';

interface DeleteCitaProps {
  cita: Cita | null;
  active: boolean;
  loading: boolean;
  closeModal: () => void;
  deleteCita: (id: UUID | undefined) => void;
}

const DeleteCita: React.FC<DeleteCitaProps> = ({
  cita,
  active,
  loading,
  closeModal,
  deleteCita,
}) => {
  // 'active' controls if the modal is open or close
  if (!active) return null;
  return (
    <>
      {cita && (
        <Modal closeModal={closeModal}>
          <div className={styles.container}>
            <h1 className={styles.title}>
              <IconTrash className={`${styles.icon} ${styles.isIconTrash}`} />
              Eliminar cita
            </h1>
            <p className={styles.description}>
              Estàs segur/a que vols eliminar la cita?
            </p>
            <div className={styles.appointmentWrapper}>
              <div className={styles.description}>
                <p></p>
              </div>
            </div>
            <ButtonsGroup variant="center">
              <Button type="button" variant="negative" onClick={closeModal}>
                Cancel·lar
              </Button>
              {cita.id && (
                <Button
                  variant="positive"
                  onClick={() => {
                    if (cita) {
                      deleteCita(cita.id);
                    }
                    closeModal();
                  }}
                  data-testid="submit_button"
                >
                  Eliminar
                </Button>
              )}
            </ButtonsGroup>
            {loading && <Loading />}
          </div>
        </Modal>
      )}
    </>
  );
};

const ConnectedDeleteCita = connect(
  (state: RootState) => ({
    cita: selectDeleteCita(state),
    active: selectDeleteCitaActive(state),
    loading: selectDeleteCitaLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    deleteCita: (payload: UUID | undefined) => dispatch(deleteCita(payload)),
    closeModal: () => dispatch(hide()),
  }),
)(DeleteCita);

export default renderWhen(ConnectedDeleteCita, selectDeleteCitaActive);
