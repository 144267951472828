import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';

export interface ConsultaExport {
  content: string;
}

export const getExportConsulta = async (): Promise<
  Result<ConsultaExport, APIValidationError>
> => {
  return validatedApiCall<ConsultaExport>(`/api/consultes/exports/csv`, {
    method: 'GET',
    auth: true,
  });
};
