import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { Professional } from '../../types/professionals/professionals';

export const updateProfessional = async (
  data: Professional,
): Promise<Result<Professional, APIValidationError>> => {
  return validatedApiCall<Professional>(`/api/professionals/${data.id}`, {
    method: 'PATCH',
    auth: true,
    body: JSON.stringify(data),
  });
};
