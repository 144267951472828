import InputField from '../../../../components/common/Form/InputField/InputField';
import TextArea from '../../../../components/common/Form/TextArea/TextArea';
import { AssessoriaAcompanyantEnum } from '../../../../types/assessories/assessoria_acompanyant.d';
import { AssessoriaEmprenedoria } from '../../../../types/assessories/assessoria_emprenedoria.d';
import { AssessoriaEmprenedoriaDerivacioEnum } from '../../../../types/assessories/assessoria_emprenedoria_derivacio.d';

interface SectionEmprenedoriaProps {
  realitzada: AssessoriaEmprenedoria;
}

const SectionEmprenedoria: React.FC<SectionEmprenedoriaProps> = ({
  realitzada,
}: SectionEmprenedoriaProps) => {
  return (
    <>
      <InputField
        label="Acompanyant"
        input={{
          type: 'text',
          defaultValue: realitzada.acompanyant
            ? AssessoriaAcompanyantEnum[realitzada.acompanyant]
            : '',
          readOnly: true,
        }}
      />
      <InputField
        label="Assistents"
        input={{
          type: 'text',
          defaultValue: realitzada?.assistents,
          readOnly: true,
        }}
      />
      <TextArea
        label="Temàtica"
        textarea={{
          defaultValue:
            realitzada?.tematiques?.map((el) => el.name).join(', ') || [],
          readOnly: true,
          name: 'tematica',
        }}
      />
      <TextArea
        label="Sub temàtica"
        textarea={{
          defaultValue:
            realitzada?.subtematiques?.map((el) => el.name).join(', ') || [],
          readOnly: true,
          name: 'subtematica',
        }}
      />
      <TextArea
        label="Derivacio"
        textarea={{
          defaultValue:
            realitzada?.derivacio
              ?.map((el) => AssessoriaEmprenedoriaDerivacioEnum[el])
              .join(', ') || [],
          readOnly: true,
          name: 'derivacio',
        }}
      />
      <InputField
        label="Valoració assessor"
        input={{
          type: 'text',
          defaultValue: realitzada?.valoracio_assessor,
          readOnly: true,
        }}
      />
      <TextArea
        label="Comentaris"
        textarea={{
          defaultValue: realitzada?.comentaris,
          readOnly: true,
          name: 'comentaris',
        }}
      />
    </>
  );
};

export default SectionEmprenedoria;
