import { useEffect, useRef, useState } from 'react';
import {
  FieldValues,
  FormState,
  Path,
  UseFormSetError,
} from 'react-hook-form/dist/types';
import {
  isAPIBadRequestError,
  isAPIForbiddenError,
  isAPIServerError,
  isAPIUnauthorizedError,
  isAPIValidationError,
} from '../api/api';
import { APIError } from '../types/api/api';

function useValidatorAPI<FormValues extends FieldValues>(
  validationErrors: APIError | null,
  setError: UseFormSetError<FormValues>,
  formState: FormState<FormValues>,
): [string | null, React.Dispatch<React.SetStateAction<null | string>>] {
  const [globalError, setGlobalError] = useState(null as null | string);
  const lastError = useRef([null] as [null] | APIError);
  useEffect(() => {
    if (validationErrors !== lastError.current) {
      if (isAPIValidationError(validationErrors)) {
        Object.keys(validationErrors.errors).map((error) =>
          setError(error as unknown as Path<FormValues>, {
            message: validationErrors.errors[error][0],
          }),
        );
      }
    }
    if (
      isAPIUnauthorizedError(validationErrors) ||
      isAPIServerError(validationErrors)
    ) {
      setGlobalError(validationErrors.message);
    }
    if (isAPIForbiddenError(validationErrors)) {
      setGlobalError(validationErrors.message);
    }
    if (isAPIServerError(validationErrors)) {
      setGlobalError(validationErrors.message);
    }
    if (isAPIBadRequestError(validationErrors)) {
      setGlobalError(validationErrors.message);
    }
    if ((formState.isSubmitting && globalError) || !validationErrors) {
      setGlobalError(null);
    }
    lastError.current = validationErrors || [null];
  }, [formState, globalError, setError, validationErrors]);

  return [globalError, setGlobalError];
}

export default useValidatorAPI;
