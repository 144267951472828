import { Result, validatedApiCall } from '../../api';
import { DataRules } from '../../../types/configuracio/control/control';
import { APIValidationError } from '../../../types/api/api';

export interface DataRulesDTO {
  data: DataRules;
}

export const getDataRules = async (): Promise<
  Result<DataRulesDTO, APIValidationError>
> => {
  return validatedApiCall<DataRulesDTO>(`/api/config/data_rules`, {
    method: 'GET',
    auth: true,
  });
};
