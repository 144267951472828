import React from 'react';
import logoApp from '../../../../assets/logos/Logo_principal.png';
import styles from './publicLayout.module.scss';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '../../../../store/store';
import {
  selectPasswordChange,
  selectIsLoggedIn,
} from '../../../../store/auth/selectors';
import SnackBar from '../../SnackBar/SnackBar';

interface PublicLayoutProps {
  isAuthenticated?: boolean;
  isPasswordChange?: boolean;
  variant?: PublicLayoutVariants;
}

export type PublicLayoutVariants = 'valign' | null;

const variantPicker = (variant: PublicLayoutVariants) => {
  switch (variant) {
    case 'valign':
      return styles.valign;
    default:
      return '';
  }
};

const PublicLayout: React.FC<PublicLayoutProps> = ({
  isAuthenticated,
  isPasswordChange,
  variant = null,
}) => {
  const location = useLocation();
  const isNewPassword = location.pathname === '/new_password';

  if (isAuthenticated && !isPasswordChange) {
    return <Navigate to={'/'} state={{ from: location }} replace />;
  }
  if (isAuthenticated && isPasswordChange && !isNewPassword) {
    return <Navigate to={'/new_password'} state={{ from: location }} replace />;
  }

  return (
    <div className={`${styles.root} ${variantPicker(variant)}`}>
      <div className={styles.mainImageWrapper}>
        <div className={styles.wrappedImage}>
          <div className={styles.alignCenter}>
            <img src={logoApp} className={styles.logoApp} alt="" />
          </div>
        </div>
      </div>
      <main className={`${styles.wrapper}`}>
        <div className={`${styles.mainContent}`}>
          <Outlet />
        </div>
      </main>
      {<SnackBar />}
    </div>
  );
};

export default connect((state: RootState) => ({
  isAuthenticated: selectIsLoggedIn(state),
  isPasswordChange: selectPasswordChange(state),
}))(PublicLayout);
