import { PayloadAction } from '@reduxjs/toolkit';
import { deleteCita } from '../../api/cites/deleteCita';
import { GenericState } from '../../types/apiModule';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { Cita } from '../../types/cites/cita.d';
import { listCitesRefresh } from './listCitaModule';

export interface deleteCitaState extends GenericState<unknown> {
  active: boolean;
  data: Cita[];
  cita: Cita | null;
}
const initialState: deleteCitaState = {
  errors: null,
  loading: false,
  data: [],
  active: false,
  cita: null,
};
const deleteCitaModule = createApiModule({
  name: 'deleteCita',
  initialState,
  apiMethod: deleteCita,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: "La cita s'ha esborrat correctament.",
    }),
    listCitesRefresh(),
  ],
  onError: () => ({
    message: 'No es pot esborrar la cita.',
  }),
  onValidationError: (error) => ({
    message: error ? error.message : 'Error esborrant la cita',
  }),
  reducers: {
    show(state, action: PayloadAction<Cita>) {
      state.active = true;
      state.cita = action.payload;
    },
    hide(state) {
      state.active = false;
    },
  },
});

export const {
  start: del,
  success: delOk,
  error: delKo,
  hide,
  show,
} = deleteCitaModule.slice.actions;

export default deleteCitaModule;
