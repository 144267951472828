import {
  getProfessionals,
  ProfessionalsDTO,
} from '../../api/professionals/getProfessionals';
import { GenericState } from '../../types/apiModule';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { Professional } from '../../types/professionals/professionals';
import createApiModule from '../createApiModule';

export interface ProfessionalsModule extends GenericState<unknown> {
  data: ProfessionalsDTO;
}

const initialState: ProfessionalsModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Professional[],
    links: {} as LinksPage,
    meta: {} as PageInfo,
  },
};

const listProfessionalModule = createApiModule({
  name: 'listProfessionals',
  apiMethod: getProfessionals,
  initialState,
  parse: (professionals) => professionals,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess: () => {},
  onError: () => ({
    message: 'Error llistant els professionals',
  }),
  onValidationError: () => ({
    message: 'Error llistant els professionals',
  }),
});

export const {
  start: listProfessionals,
  success: listProfessionalsOk,
  error: listProfessionalsKo,
  refresh: listProfessionalsRefresh,
  reset: listProfessionalsReset,
} = listProfessionalModule.slice.actions;

export default listProfessionalModule;
