import { GenericState } from '../../types/apiModule';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import createApiModule from '../createApiModule';
import { CitesDTO, getCites } from '../../api/cites/getCites';
import { Cita } from '../../types/cites/cita.d';

export interface CitesModule extends GenericState<unknown> {
  data: CitesDTO;
}

const initialState: CitesModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Cita[],
    links: {} as LinksPage,
    meta: {} as PageInfo,
  },
};

const listCitaModule = createApiModule({
  name: 'listCites',
  apiMethod: getCites,
  initialState,
  parse: (consultes) => consultes,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess: () => {},
  onError: () => ({
    message: 'Error llistant les cites',
  }),
  onValidationError: () => ({
    message: 'Error llistant les cites',
  }),
});

export const {
  start: listCites,
  success: listCitesOk,
  error: listCitesKo,
  refresh: listCitesRefresh,
  reset: listCitesReset,
} = listCitaModule.slice.actions;

export default listCitaModule;
