import { RootState } from '../store';

/******************** GET SectorsProfessionals **************************************/
export const selectSectorsProfessionalsSlice = (state: RootState) =>
  state.sectors_professionals;
export const selectSectorsProfessionals = (state: RootState) =>
  selectSectorsProfessionalsSlice(state).data.data;
export const selectSectorsProfessionalsLoading = (state: RootState) =>
  selectSectorsProfessionalsSlice(state).loading;
export const selectSectorsProfessionalsErrors = (state: RootState) =>
  selectSectorsProfessionalsSlice(state).errors;
