import React, { ReactElement, Ref } from 'react';
import { FieldError } from 'react-hook-form';
import isFieldError from '../../../../helpers/isFieldError';
import styles from './multiSelectField.module.scss';
import ReactSelect, { GroupBase } from 'react-select';
import Select from 'react-select/base';

export type Variant = 'tiny' | 'default' | 'clean';
export interface MultiSelectFieldProps {
  select: React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >;
  label?: string | ReactElement;
  error?: string | FieldError;
  placeholder?: string;
  strict?: boolean;
  variant?: Variant;
  defaultValue?: ReactSelectOption[];
  options: ReactSelectOption[];
  controller: any;
  control: any;
  onChange?: (e: any) => void;
  innerRef?: Ref<Select<ReactSelectOption, true, GroupBase<ReactSelectOption>>>;
}

const stylePicker = (variant: Variant | undefined): string | undefined => {
  switch (variant) {
    case 'tiny':
      return styles.isTiny;
    case 'default':
    case 'clean':
      return '';
  }
};

export interface ReactSelectOption {
  readonly value: string;
  readonly label: string;
  readonly color?: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
  readonly childs?: ReactSelectOption[];
}

export interface GroupedOption {
  readonly label: string;
  readonly options: readonly ReactSelectOption[];
}

const MultiSelectField: React.FC<MultiSelectFieldProps> = ({
  innerRef,
  label,
  error,
  select,
  placeholder,
  variant = 'default',
  defaultValue,
  options,
  control,
  controller,
  onChange,
}) => {
  const id = select.id || select.name;
  const errorMessage: string | JSX.Element = isFieldError(error)
    ? error.message || ''
    : error || '';

  const Controller = controller;
  const externalOnChange = onChange;

  return (
    <div className={`${styles.row}`}>
      <label className={`${styles.label} ${stylePicker(variant)}`} htmlFor={id}>
        {label}
      </label>
      <Controller
        name={select.name}
        control={control}
        render={({ field: { onChange, name } }) => (
          <ReactSelect
            ref={innerRef}
            name={name}
            onChange={(e: any) => {
              const selectedValues = e.childs
                ? e.childs.map((c: any) => c.value)
                : e.value
                  ? [e.value]
                  : e.map((c: any) => c.value);

              onChange(selectedValues);
              if (externalOnChange) {
                externalOnChange(selectedValues);
              }
              //if (select.onChange) select.onChange(selectedValues);
            }}
            value={defaultValue}
            options={options}
            isMulti
            placeholder={placeholder}
            classNamePrefix="react-select"
            className={
              variant !== 'clean'
                ? `${styles.selectDropDown} 
        ${!placeholder ? styles.hasValue : ''} ${stylePicker(variant)}`
                : ''
            }
            id={id}
            noOptionsMessage={() => 'No hi ha opcions'}
          />
        )}
        rules={{ required: true }}
      />
      {error && <p className={styles.errorText}>{errorMessage}</p>}
    </div>
  );
};

export default MultiSelectField;
