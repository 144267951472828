import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { Cita } from '../../types/cites/cita.d';

export const newCita = async (
  data: Cita,
): Promise<Result<Cita, APIValidationError>> => {
  return validatedApiCall<Cita>(`/api/cites`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  });
};
