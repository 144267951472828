import { put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { loadOficines } from '../../api/oficines/loadOficines';
import { Await } from '../../types/api/api';
import oficinesSlice from './oficinesSlice';
import { isApiError } from '../../api/api';

function* oficinaSaga(): Generator<any, void, any> {
  try {
    const result = (yield call(loadOficines)) as Await<
      ReturnType<typeof loadOficines>
    >;
    switch (result.type) {
      case 'ok':
        yield put(oficinesSlice.actions.loadOficinesOk(result.value.data));
        return;
      case 'validation-error':
        yield put(oficinesSlice.actions.loadOficinesKo(result.value));
        return;
    }
  } catch (e) {
    if (isApiError(e)) {
      yield put(oficinesSlice.actions.loadOficinesKo(e));
    }
    throw e;
  }
}

const sagas = [
  takeLatest<PayloadAction<string>>(
    oficinesSlice.actions.loadOficines.type,
    oficinaSaga,
  ),
];

export default sagas;
