import { getProfessional as getUsr } from '../../api/professionals/getProfessional';
import createApiModule from '../createApiModule';

const professionalModule = createApiModule({
  name: 'Professional',
  apiMethod: getUsr,
  initialData: null,
  parse: (professional) => professional,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess: () => {},
  onError: () => ({
    message: 'No hemos podido cargar el/la usuario/a',
  }),
  onValidationError: () => ({
    message: 'No hemos podido cargar el/la usuario/a',
  }),
});

export const {
  start: getProfessional,
  success: getProfessionalOk,
  error: getProfessionalKo,
  reset: resetProfessional,
} = professionalModule.slice.actions;

export default professionalModule;
