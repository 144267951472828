import { UUID } from '../standard';
import { AssessoriaInscripcio } from './assessoria_inscripcio';
import { Assessoria } from './assessoria';
import { AssessoriaAcompanyant } from './assessoria_acompanyant';
import { AssessoriaMobilitat } from './assessoria_mobilitat';

export type AssessoriaEspecifica = AssessoriaMobilitat;

export enum ValoracioEnum {
  '01' = '1',
  '02' = '2',
  '03' = '3',
  '04' = '4',
  '05' = '5',
}

export enum NumAssistentsEnum {
  '01' = '1',
  '02' = '2',
  '03' = '3',
  '04' = '4',
  '05' = '5',
  '06' = '6',
  '07' = '7',
  '08' = '8',
  '09' = '9',
  '010' = '10',
  '011' = '11',
  '012' = '12',
  '013' = '13',
  '014' = '14',
  '015' = '15',
}

export type AssessoriaRealitzada = {
  assessoria_id?: UUID | undefined;
  assessoria?: Assessoria;
  professional_id?: UUID;
  inscripcio?: AssessoriaInscripcio;
  assistencia?: boolean;
  assistencia_array?: string[];
  assistents?: number;
  acompanyant?: AssessoriaAcompanyant;
  valoracio?: number;
  comentaris?: string;
  especifica?: AssessoriaEspecifica;
};
