import { Result, validatedApiCall } from '../api';
import { Assessoria } from '../../types/assessories/assessoria.d';
import { APIValidationError } from '../../types/api/api';
import { UUID } from '../../types/standard';

export interface AssessoriaDTO {
  data: Assessoria;
}

export const getAssessoria = async (
  id: UUID,
): Promise<Result<AssessoriaDTO, APIValidationError>> => {
  return validatedApiCall<AssessoriaDTO>(`/api/assessories/${id}`, {
    method: 'GET',
    auth: true,
  });
};
