import { lazy } from 'react';
import { AssessoriesProvider } from './AssessoriesContext';
import RealitzadaAssessoria from './Components/Realitza/RealitzadaAssessoria';
import AssessoriaRealitzada from './Components/Realitzada/AssessoriaRealitzada';
const List = lazy(() => import('./Components/List/AssesoriesList'));
const NewEdit = lazy(() => import('./Components/NewEdit/AssessoriaNewEdit'));

const AssessoriesRoutes = [
  {
    path: 'assessories',
    element: (
      <AssessoriesProvider>
        <List />
      </AssessoriesProvider>
    ),
  },
  {
    path: 'assessories/nova',
    element: (
      <AssessoriesProvider>
        <NewEdit />
      </AssessoriesProvider>
    ),
  },
  {
    path: 'assessories/:id/editar',
    element: (
      <AssessoriesProvider>
        <NewEdit />
      </AssessoriesProvider>
    ),
  },
  {
    path: 'assessories/:id/realitzar',
    element: (
      <AssessoriesProvider>
        <RealitzadaAssessoria />
      </AssessoriesProvider>
    ),
  },
  {
    path: 'assessories/:id',
    element: (
      <AssessoriesProvider>
        <AssessoriaRealitzada />
      </AssessoriesProvider>
    ),
  },
];

export default AssessoriesRoutes;
