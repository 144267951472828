import { Result, validatedApiCall } from '../api';
import { Professional } from '../../types/professionals/professionals';
import { APIValidationError } from '../../types/api/api';

export const registerProfessional = async (
  data: Professional,
): Promise<Result<Professional, APIValidationError>> => {
  return validatedApiCall<Professional>(`/api/professionals`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  });
};
