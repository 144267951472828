import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { MarcAccio } from '../../types/consultes/marc_accio.d';

export interface MarcsAccioDTO {
  data: MarcAccio[];
}

export const getMarcs = async (): Promise<
  Result<MarcsAccioDTO, APIValidationError>
> => {
  return validatedApiCall<MarcsAccioDTO>(`/api/interns/marcs`, {
    method: 'GET',
    auth: true,
  });
};
