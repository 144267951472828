import { EspecificaDictionary } from './EspecificaDictionary';
import { Assessoria } from '../../../../types/assessories/assessoria';

export class EspecificaCreator {
  static createEspecifica(tipus: string, assessoria: Assessoria): any {
    return {
      ...EspecificaDictionary[tipus],
      assessoria: assessoria,
    };
  }
}
