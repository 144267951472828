import { getConsulta as getCons } from '../../api/consultes/getConsulta';
import createApiModule from '../createApiModule';

const consultaModule = createApiModule({
  name: 'Consulta',
  apiMethod: getCons,
  initialData: null,
  parse: (consulta) => consulta,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess: () => {},
  onError: () => ({
    message: 'No hem pogut carregar la consulta',
  }),
  onValidationError: () => ({
    message: 'No hem pogut carregar la consulta',
  }),
});

export const {
  start: getConsulta,
  success: getConsultaOk,
  error: getConsultaKo,
  reset: resetConsulta,
} = consultaModule.slice.actions;

export default consultaModule;
