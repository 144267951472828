import { RootState } from '../store';
import { RoutePermissions } from './routePermissions';
import { selectUserPermissions } from '../auth/selectors';

export const canAccess = (state: RootState, path: string) => {
  const permissions = selectUserPermissions(state);
  const routePermissions = RoutePermissions;

  const item = routePermissions.find((i) => {
    if (i.path === path) {
      return true;
    }
    if (i.path.endsWith('/*')) {
      return path.startsWith(i.path.slice(0, -2));
    }
    return false;
  });

  return (
    item &&
    item.permissions.some(
      (permission) => permission === '*' || permissions.includes(permission),
    )
  );
};

export const selectShowProfessionals = (state: RootState) => {
  return canAccess(state, '/professionals');
};
export const selectShowConsultes = (state: RootState) => {
  return canAccess(state, '/consultes');
};
export const selectShowCalendari = (state: RootState) => {
  return canAccess(state, '/calendari');
};
export const selectShowAssessories = (state: RootState) => {
  return canAccess(state, '/assessories');
};
export const selectShowCentres = (state: RootState) => {
  return canAccess(state, '/centres_educatius');
};
export const selectShowOficines = (state: RootState) => {
  return canAccess(state, '/oficines');
};
export const selectShowActivitats = (state: RootState) => {
  return canAccess(state, '/activitats');
};
export const selectShowConfiguracio = (state: RootState) => {
  return canAccess(state, '/configuracio');
};
export const selectCanAccessConfiguracioControl = (state: RootState) => {
  return canAccess(state, '/configuracio/control');
};
