import React from 'react';
import Index from './routes';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store, { persistor } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import './services/i18n';

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <Index />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
