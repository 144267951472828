import { patchAssessoriaAssistit } from '../../api/assessories/patchAssessoriaAssistit';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { listAssessoriesRefresh } from './listAssessoriaModule';
import { listCitesRefresh } from '../cites/listCitaModule';

const assessoriaAssistitModule = createApiModule({
  name: 'assessoriaAssistit',
  apiMethod: patchAssessoriaAssistit,
  initialData: null,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: 'El usuari no ha assistit a la assessoria',
    }),
    listAssessoriesRefresh(),
    listCitesRefresh(),
  ],
  onError: () => ({
    message: "No hem pogut carregar l'assessoria",
  }),
  onValidationError: () => ({
    message: "No hem pogut carregar l'assessoria",
  }),
});

export const {
  start: switchAssessoriaAssistit,
  success: switchAssessoriaAssistitOk,
  error: switchAssessoriaAssistitKo,
  reset: resetSwitchAssessoriaAssistit,
} = assessoriaAssistitModule.slice.actions;

export default assessoriaAssistitModule;
