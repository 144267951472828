import { getPaisos as paisos } from '../../api/paisos/getPaisos';
import { GenericState } from '../../types/apiModule';
import createApiModule from '../createApiModule';
import { ApiInternItem, ApiInternResponseDTO } from '../../types/standard';

export interface PaisosModule extends GenericState<unknown> {
  data: ApiInternResponseDTO;
}

const initialState: PaisosModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as ApiInternItem[],
  },
};

const getPaisosModule = createApiModule({
  name: 'getPaisos',
  apiMethod: paisos,
  initialState,
  parse: (paisos) => paisos,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess: () => {},
  onError: () => ({
    message: 'Error llistant els països',
  }),
  onValidationError: () => ({
    message: 'Error llistant els països',
  }),
});

export const {
  start: getPaisos,
  success: gePaisosOk,
  error: getPaisosKo,
  refresh: getPaisosRefresh,
  reset: getPaisosReset,
} = getPaisosModule.slice.actions;

export default getPaisosModule;
