import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import toQueryParams from '../../utils/toQueryParams';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { Cita } from '../../types/cites/cita.d';
import { ISO8601DateString, UUID } from '../../types/standard';

export interface GetCitesDTO {
  oficina_id?: UUID;
  modalitat?: string;
  tipus?: string;
  professional_id?: UUID;
  from: ISO8601DateString;
  to: ISO8601DateString;
  page: number;
  size: number;
}

export interface CitesDTO {
  data: Cita[];
  links: LinksPage;
  meta: PageInfo;
}

export const getCites = async (
  data: GetCitesDTO,
): Promise<Result<CitesDTO, APIValidationError>> => {
  return validatedApiCall<CitesDTO>(
    `/api/cites?${toQueryParams(data as unknown as Record<string, string>)}`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
