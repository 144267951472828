import { RootState } from '../store';

/******************** GET Paisos **************************************/
export const selectPaisosSlice = (state: RootState) => state.paisos;
export const selectPaisos = (state: RootState) =>
  selectPaisosSlice(state).data.data;
export const selectPaisosLoading = (state: RootState) =>
  selectPaisosSlice(state).loading;
export const selectPaisosErrors = (state: RootState) =>
  selectPaisosSlice(state).errors;
