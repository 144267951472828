import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { ApiInternResponseDTO } from '../../types/standard';

export const getPaisos = async (): Promise<
  Result<ApiInternResponseDTO, APIValidationError>
> => {
  return validatedApiCall<ApiInternResponseDTO>(`/api/interns/paisos`, {
    method: 'GET',
    auth: true,
  });
};
