import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { CentreEducatiu } from '../../types/centreEducatiu/centreEducatiu.d';

export interface GetCentreEducatiu {
  data: CentreEducatiu[];
}

export const getCentreEducatius = async (): Promise<
  Result<GetCentreEducatiu, APIValidationError>
> => {
  return validatedApiCall<GetCentreEducatiu>(`/api/interns/centre_educatius`, {
    method: 'GET',
    auth: true,
  });
};
