import React, { ReactElement } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { getError, Paths } from '../../../../helpers/inputs/inputHelper';
import useIsRequired from '../../../../hooks/useIsRequired';
import TextArea, { TextAreaProps } from '../TextArea/TextArea';
import styles from '../TextArea/textArea.module.scss';

export interface ValidatedTextAreaProps<T extends FieldValues>
  extends TextAreaProps<T> {
  textarea: React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > & {
    name: Paths<T, 2>;
  };
  errors: FieldErrors<T>;
  schema: any;
  label: string | ReactElement;
}

const ValidatedTextArea = <T extends FieldValues>({
  errors,
  schema,
  label,
  textarea,
  ...other
}: ValidatedTextAreaProps<T>): ReactElement => {
  const fname = textarea.name as string;
  const error = getError(errors, fname);
  const required = useIsRequired(fname, schema);

  return (
    <TextArea
      textarea={textarea}
      error={error}
      label={
        label && required ? (
          <React.Fragment>
            {label}
            <span className={styles.asterisk}>{'\u00a0*'}</span>
          </React.Fragment>
        ) : (
          label
        )
      }
      {...other}
    />
  );
};

export default ValidatedTextArea;
