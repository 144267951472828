import React from 'react';
import { useSelector } from 'react-redux';
import styles from '../privateLayout.module.scss';
import { ReactComponent as IconDashboard } from '../../../../../assets/asideMenu/icon_inicio.svg';
import { ReactComponent as IconUsers } from '../../../../../assets/asideMenu/icon_users.svg';
import { ReactComponent as IconCalendari } from '../../../../../assets/asideMenu/icon_calendari.svg';
import { ReactComponent as IconCentreEducatiu } from '../../../../../assets/asideMenu/icon_centre_educatiu.svg';
import { ReactComponent as IconAssessoraments } from '../../../../../assets/asideMenu/icon_assesoraments.svg';
import { ReactComponent as IconRegistres } from '../../../../../assets/asideMenu/icon_registres.svg';
import { ReactComponent as IconConfiguracio } from '../../../../../assets/asideMenu/icon_configuracio.svg';
import { ReactComponent as IconActivitat } from '../../../../../assets/asideMenu/icon_activitat.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';

import MenuItem from './MenuItem';
import { useTranslation } from 'react-i18next';
import {
  selectShowAssessories,
  selectShowCalendari,
  selectShowCentres,
  selectShowConsultes,
  selectShowProfessionals,
  selectShowOficines,
  selectShowActivitats,
  selectShowConfiguracio,
  selectCanAccessConfiguracioControl,
} from '../../../../../store/routePermissions/selectors';

interface MenuProps {
  isOpenMenu: boolean;
  setIsOpenMenu: (open: boolean) => void;
}

const Menu: React.FC<MenuProps> = ({ isOpenMenu, setIsOpenMenu }) => {
  const { t } = useTranslation();

  const showProfessionals = useSelector(selectShowProfessionals);
  const showConsultes = useSelector(selectShowConsultes);
  const showCentres = useSelector(selectShowCentres);
  const showCalendari = useSelector(selectShowCalendari);
  const showAssessories = useSelector(selectShowAssessories);
  const showOficines = useSelector(selectShowOficines);
  const showActivitats = useSelector(selectShowActivitats);
  const showConfiguracio = useSelector(selectShowConfiguracio);
  const canAccessConfiguracioControl = useSelector(
    selectCanAccessConfiguracioControl,
  );

  return (
    <nav
      data-testid={isOpenMenu ? 'isMenuVisible' : 'isMenuHidden'}
      className={`${styles.navigation} ${isOpenMenu && styles.isOpen}`}
    >
      <button
        aria-label={isOpenMenu ? 'Cerrar menú' : 'Abrir menú'}
        onClick={() => setIsOpenMenu(!isOpenMenu)}
        className={`${styles.burger} ${styles.icon} ${
          isOpenMenu ? styles.isOpen : ''
        }`}
        type="button"
      >
        <span className={styles.burgerBox}>
          <span className={styles.burgerInner} />
        </span>
      </button>
      <ul className={styles.mainMenu}>
        <MenuItem path={'/'} text={`${t('menu.Inicio')}`}>
          <IconDashboard className={styles.menuIcon} />
        </MenuItem>
        {showProfessionals && (
          <MenuItem path={'/professionals'} text={`${t('menu.Professionals')}`}>
            <IconUsers className={styles.menuIcon} />
          </MenuItem>
        )}
        {showConsultes && (
          <MenuItem path={'/consultes'} text={`${t('menu.Consultes')}`}>
            <IconRegistres className={styles.menuIcon} />
          </MenuItem>
        )}
        {showAssessories && (
          <MenuItem path={'/assessories'} text={`${t('menu.Assessories')}`}>
            <IconAssessoraments className={styles.menuIcon} />
          </MenuItem>
        )}
        {showCalendari && (
          <MenuItem path={'/calendari'} text={`${t('menu.Calendari')}`}>
            <IconCalendari className={styles.menuIcon} />
          </MenuItem>
        )}
        {showActivitats && (
          <MenuItem path={'/activitats'} text={`${t('menu.Activitats')}`}>
            <IconActivitat className={styles.menuIcon} />
          </MenuItem>
        )}
        {showCentres && (
          <MenuItem
            text={`${t('menu.Centres Educatius')}`}
            path={'/centres_educatius'}
            disabled
          >
            <IconCentreEducatiu className={styles.menuIcon} />
          </MenuItem>
        )}
        {showOficines && (
          <MenuItem text={`${t('menu.Oficines')}`} path={'/oficines'} disabled>
            <FontAwesomeIcon icon={faBuilding} />
          </MenuItem>
        )}
        {showConfiguracio && (
          <>
            <MenuItem
              path={'/configuracio/control'}
              text={`${t('menu.Configuració')}`}
              disabled={!canAccessConfiguracioControl}
            >
              <IconConfiguracio className={styles.menuIcon} />
            </MenuItem>
            <div className={styles.indented}>
              <MenuItem
                text={`· ${t('menu.Control de dates')}`}
                path={'/configuracio/control'}
                disabled={!canAccessConfiguracioControl}
                submenu={true}
              ></MenuItem>
              <MenuItem
                text={`· ${t('menu.Àmbits')}`}
                path={'/configuracio/ambits'}
                disabled
                submenu={true}
              ></MenuItem>
              <MenuItem
                text={`· ${t('menu.Temàtiques')}`}
                path={'/configuracio/tematiques'}
                disabled
                submenu={true}
              ></MenuItem>
              <MenuItem
                text={`· ${t('menu.Subtemàtiques')}`}
                path={'/configuracio/subtematiques'}
                disabled
                submenu={true}
              ></MenuItem>
              <MenuItem
                text={`· ${t("menu.Marcs d'acció")}`}
                path={'/configuracio/marcs'}
                disabled
                submenu={true}
              ></MenuItem>
              <MenuItem
                text={`· ${t("menu.Submarcs d'acció")}`}
                path={'/configuracio/submarcs'}
                disabled
                submenu={true}
              ></MenuItem>
            </div>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Menu;
