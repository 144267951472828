import { routePermission } from '../../types/auth/routePermissions';

export const RoutePermissions: routePermission[] = [
  {
    path: '/',
    permissions: ['*'],
  },
  {
    path: '/professionals',
    permissions: ['read_usuari'],
  },
  {
    path: '/professionals/*',
    permissions: ['read_usuari'],
  },
  {
    path: '/calendari',
    permissions: ['read_cita'],
  },
  {
    path: '/calendari/cita/nova',
    permissions: ['create_cita'],
  },
  {
    path: '/consultes',
    permissions: ['read_consulta'],
  },
  {
    path: '/consultes/*',
    permissions: ['read_consulta'],
  },
  {
    path: '/assessories',
    permissions: ['read_assessoria'],
  },
  {
    path: '/assessories/*',
    permissions: ['read_assessoria'],
  },
  {
    path: '/activitats/*',
    permissions: ['read_activitat'],
  },
  {
    path: '/configuracio/*',
    permissions: ['read_configuracio_app'],
  },
];
