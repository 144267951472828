import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { Ambit } from '../../types/consultes/ambit';

export interface AmbitsDTO {
  data: Ambit[];
}

export const getAmbits = async (): Promise<
  Result<AmbitsDTO, APIValidationError>
> => {
  return validatedApiCall<AmbitsDTO>(`/api/interns/ambits`, {
    method: 'GET',
    auth: true,
  });
};
