import { Result, validatedApiCall } from '../api';
import { Consulta } from '../../types/consultes/consulta.d';
import { APIValidationError } from '../../types/api/api';
import { UUID } from '../../types/standard';

export interface ConsultaDTO {
  data: Consulta;
}

export const getConsulta = async (
  id: UUID,
): Promise<Result<ConsultaDTO, APIValidationError>> => {
  return validatedApiCall<ConsultaDTO>(`/api/consultes/${id}`, {
    method: 'GET',
    auth: true,
  });
};
