export type AssessoriaTipus =
  | 'academica'
  | 'laboral'
  | 'mobilitat_internacional'
  | 'emprenedoria'
  | 'habitatge';

export enum AssessoriaTipusEnum {
  'academica' = 'Acadèmica',
  'laboral' = 'Laboral',
  'mobilitat_internacional' = 'Mobilitat internacional',
  'emprenedoria' = 'Emprenedoria',
  'habitatge' = 'Habitatge',
}

export enum AssessoriaTipusKeyEnum {
  'academica' = 'academica',
  'laboral' = 'laboral',
  'mobilitat_internacional' = 'mobilitat_internacional',
  'emprenedoria' = 'emprenedoria',
  'habitatge' = 'habitatge',
}

export enum AssessoriaUserEnum {
  'academica' = 'educaciojove@bcn.cat',
  'laboral' = 'laboraljove@bcn.cat',
  'mobilitat_internacional' = 'mobilitatjove@bcn.cat',
  'emprenedoria' = 'emprenedoriajove@bcn.cat',
  'habitatge' = 'habitatgejove@bcn.cat',
}

export enum AssessoriaRoleEnum {
  'academica' = 'assesor_academica',
  'laboral' = 'assesor_laboral',
  'mobilitat_internacional' = 'assesor_mobilitat',
  'emprenedoria' = 'assesor_emprenedoria',
  'habitatge' = 'assesor_habitatge',
}
