import React from 'react';
import { AssessoriaTipusEnum } from '../../../../types/assessories/assessoria_tipus.d';
import { iEspecifica } from './iEspecifica';
import mobilitatValidationSchema from './Forms/mobilitatValidationSchema';
import AssessoriaMobilitatDataForm from './Forms/AssessoriaMobilitatDataForm';
import AssessoriaAcademicaDataForm from './Forms/AssessoriaAcademicaDataForm';

import { mergeArray } from '../../../../utils/mergeArrays';
import realitzadaValidationSchema from './Forms/realitzadaValidationSchema';
import { AssessoriaRealitzada } from '../../../../types/assessories/assessoria_realitzada';
import academicaValidationSchema from './Forms/academicaValidationSchema';
import { newAssessoriaMobilitat } from '../../../../store/assessories/newAssessoriaMobilitatModule';
import { newAssessoriaAcademica } from '../../../../store/assessories/newAssessoriaAcademicaModule';
import { Assessoria } from '../../../../types/assessories/assessoria';
import habitatgeValidationSchema from './Forms/habitatgeValidationSchema';
import { newAssessoriaHabitatge } from '../../../../store/assessories/newAssessoriaHabitatgeModule';
import AssessoriaHabitatgeDataForm from './Forms/AssessoriaHabitatgeDataForm';
import emprenedoriaValidationSchema from './Forms/emprenedoriaValidationSchema';
import AssessoriaEmprenedoriaDataForm from './Forms/AssessoriaEmprenedoriaDataForm';
import { newAssessoriaEmprenedoria } from '../../../../store/assessories/newAssessoriaEmprenedoriaModule';
import AssessoriaLaboralDataForm from './Forms/AssessoriaLaboralDataForm';
import laboralValidationSchema from './Forms/laboralValidationSchema';
import { newAssessoriaLaboral } from '../../../../store/assessories/newAssessoriaLaboralModule';

export const EspecificaDictionary: Record<AssessoriaTipusEnum, iEspecifica> = {
  [AssessoriaTipusEnum.laboral]: {
    assessoria: {} as Assessoria,
    component: (
      <AssessoriaLaboralDataForm
        schema={mergeArray(realitzadaValidationSchema, laboralValidationSchema)}
      />
    ),
    validationSchema: mergeArray(
      realitzadaValidationSchema,
      laboralValidationSchema,
    ),
    save: (data: AssessoriaRealitzada, dispatch) =>
      dispatch(newAssessoriaLaboral(data)),
  },
  [AssessoriaTipusEnum.academica]: {
    assessoria: {} as Assessoria,
    component: (
      <AssessoriaAcademicaDataForm
        schema={mergeArray(
          realitzadaValidationSchema,
          academicaValidationSchema,
        )}
      />
    ),
    validationSchema: mergeArray(
      realitzadaValidationSchema,
      academicaValidationSchema,
    ),
    save: (data: AssessoriaRealitzada, dispatch) =>
      dispatch(newAssessoriaAcademica(data)),
  },
  [AssessoriaTipusEnum.mobilitat_internacional]: {
    assessoria: {} as Assessoria,
    component: (
      <AssessoriaMobilitatDataForm
        schema={mergeArray(
          realitzadaValidationSchema,
          mobilitatValidationSchema,
        )}
      />
    ),
    validationSchema: mergeArray(
      realitzadaValidationSchema,
      mobilitatValidationSchema,
    ),
    save: (data: AssessoriaRealitzada, dispatch) =>
      dispatch(newAssessoriaMobilitat(data)),
  },
  [AssessoriaTipusEnum.emprenedoria]: {
    assessoria: {} as Assessoria,
    component: (
      <AssessoriaEmprenedoriaDataForm
        schema={mergeArray(
          realitzadaValidationSchema,
          emprenedoriaValidationSchema,
        )}
      />
    ),
    validationSchema: mergeArray(
      realitzadaValidationSchema,
      emprenedoriaValidationSchema,
    ),
    save: (data: AssessoriaRealitzada, dispatch) =>
      dispatch(newAssessoriaEmprenedoria(data)),
  },
  [AssessoriaTipusEnum.habitatge]: {
    assessoria: {} as Assessoria,
    component: (
      <AssessoriaHabitatgeDataForm
        schema={mergeArray(
          realitzadaValidationSchema,
          habitatgeValidationSchema,
        )}
      />
    ),
    validationSchema: mergeArray(
      realitzadaValidationSchema,
      habitatgeValidationSchema,
    ),
    save: (data: AssessoriaRealitzada, dispatch) =>
      dispatch(newAssessoriaHabitatge(data)),
  },
};
