import { getConsultes, ConsultesDTO } from '../../api/consultes/getConsultes';
import { GenericState } from '../../types/apiModule';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { Consulta } from '../../types/consultes/consulta.d';
import createApiModule from '../createApiModule';

export interface ConsultesModule extends GenericState<unknown> {
  data: ConsultesDTO;
}

const initialState: ConsultesModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Consulta[],
    links: {} as LinksPage,
    meta: {} as PageInfo,
  },
};

const listConsultaModule = createApiModule({
  name: 'listConsultes',
  apiMethod: getConsultes,
  initialState,
  parse: (consultes) => consultes,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess: () => {},
  onError: () => ({
    message: 'Error llistant les consultes',
  }),
  onValidationError: () => ({
    message: 'Error llistant les consultes',
  }),
});

export const {
  start: listConsultes,
  success: listConsultesOk,
  error: listConsultesKo,
  refresh: listConsultesRefresh,
  reset: listConsultesReset,
} = listConsultaModule.slice.actions;

export default listConsultaModule;
