import {
  AcademiquesDTO,
  getAcademiques as academiques,
} from '../../api/academiques/getAcademiques';
import { GenericState } from '../../types/apiModule';
import createApiModule from '../createApiModule';
import { Academiques } from '../../types/assessories/academiques';

export interface AcademiquesModule extends GenericState<unknown> {
  data: AcademiquesDTO;
}

const initialState: AcademiquesModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Academiques[],
  },
};

const getAcademiquesModule = createApiModule({
  name: 'getAcademiques',
  apiMethod: academiques,
  initialState,
  parse: (academiques) => academiques,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess: () => {},
  onError: () => ({
    message: 'Error llistant acadèmiques',
  }),
  onValidationError: () => ({
    message: 'Error llistant acadèmiques',
  }),
});

export const {
  start: getAcademiques,
  success: geAcademiquesOk,
  error: getAcademiquesKo,
  refresh: getAcademiquesRefresh,
  reset: getAcademiquesReset,
} = getAcademiquesModule.slice.actions;

export default getAcademiquesModule;
