import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import {
  ActivitatCentreEscolar,
  ActivitatPresencial,
  ActivitatValoracio,
  ActivitatVirtual,
  PostActivitat,
} from '../../types/activitat/Activitat';

export interface PostActivitatProps {
  activitat: PostActivitat;
  activitatCentreEscolar?: ActivitatCentreEscolar;
  activitatPresencial?: ActivitatPresencial;
  activitatVirtual?: ActivitatVirtual;
  activitatValoracio?: ActivitatValoracio;
}

export const postActivitat = async (
  data: PostActivitatProps,
): Promise<Result<PostActivitatProps, APIValidationError>> => {
  return validatedApiCall<PostActivitatProps>(`/api/activitats`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  });
};
